Banner = {
  init: function() {
    Banner.submitBanner();
    this.handleChangeCategory()
    this.filterCategory()

   if($('.banner.content-container').length){
    this.handleSort();
    this.handleSubmitBannerSOrder();
   }
  },

  submitBanner: function() {
    $('.submit-banner').bind('click', function (e) {
      if (!Banner.isValid()) {
        e.preventDefault();
        const errors = $('.banner-error-message').html()
        toastr.error(errors)
        return false;
      }
      $('.modal-loading').modal('show');
      return true;
    })
  },

  isValid: function() {
    const inputs = $('.input-required')
    const image_prev = $("#image_prev")[0].getAttribute("style")
    let result = true
    let names = [];

    for(let index = 0; index < inputs.length; index++) {
      if($(inputs[index]).val().trim().length === 0) {
        result = false;
        names.push($(inputs[index]).data('validate-name'))
      }
    }

    if (image_prev === 'background-image:none' || image_prev === 'background-image:url()') {
      names.push($('#banners_image').data('validate-name'))
      result = false;
    }

    if(result === false) {
      names = names.filter(function(item, index, array) { return array.indexOf(item) === index; })
      $('.banner-error-message span.fields').html(names.join('、'));
    }

    return result;
  },
  handleChangeCategory: function () {
    $('.banner-category').click(function () {
      const category = $(this).val()
      const bannerSizeSelector = $('.banner-size')

      switch (category) {
        case 'main':
          bannerSizeSelector.text('幅2123px, 縦1225px推奨')
          break
        case 'content':
          bannerSizeSelector.text('幅768px, 縦232px推奨')
          break
        case 'loop':
          bannerSizeSelector.text('幅1176px, 縦354px推奨')
          break
        default:
          bannerSizeSelector.text('幅2123px, 縦1225px推奨')
      }
    })
  },
  filterCategory: function () {
    $('#banner_category').change(function () {
      const category = $(this).val()
      let url = new URL(window.location.href);
      url.searchParams.delete('category');
      const categoryQuery = category ? `&category=${category}` : ''

      window.location.replace(`${url}${categoryQuery}`)
    })
  },

  handleSort: function () {
    $('.banner-sortable').sortable({
      placeholder: 'ui-state-highlight',
      revert: (true, 100),
      handle: ".drag",
      scroll: true,
      items: 'tr.sortable'
    });
  },

  handleSubmitBannerSOrder: function () {
    $('#submit-banner-order').on('click', function (event) {
      event.preventDefault();

      let order = $('.banner-sortable').sortable('toArray', { attribute: 'data-id' });

      $('#ids_change_order').val(order);
      $('#update_sort').submit();
    });
  },
}
