// import {handleAjaxError} from "../handle_error";

var EventLocationInfos = {
  init: function () {
    EventLocationInfos.start()
    // EventLocationInfos.handleSearchBukken()
    EventLocationInfos.handleAddBlock();
    EventLocationInfos.handleTriggerClickUploadBtn();
    EventLocationInfos.handleSort();
    EventLocationInfos.handleSubmit();
    EventLocationInfos.generateDataSort();
    EventLocationInfos.savePreview();
    // イベントの物件に関する機能がコメントアウトされましたので、検索したい場合は以下のテキストで検索してください： comment_out_event_location_bukken
    // EventLocationInfos.handleClearBukkenTable();
  },
  // bukkens: [],
  // selected_bukkens: [],
  // currentBukkenId: null,
  // activeLocationId: null,
  // locationWrapper: null,
  start: function () {
    // EventLocationInfos.bukkens = []
    // EventLocationInfos.selected_bukkens = []
    // EventLocationInfos.currentBukkenId = null
    // EventLocationInfos.activeLocationId = null
    // EventLocationInfos.locationWrapper = null

    $('.event-locations-form')
      .on('cocoon:before-insert', function (e, item) {
        let locationItemLength = $(e.currentTarget).find('.locations-wrapper.nested-fields').length
        item.find('label.location-title').first().text(`会場名 ${locationItemLength + 1}`)
      })
      .on("cocoon:before-remove", function (e, item) {
        $(e.currentTarget).find('.remove-location-modal').modal('hide')
      })
      // .on('cocoon:after-insert', function (e, item) {
      //   EventLocationInfos.handleClearBukkenTable();
      //   EventLocationInfos.handleAddEventLocationBukken()
      // })
      .on("cocoon:after-remove", function (e, item) {
        $.each($(e.currentTarget).find('label.location-title'), function(index, titleLabel) {
          $(titleLabel).text(`会場名 ${index + 1}`)
        })
      })

    // EventLocationInfos.handleAddEventLocationBukken()
  },
  // handleAddEventLocationBukken: function() {
  //   $('.white-table.no-padding')
  //     .on('cocoon:before-insert', function (e, item) {
  //       item.find('.event_location_bukken_id').val(EventLocationInfos.currentBukkenId)
  //
  //       $.ajax({
  //         url: '/kanri/event_location_infos/ajax_assign_bukken',
  //         type : 'GET',
  //         dataType: 'json',
  //         data : { bukken_id: EventLocationInfos.currentBukkenId },
  //         success: function (response) {
  //           if (response.bukken) {
  //             let data = response.bukken
  //             item.find('.body-id').text(data.id)
  //             item.find('.body-date').text(data.release_date)
  //             item.find('.body-name').text(data.bukken_name)
  //             item.find('.body-price').text(data.price)
  //             item.find('.body-type').text(data.bukken_type_name)
  //             item.find('.event-location-bukken-detail').attr('href', data.detail_path)
  //           }
  //         },
  //         error: function (request, status, error) {
  //           handleAjaxError(request, status, error);
  //         }
  //       })
  //     })
  //     .on("cocoon:after-remove", function (e, item) {
  //       let bukkenID = item.find('.event_location_bukken_id').val()
  //       EventLocationInfos.bukkens = EventLocationInfos.bukkens.filter(id => id !== String(bukkenID))
  //       EventLocationInfos.selected_bukkens = EventLocationInfos.selected_bukkens.filter(id => id !== String(bukkenID))
  //       EventLocationInfos.toggleBtnAddBukken()
  //     })
  // },
  // handleClearBukkenTable: function() {
  //   $('a[href="#location_add_bukken"]').click(function() {
  //     let locationId = $(this).attr('data-location-id')
  //     if (EventLocationInfos.activeLocationId == null || !locationId) {
  //       let randomString = Math.random().toString(36).slice(2, 7);
  //       $(this).attr('data-location-id', randomString)
  //       EventLocationInfos.activeLocationId = randomString
  //     } else if (EventLocationInfos.activeLocationId != locationId ) {
  //       EventLocationInfos.activeLocationId = locationId
  //     }
  //
  //     EventLocationInfos.locationWrapper = $(`a[data-location-id="${EventLocationInfos.activeLocationId}"]`).closest('.locations-wrapper.nested-fields')
  //     EventLocationInfos.bukkens = []
  //     EventLocationInfos.selected_bukkens = []
  //     $.each(EventLocationInfos.locationWrapper.find('.event_location_bukken_id'), function() {
  //       EventLocationInfos.bukkens.push(String($(this).val()))
  //       EventLocationInfos.selected_bukkens.push(String($(this).val()))
  //     })
  //
  //     $("#table_search_bukkens").html('<tbody><tr><td class="text-center">検索条件を指定すると物件が表示されます。</td></tr></tbody>');
  //   })
  // },
  handleAddBlock: function () {
    $(document).on('click', '.add-block-btn', function(event) {
      let dataTrigger = event.target.getAttribute('data-trigger');
      $(event.target).closest('.locations-wrapper').find('a.' + dataTrigger).trigger('click');
    })
  },
  handleTriggerClickUploadBtn: function () {
    $(document).on('click', '.img-wrapper', function(event) {
      $(event.target).find('input').trigger('click');
    })
  },
  handleSort: function () {
    $('.locations-wrapper').each(function() {
      let self = $(this);
      let defaultSort = [];
      let sortableBlock = self.find('.sortable-blocks');

      self.find('.block-box').each(function() {
        defaultSort.push([$(this).attr('id'), $(this).find('input.order-field').val()]);
      });

      defaultSort.sort(function(a, b) {
        var compA = parseInt(a[1]);
        var compB = parseInt(b[1]);
        return (compA < compB) ? -1 : (compA > compB) ? 1 : 0;
      });

      $.each(defaultSort, function(_i, val) {
        self.find('#' + val[0]).appendTo(sortableBlock);
      });
    });

    $('.sortable-blocks').sortable({
      items: 'div.block-box',
      start: function (_, ui) {
        const id_textarea = ui.item.find(".block-ckeditor").attr("id");
        if (typeof CKEDITOR != 'undefined' && id_textarea) {
          CKEDITOR.instances[id_textarea].destroy();
        }
      },
      stop: function (_, ui) {
        const id_textarea = ui.item.find(".block-ckeditor").attr("id");
        if (typeof CKEDITOR != 'undefined' && id_textarea) {
          CKEDITOR.replace(id_textarea, {"language":"ja","toolbar":"mini","width":"1000px", "height": "84px","customConfig":"/ckeditor/config.js"});
        }
      }
    });
    $(document).on('cocoon:after-insert', function(e, insertedItem, originalEvent) {
      $('.sortable-blocks').sortable({
        items: 'div.block-box',
        start: function (_, ui) {
          const id_textarea = ui.item.find(".block-ckeditor").attr("id");
          if (typeof CKEDITOR != 'undefined' && id_textarea) {
            CKEDITOR.instances[id_textarea].destroy();
          }
        },
        stop: function (_, ui) {
          const id_textarea = ui.item.find(".block-ckeditor").attr("id");
          if (typeof CKEDITOR != 'undefined' && id_textarea) {
            CKEDITOR.replace(id_textarea, {"language":"ja","toolbar":"mini","width":"1000px", "height": "84px","customConfig":"/ckeditor/config.js"});
          }
        }
      });
    });
  },
  handleSubmit: function () {
    $(document).on('submit', '#event-locations-form', function(event) {
      $('.locations-wrapper').each(function() {
        let self = $(this);
        let sorted = self.find('.sortable-blocks').sortable('toArray');

        jQuery.each(sorted, function(i, val) {
          self.find('#' + val).find('input.order-field').val(i + 1);
        });
      });
    });
  },
  generateDataSort: function () {
    $(document).on('cocoon:after-insert', function(e, insertedItem, originalEvent) {
      let item = insertedItem[0];
      let orderFields = item.getElementsByClassName('order-field');
      if (orderFields.length > 0) {
        let blockName = item.getAttribute('data-block');
        let insertedItemId = orderFields[0].getAttribute('name').split('][').slice(-2)[0];

        item.setAttribute('id', blockName + '-' + insertedItemId);
      }
    });
  },
  savePreview: function () {
    $('.event-builder-preview').on('click', function () {
      $("#betsudai_home_event_builder_action").val('save-preview')
      $(".event-builder-submit").click()
    })
  },
  // handleSearchBukken: function() {
  //   $(".event-location-search-bukken").on('click', function () {
  //     if ($('#bukken_type').val() === '') {
  //       toastr.error("必ず物件種別を選択してください！")
  //     } else {
  //       EventLocationInfos.searchBukken()
  //     }
  //
  //     $(".btn-event-location-assign-bukken").on('click', function () {
  //       EventLocationInfos.selected_bukkens = $.merge([], EventLocationInfos.bukkens);
  //       $(".modal-search-bukken").modal('hide')
  //       EventLocationInfos.locationWrapper.find('.event-location-bukken-block').remove()
  //       EventLocationInfos.bukkens.forEach(bukken_id => {
  //         EventLocationInfos.currentBukkenId = bukken_id
  //         EventLocationInfos.locationWrapper.find('.add-event-location-bukken-btn').trigger('click')
  //       })
  //     })
  //   });
  // },
  // searchBukken: function () {
  //   const form_search = $('#event_location_search_bukken')
  //   const url_search = form_search.attr('action');
  //   let data = form_search.serialize()
  //
  //   $("#table_search_bukkens").html('<tbody><tr><td class="text-center">検索中</td></tr></tbody>');
  //   $.ajax({
  //     url: url_search,
  //     type : 'GET',
  //     data : data,
  //     success: function () {
  //       $('.modal-loading').modal('hide')
  //       EventLocationInfos.addBukken();
  //       EventLocationInfos.toggleBtnAddBukken();
  //       $.each($(".event-location-add-bukken"), function() {
  //         if(EventLocationInfos.checkBukkenExist($(this).data('id'))){
  //           $(this).removeClass('bgcolor-gray').addClass('bgcolor-dusty-gray').text('追加済');
  //         }
  //       })
  //     },
  //     error: function (request, status, error) {
  //       handleAjaxError(request, status, error);
  //     }
  //   })
  // },
  // addBukken: function () {
  //   $(".event-location-add-bukken").on('click', function () {
  //     let bukken_id = $(this).data('id')
  //     if(EventLocationInfos.checkBukkenExist(bukken_id)){
  //       $(this).removeClass('bgcolor-dusty-gray').addClass('bgcolor-gray').text('追加する');
  //       EventLocationInfos.bukkens = EventLocationInfos.bukkens.filter(id => id !== String(bukken_id))
  //     } else {
  //       if (EventLocationInfos.bukkens.length > 4) {
  //         toastr.error("物件を5件以上追加できません。")
  //       } else {
  //         $(this).removeClass('bgcolor-gray').addClass('bgcolor-dusty-gray').text('追加済');
  //         EventLocationInfos.bukkens.push(String(bukken_id));
  //       }
  //     }
  //
  //   });
  // },
  // toggleBtnAddBukken: function () {
  //   $(".event-location-add-bukken").each(function() {
  //     let bukken_id = $(this).data('id')
  //
  //     if (EventLocationInfos.checkBukkenExist(bukken_id)) {
  //       $(this).addClass('bgcolor-dusty-gray').text('追加済').show()
  //     } else {
  //       $(this).addClass('bgcolor-gray').text('追加する').show()
  //     }
  //   })
  // },
  // checkBukkenExist: function (bukken_id) {
  //   return EventLocationInfos.bukkens.map(i => String(i)).includes(String(bukken_id));
  // },
}

export default EventLocationInfos;
