ImageCommon = {
  init: function() {
    ImageCommon.initUpdateImage();
    ImageCommon.initDragArea();
    ImageCommon.initMultipleFileInput();
    ImageCommon.initMultipleDragArea();
    ImageCommon.renumberSyuhenIndex();
    ImageCommon.changePicOrder();
    ImageCommon.savePicOrder();
    ImageCommon.changeImage();
  },

  initUpdateImage: function() {
    $(document).on('change', '.change-image', function () {
      if (!ImageCommon.checkExtShowAlert($(this))) return false;
      if (!ImageCommon.checkSizeShowAlert($(this))) return false;

      ImageCommon.updatePrevImage($(this));
      ImageCommon.clearRemoveVal($(this));
      ImageCommon.clearBulkUrlVal($(this));
    });

    $(document).on('click', '.remove-image-btn', function () {
      var remove = $(this).parent().find('.remove-image');
      remove.val('true');
      $(this).parent().find('.change-image').data('image-value', '')
      ImageCommon.clearImagePrev($(this));
      ImageCommon.clearInputVal($(this));
      ImageCommon.clearBulkUrlVal($(this));
      ImageCommon.hideActionButton($(this));
    });

    $(document).on('change', '.change-image-bulk', function () {
      var url = $(this).val();
      ImageCommon.updatePrevImageFromURL($(this), url);
      ImageCommon.clearInputVal($(this));
      ImageCommon.clearRemoveVal($(this));
    });
  },

  checkSizeShowAlert: function(input) {
    if ($(input)[0].files[0].size > 100000000) {
      alert("100MB以下のファイルを選択していください。");
      ImageCommon.clearInputVal(input);
      return false;
    }

    return true;
  },

  checkExtShowAlert: function(input) {
    var ext_check = ImageCommon.checkExt(input);
    switch (ext_check) {
      case 0:
        return true;
      case 1:
        alert("拡張子は.pdfのみ選択可能です");
        ImageCommon.clearInputVal(input);
        return false;
      case 2:
        alert("拡張子は.jpgのみ選択可能です");
        ImageCommon.clearInputVal(input);
        return false;
      default:
        alert("例外エラー");
        ImageCommon.clearInputVal(input);
        return false;
    }
  },

  clearRemoveVal: function(input) {
    var remove = $(input).parent().find('.remove-image');
    remove.val('');
  },

  clearBulkUrlVal: function(input) {
    var bulkUrl = $(input).parent().find('.change-image-bulk');
    bulkUrl.val('');
  },

  clearInputVal: function(input) {
    var input = $(input).parent().find('input[type=file]');
    input.val('');
  },

  clearImagePrev: function(input) {
    var imagePrev = $(input).parent().parent().find("#image_prev");
    imagePrev.attr('style', 'background-image:none');
    var pdfFinenameArea = imagePrev.parent().find('.pdf-filename');
    pdfFinenameArea.attr('style', 'display:none');
  },

  updatePrevImage: function(input) {
    var input = $(input).parent().find('input[type=file]');
    var file = input.prop('files')[0];
    var fileReader = new FileReader();
    fileReader.onloadend = function() {
      var src = fileReader.result
      var element = input.parent().parent().find("#image_prev")
      if (src) {
        element.attr('style', 'background-image:url(' + src + ')');
      } else {
        element.attr('style', 'background-image:none');
      }
    }
    fileReader.readAsDataURL(file);
    ImageCommon.showActionButton(input);
  },

  updatePrevImageFromURL: function(input, src) {
    if (src) {
      var element = $(input).parent().parent().find("#image_prev");
      element.attr('style', 'background-image:url(' + src + ')');
    } else {
      element.attr('style', 'background-image:none');
    }
  },

  initDragArea: function() {
    ImageCommon.initSingleDragArea();
    ImageCommon.initBulkDragArea();
    ImageCommon.disableDragDrop(); // 枠外のドラッグ&ドロップを無効化
  },

  initSingleDragArea: function() {
    $(".dragarea").on('dragover', function (e) {
      e.stopPropagation();
      e.preventDefault();
    });
    $(".dragarea").on('dragleave', function (e) {
      e.stopPropagation();
      e.preventDefault();
    });
    $(".dragarea").on('drop', function (e) {
      e.stopPropagation();
      e.preventDefault();

      var fileInput = $(this).parent().find('input[type=file]');
      var files = e.originalEvent.dataTransfer.files;
      fileInput[0].files = files;

      if (!ImageCommon.checkExtShowAlert(fileInput)) return false;

      ImageCommon.updatePrevImage(fileInput);
      ImageCommon.clearRemoveVal(fileInput);
      ImageCommon.clearBulkUrlVal(fileInput);
    });

    // 周辺環境追加時
    $(".arealist").on('cocoon:after-insert', function () {
      var dragArea = $(this).find('.dragarea');
      dragArea.on('dragover', function (e) {
        e.stopPropagation();
        e.preventDefault();
      });
      dragArea.on('dragleave', function (e) {
        e.stopPropagation();
        e.preventDefault();
      });
      dragArea.on('drop', function (e) {
        e.stopPropagation();
        e.preventDefault();

        var fileInput = $(this).parent().find('input[type=file]');
        var files = e.originalEvent.dataTransfer.files;
        fileInput[0].files = files;

        if (!ImageCommon.checkExtShowAlert(fileInput)) return false;

        ImageCommon.updatePrevImage(fileInput);
        ImageCommon.clearRemoveVal(fileInput);
        ImageCommon.clearBulkUrlVal(fileInput);
      });
    });
  },

  initBulkDragArea: function() {
    $(".upload-dragarea").on('dragover', function (e) {
      e.stopPropagation();
      e.preventDefault();
    });
    $(".upload-dragarea").on('dragleave', function (e) {
      e.stopPropagation();
      e.preventDefault();
    });
    $(".upload-dragarea").on('drop', function (e) {
      e.stopPropagation();
      e.preventDefault();

      var files = e.originalEvent.dataTransfer.files;
      var currentNum = 1;
      var dragArea = $(this);
      var result
      $.each(files, function(index, file) {
        var input = ImageCommon.getFirstEmptyField(dragArea);
        if (input) {
          const dt = new DataTransfer();
          dt.items.add(file);
          input.files = dt.files;

          if (!ImageCommon.checkExtShowAlert($(input))) return false;

          ImageCommon.updatePrevImage($(input));
          ImageCommon.clearRemoveVal($(input));
          ImageCommon.clearBulkUrlVal($(input));

          result = true;
        }
      });
      if (result) {
        var messageArea = dragArea.parent().find('#message_area');
        ImageCommon.displaySuccessMessage(messageArea);
      }
    });
  },

  initMultipleFileInput: function() {
    $(document).on('change', '.change-multi-image', function () {
      if (!ImageCommon.checkExtShowAlert($(this))) return false;

      var fileInput = $(this).parent().find('input[type=file]');
      ImageCommon.updateMultiPrevImages(fileInput);
    });

    $(document).on('click', '.reset-multi-image', function () {
      $('.change-multi-image').val('');
      $('.multi-dragarea').empty();
    });

    $(document).on('click', '.remove-multi-image', function () {
      var removeIndex = $(this).data('prev-index')
      $(this).parent().remove();
      ImageCommon.removeFileInputOfIndex(removeIndex);

      // prev-index を振りなおすため
      var fileInput = $('.change-multi-image');
      ImageCommon.updateMultiPrevImages(fileInput);
    });
  },

  initMultipleDragArea: function() {
    $(".multi-dragarea").on('dragover', function (e) {
      e.stopPropagation();
      e.preventDefault();
    });
    $(".multi-dragarea").on('dragleave', function (e) {
      e.stopPropagation();
      e.preventDefault();
    });
    $(".multi-dragarea").on('drop', function (e) {
      e.stopPropagation();
      e.preventDefault();

      var fileInput = $(this).parent().find('input[type=file]');
      var files = e.originalEvent.dataTransfer.files;
      const dt = new DataTransfer();
      // 既にinput filesに入ってる画像と新たにドラッグした画像を足し合わせる
      $.each(fileInput[0].files, function(index, file) {
        dt.items.add(file);
      });
      $.each(files, function(index, file) {
        dt.items.add(file);
      });
      fileInput[0].files = dt.files;

      if (!ImageCommon.checkExtShowAlert(fileInput)) return false;

      ImageCommon.updateMultiPrevImages(fileInput);
    });

    // 枠内クリックでファイル選択を開く
    $(document).on('click', '.multi-dragarea', function (e) {
      if (!$(e.target).is('.remove-multi-image, .image_prev')) {
        $('.change-multi-image').click();
      }      
    });
  },

  updateMultiPrevImages: function(fileInput) {
    var files = fileInput[0].files

    $('.multi-dragarea').empty();
    $.each(files, function(index, file) {
      var fileReader = new FileReader();
      fileReader.onloadend = function() {
        var html = '<div class="active image_prev" style="background-image:none;"><a href="javascript:void(0);" class="remove-btn remove-multi-image" data-prev-index=' + index + '>×</a></div>'
        element = $(html).appendTo($('.multi-dragarea'))
        var src = fileReader.result
        if (src) {
          element.attr('style', 'background-image:url(' + src + ')');
        } else {
          element.attr('style', 'background-image:none');
        }
      }
      fileReader.readAsDataURL(file);
    });
  },

  removeFileInputOfIndex: function(removeIndex) {
    // removeIndex番目を除いてfileInputに再格納
    var fileInput = $('input[type=file]');
    const dt = new DataTransfer();
    $.each(fileInput[0].files, function(index, file) {
      if (removeIndex != index) {
        dt.items.add(file);
      }
    });
    fileInput[0].files = dt.files;
  },

  displaySuccessMessage: function(messageArea) {
    messageArea.html('画像を反映しました').show().delay(3000).fadeOut("slow");
  },

  getFirstEmptyField: function(element) {
    var target = element.data('target');
    if (target == 'tab2') {
      var fields = $('#tab2').find('input[type=file]');
    } else if (target == 'tab3') {
      var fields = $('#tab3').find('input[type=file]');
    } else {
      return
    }

    var emptyInputFields = fields.filter(function(index, input){
      var imagePrev = $(input).parent().parent().find("#image_prev")
      var backgroundImage = imagePrev.css('background-image')
      return (input.files.length == 0 && backgroundImage == 'none');
    });
    if (emptyInputFields) {
      return emptyInputFields[0];
    }
  },

  disableDragDrop: function() {
    $("#tab2, #tab3, #modalUploadNaigaikan, #modalUploadSyuhen").on('dragover', function (e) {
      e.stopPropagation();
      e.preventDefault();
    });
    $("#tab2, #tab3, #modalUploadNaigaikan, #modalUploadSyuhen").on('dragleave', function (e) {
      e.stopPropagation();
      e.preventDefault();
    });
    $("#tab2, #tab3, #modalUploadNaigaikan, #modalUploadSyuhen").on('drop', function (e) {
      e.stopPropagation();
      e.preventDefault();
    });
  },

  renumberSyuhenIndex: function() {
    $(".arealist").on('cocoon:after-insert cocoon:after-remove', function () {
      var syuhenElements = $(this).children('.nested-fields').not(':hidden');
      var is_fuk_bukken = $('#fukuoka_bukken_bukken_id').length ? true : false;
      var is_hosue = $('#bukken_bukken_type').val() == 1;
      var is_mansion = $('#bukken_bukken_type').val() == 2;
      var is_syueki = $('#bukken_bukken_type').val() == 4;
      var is_resort = $('#bukken_bukken_type').val() == 5;
      $.each(syuhenElements, function(index, element) {
        var index = index + 1;
        var label = $(element).find('.item' + '.head');
        label.text('周辺情報' + index);
        // TODO FIX 追加した周辺画像のpic-indexが反映されない

        switch(true) {
          case index < 9: {
            if(is_syueki || is_resort) {
              $(element).find('#facility-distance-note').text('');
              break;
            }
            $(element).find('#facility-distance-note').text('アットホーム・SUUMOに反映されます。※種別,施設名,距離の全てが入力されている場合のみ反映されます（画像は任意）');
            break;
          }
          case index < 10: {
            if(is_syueki || is_resort) {
              $(element).find('#facility-distance-note').text('');
              break;
            }
            $(element).find('#facility-distance-note').text('SUUMOに反映されます。※種別,施設名,距離の全てが入力されている場合のみ反映されます（画像は任意）');
            break;
          }
          default: {
            $(element).find('#facility-distance-note').text('');
          }
        }

        if (is_fuk_bukken) {
          $(element).find('#caption-note').text('');
        } else {
          switch(true) {
            case index < 4: {
              if (is_hosue || is_mansion) {
                $(element).find('#caption-note').text('ファクト・不動産ポータルに反映されます');
              } else {
                $(element).find('#caption-note').text('不動産ポータルに反映されます');
              }
              break;
            }
            case index < 5: {
              if (is_hosue) {
                $(element).find('#caption-note').text('ファクト・不動産ポータルに反映されます');
              } else {
                $(element).find('#caption-note').text('不動産ポータルに反映されます');
              }
              break;
            }
            case index < 10: {
              $(element).find('#caption-note').text('不動産ポータルに反映されます');
              break;
            }
            default: {
              $(element).find('#caption-note').text('');
            }
          }
        }
        last_index = index;
      });

      if (last_index >= 30) {
        $('.add_fields').hide();
      } else {
        $('.add_fields').show();
      }
    });
  },

  checkExt: function(input) {
    var path = location.pathname
    if (path.match(/^\/kanri\/sales\/fuk/) || path.match(/^\/kanri\/images\/fukuoka/)) {
      // 内外観の5枚目はpdf
      var index = $('#tab2').find('.change-image').index(input) + 1
      if (index == 5) {
        return ImageCommon.checkIfPDF(input);
      } else {
        return 0;
      }
    } else if (path.match(/^\/kanri\/sales/) || path.match(/^\/kanri\/images\/sale/)) {
      // 内外観の5枚目はpdf
      var index = $('#tab2').find('.change-image').index(input) + 1
      if (index == 5) {
        return ImageCommon.checkIfPDF(input);
      } else {
        return 0;
      }
    } else if (path.match(/^\/kanri\/rentals/) || path.match(/^\/kanri\/images\/rental/)) {
      // 内外観の22枚目はpdf
      var index = $('#tab2').find('.change-image').index(input) + 1
      if (index == 22) {
        return ImageCommon.checkIfPDF(input);
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  },

  checkIfPDF: function(input) {
    var filename = input.prop('files')[0].name;
    var ext = filename.match(/[^.]+$/).toString().toLowerCase();
    if (ext == 'pdf') {
      //alert('PDFファイルを選択しました。');
      pdfFilenameArea = input.parent().parent().find('.pdf-filename');
      pdfFilenameArea.html(filename);
      pdfFilenameArea.show();
      return 0;
    } else {
      return 1;
    }
  },

  checkIfJPG: function(input) {
    var filename = input.prop('files')[0].name;
    var ext = filename.match(/[^.]+$/).toString().toLowerCase();
    if (ext == 'jpg') {
      return 0;
    } else {
      return 2;
    }
  },

  changePicOrder: function() {
    $('#pic_sortable').sortable({
      cancel: '.fixed',
      revert: true,
      update: function(event,ui){
        // 画像を並び替えたあと、固定画像を正しい位置に配置しなおす
        fixed_items = $('#pic_sortable').children('.fixed').detach();
        fixed_items = fixed_items.sort(function(a, b){
          return ($(a).data('index') > $(b).data('index') ? 1 : -1);
        });
        fixed_items.each(function(i, element){
          index = $(element).data('index');
          before_item = $('#pic_sortable').children('tr').eq(index-1)
          if (before_item.length != 0) {
            $(element).insertBefore($('#pic_sortable').children('tr').eq(index-1))
          } else {
            $(element).appendTo($('#pic_sortable')) // 挿入要素が存在しない場合(賃貸22番目)、末尾に追加
          }
        });
        // 賃貸の場合、種別の表記を上書きしなおす
        if ($('#pic_bukken_type_name').val().match(/^rentals/)) {
          ImageCommon.RewriteRentalPicTypes();
        }
      }
    });
  },
  savePicOrder: function() {
    $('.save_pic_order').bind('click', function (e) {
      $('.save_pic_order').prop('disabled', true)
      var bukken_type_name = $('#pic_bukken_type_name').val();
      var link = `/kanri/${bukken_type_name}/change_pic_order`
      var id = $('#pic_bukken_id').val();
      var indexesObj = $('#pic_sortable').children('tr').map(function(index, element){ return $(element).data('index') });
      var indexes = $.makeArray(indexesObj)
      var method = 'POST'
      $.ajax({
        url: link,
        data: { 'id': id, 'pic_order_indexes': indexes },
        method: method,
        success: function (response) {
          if (response.result) {
            window.location.href = response.url;
          } else {
            $('.save_pic_order').prop('disabled', false);
          }
        }
      });
    });
  },
  RewriteRentalPicTypes: function() {
    var items = $('#pic_sortable').children('tr');
    items.each(function(i, element){
      var pic_type = $(`#pic_types_${i}`).val()
      $(element).find('.pic_type').html(pic_type)
    });
  },

  hideActionButton: function(element) {
    if (element.data("hidden")) {
      element.hide();
      let parent = $(element).parent();
      parent.find('.change-image-btn').hide();
      parent.find('#image_prev').addClass("hidden");
    }

    let removeBtn = $(element).parent().find('.remove-image-btn')
    if (removeBtn && removeBtn.hasClass('toggle')) {
      removeBtn.hide();
    }
    let prevWrapper = $(element).parent().find('.wrap-image-prev');
    if (prevWrapper && prevWrapper.hasClass('toggle')) {
      prevWrapper.hide();
    }
  },

  showActionButton: function(element) {
    let parent = $(element).parent();
    parent.find('.change-image-btn').show();
    parent.find('.remove-image-btn').show();
    parent.find('#image_prev').removeClass("hidden");

    let prevWrapper = parent.find('.wrap-image-prev');
    if (prevWrapper && prevWrapper.hasClass('toggle')) {
      prevWrapper.show();
    }
  },

  changeImage: function() {
    $(".change-image-btn").click(function(){
      $(this).parent().parent().parent().find('input[type=file]').click();
    });
  },
}
