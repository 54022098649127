import {handleAjaxError} from "../handle_error";

var MagazineNisetai = {
  init: function () {
    MagazineNisetai.start()
    MagazineNisetai.toggleBtnNewMagazineNisetai()
    MagazineNisetai.resetSort()
    MagazineNisetai.newMagazineNisetai()
    MagazineNisetai.deleteMagazineNisetai()
    MagazineNisetai.updateMagazineNisetai()
  },

  start: function () {
    $('.sortable-magazine-nisetai').sortable(
      {
        handle: '.sort-icon-nisetai',
        cursor: 'move',
        scroll: false
      }
    )
  },

  updateMagazineNisetai: function () {
    $(document).on('click', '.btn-update-magazine-nisetai', function () {
      const order = $('.sortable-magazine-nisetai').sortable(
        'toArray',{ attribute: 'data-magazine-nisetai' }
      );
      // handle call api to update index magazine nisetai after sort
      $.ajax({
        url: '/kanri/magazine_nisetai/update_all',
        data: {
          'order': order
        },
        method: 'POST',
        success: function(res) {
          toastr.success('更新されました')
          $('#magazine-nisetai-modal').modal('hide')
        },
        error: function (request, status, error) {
          handleAjaxError(request, status, error);
        }
      })
    })
  },

  toggleBtnNewMagazineNisetai: function () {
    $('#bh_nisetai_magazine_id').on('change', function () {
      $('.btn-new-magazine-nisetai').prop('disabled', $(this).val().length == 0)
    })
  },

  resetSort: function () {
    $('#magazine-nisetai-modal').on('hidden.bs.modal', function () {
      $(".sortable-magazine-nisetai").sortable("cancel")
    })
  },

  newMagazineNisetai: function () {
    $(document).on('click', '.btn-new-magazine-nisetai', function () {
      const inputVal = $('#bh_nisetai_magazine_id option:selected').val()
      $.ajax({
        url: '/kanri/magazine_nisetai',
        data: {
          'magazine_nisetai[bh_nisetai_magazine_id]': inputVal,
        },
        method: 'POST',
        success: function(res) {
          document.getElementById('bh_nisetai_magazine_id').selectedIndex = 0
        },
        error: function (request, status, error) {
          handleAjaxError(request, status, error);
        }
      })
    })
  },

  deleteMagazineNisetai: function () {
    $(document).on('click', '.btn-delete-magazine-nisetai', function () {
      const idMagazineNisetai = $(this).data('magazine-nisetai-id')
      $.ajax({
        url: `/kanri/magazine_nisetai/${idMagazineNisetai}`,
        method: 'DELETE',
        success: function(res) {
          toastr.success('削除されました')
        },
        error: function (request, status, error) {
          handleAjaxError(request, status, error);
        },
      })
    })
  }
}

export default MagazineNisetai;
