import {handleAjaxError} from "../handle_error";
import {handleDownloadCSV} from "../download_csv";

var Company = {
  init: function () {
    Company.searchCompany();
    Company.sortCompany();
    Company.exportCsvCompany();
  },

  searchCompany: function () {
    $('#btnSearchCompany').on('click', function () {
      Company.submitForm()
    })
  },

  sortCompany: function () {
    $('.table-companies').on('click', '.sort-asc, .sort-desc', function () {
      const order = $(this).data('order')
      const sort_by = $(this).data('sort_by')
      Company.submitForm(order, sort_by)
    })
  },

  submitForm: function (order='', sort_by='') {
    const form_search = $('#formCompany')
    const url_search = form_search.attr('action');
    $('.modal-loading').modal('show')
    $.ajax({
      url: url_search,
      type : 'GET',
      data : form_search.serialize() + `&order=${order}&sort_by=${sort_by}`,
      success: function () {
        $('.modal-loading').modal('hide')
      },
      error: function (request, status, error) {
        handleAjaxError(request, status, error);
        $('.modal-loading').modal('hide')
        $(this).prop('disabled', false);
      }
    })
  },

  exportCsvCompany: function () {
    $('.btnExportCsvCompany').on('click', function () {
      $('.modal-loading').modal('show');
      const form_search = $('#formCompany')
      let request_url = $(this).data('url-csv') + '?' + form_search.serialize()
      handleDownloadCSV(request_url, "company")
    })
  }
}

export default Company;
