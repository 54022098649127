ImageSale = {
  init: function() {
    ImageSale.handelMoveFolder();
    ImageSale.handleSortFolderList();
    ImageSale.handleOpenFolderTree();
    ImageSale.handleDeleteItem();
    ImageSale.handleModal();
    ImageSale.initFolder();
    ImageSale.displaySortIcon('updated_at', 'asc')
  },

  handelMoveFolder: function() {
    $("#sale_folder_path").on('click', '.folder-link', function () {
      var folderId = $(this).data('folder-id');
      ImageSale.updateFolderList(folderId);
      ImageSale.updateFolderPath(folderId);
    });

    $("#sale_folder_list").on('click', '.folder-link', function () {
      var folderId = $(this).data('folder-id');
      ImageSale.updateFolderList(folderId);
      ImageSale.updateFolderPath(folderId);
    });

    $("#sale_folder_tree").on('click', '.folder-link', function () {
      var folderId = $(this).data('folder-id');
      ImageSale.updateFolderList(folderId);
      ImageSale.updateFolderPath(folderId);
    });
  },

  updateFolderList: function(folderId, sortColumn = null, sortOrder = null) {
    alert
    if (!folderId) { folderId = $('#sale_image_folder_current_folder_id').val() }
    if (!folderId) { return }

    if (!sortColumn) { sortColumn = $('#current_sort_column').val() }
    if (!sortOrder) { sortOrder = $('#current_sort_order').val() }
    response = $.ajax({
      url: '/kanri/images/sale/get_folder_file_list',
      data: { folder_id: folderId, sort_column: sortColumn, sort_order: sortOrder },
      method: 'GET',
      success: function(result) {
        // フォルダ一覧の更新
        $("#sale_folder_list").empty();
        $.each(result['folders'], function(index, folder) {
          var decodedName = decodeURI(folder['name'])
          if (folder['deletable']) {
            var changeFolderModal = '<div aria-hidden="true" aria-labelledby="modalChangeFolder_' + folder['id'] + '" class="modal fade change-modal" id="modalChangeFolder_' + folder['id'] + '" aria-modal="true" role="dialog">' +
            '<div class="modal-dialog modal-lg" role="document">' +
            '<div class="modal-content">' +
            '<div class="grayback">' +
            '<div class="hoverwindow bgcolor-white">' +
            '<div class="title">フォルダ名の変更</div>' +
            '<ul class="list">' +
            '<form class="edit_sale_image_folder" id="edit_sale_image_folder_' + folder['id'] + '" action="/kanri/images/sale/' + folder['id'] + '/update_folder" accept-charset="UTF-8" method="post">' +
            '<input value="' + $('meta[name="csrf-token"]').attr('content') +'" type="hidden" name="authenticity_token">' +
            '<input value="' + folderId +'" type="hidden" name="sale_image_folder[current_folder_id]" id="sale_image_folder_current_folder_id">' +
            '<div><input class="input-text w300px" placeholder="フォルダ名を入力" type="text" value="' + folder['name'] + '" name="sale_image_folder[name]" id="sale_image_folder_name"></div>' +
            '<div class="mt-2"><textarea class="input-text w300px image-note" placeholder="備考を入力" name="sale_image_folder[note]" id="sale_image_folder_note">' + folder['note'] + '</textarea></div>' +
            '<div class="mt-2"><input type="submit" name="commit" value="保存" class="input-button change-records" data-disable-with="保存"></div>' +
            '</form>' +
            '<div class="error-messages" id="sale_folder_errors_' + folder['id'] + '"></div>' +
            '</ul>' +
            '<div class="btn-close"><a class="btn-close__link" data-dismiss="modal"> 閉じる</a></div>' +
            '</div>' +
            '</div>' +
            '</div>' +
            '</div>' +
            '</div>'
            var changeLink = '<a class="btn-change__link link__change" href="#modalChangeFolder_' + folder['id'] + '" data-toggle="modal" data-change-url="/kanri/images/sale/' + folder['id'] + '/update_folder?current_folder_id=' + folderId +'">フォルダ名・備考編集</a>'
            var deleteLink = '<a class="link__delete" href="#delete_record_folder" data-toggle="modal" data-delete-url="/kanri/images/sale/' + folder['id'] + '/destroy_folder?current_folder_id=' + folderId +'">削除</a>'
          } else {
            var changeFolderModal = ''
            var changeLink = ''
            var deleteLink = ''
          }
          var enable_edit_folder = $('#enable_edit_folder').val();
          if (enable_edit_folder == 'true' && folder['deletable']) {
            var editMenuHtml = '<div class="btn-group dropleft"><button aria-expanded="false" aria-haspopup="true" class="dropdown-toggle"' +
              'data-toggle="dropdown" type="button"><i class="fas fa-ellipsis-h"></i></button>' +
              '<div class="dropdown-menu" style="">' +
              '<ul>' +
              '<li class="edit-action image folder"><i class="fa fa-pencil"></i>' + changeLink + '</li>' +
              '<li class="dropdown-divider"></li>' +
              '<li class="delete-action image folder"><i class="fa fa-trash"></i>' + deleteLink + '</li>' +
              '</ul>' +
              '</div>' +
              '</div>'
          } else {
            var editMenuHtml = ''
          }

          if ($("#sale_folder_list").data('type') == 'bukken') {
            var element = '<li class="column">' +
            '<div class="item btn-folder"><span class="btn-folder__link"></span></div>' +
            '<div class="item"><a class="folder-link" href="javascript:void(0);" data-folder-id=' + folder['id'] + '>' + decodedName + '</a>' + '</div>' +
            '<div class="item">' + folder['note'] + '</div>' +
            '<div class="item">' + folder['time'] + '</div>' +
            '<div class="item"></div>' +
            '</li>';
          } else {
            var element = '<li class="column">' +
            '<div class="item btn-folder"><span class="btn-folder__link"></span></div>' +
            '<div class="item"><a class="folder-link" href="javascript:void(0);" data-folder-id=' + folder['id'] + '>' + decodedName + '</a>' + '</div>' +
            '<div class="item">' + folder['note'] + '</div>' +
            '<div class="item">' + folder['time'] + '</div>' +
            '<div class="item btn-file"></div>' + // マージン揃えるため空divを入れる
            '<div class="item">' + editMenuHtml + '</div>' +
            '</li>';
          }
  
          $("#sale_folder_list").append(element);
          $(".popup").append(changeFolderModal);
        });

        // ファイル一覧の更新
        $("#sale_file_list").empty();
        $.each(result['files'], function(index, file) {
          var decodedName = decodeURI(file['name'])
          var decodedUrl = decodeURI(file['url'])
          var downloadUrl = '/kanri/images/sale/download?id=' + file['id'];
          var copy_id = 'url-' + file['id'];
          var changeFileModal = '<div aria-hidden="true" aria-labelledby="modalChangeFile_' + file['id'] + '" class="modal fade change-modal" id="modalChangeFile_' + file['id'] + '" aria-modal="true" role="dialog">' +
          '<div class="modal-dialog modal-lg" role="document">' +
          '<div class="modal-content">' +
          '<div class="grayback">' +
          '<div class="hoverwindow bgcolor-white">' +
          '<div class="title">ファイル名の変更</div>' +
          '<ul class="list">' +
          '<form class="edit_sale_image_file" id="edit_sale_image_file_' + file['id'] + '" action="/kanri/images/sale/' + file['id'] + '/update_file" accept-charset="UTF-8" method="post">' +
          '<input value="' + $('meta[name="csrf-token"]').attr('content') +'" type="hidden" name="authenticity_token">' +
          '<input value="' + folderId +'" type="hidden" name="sale_image_file[current_folder_id]" id="sale_image_file_current_folder_id">' +
          '<div><input class="input-text w300px" placeholder="フォルダ名を入力" type="text" value="' + file['name'] + '" name="sale_image_file[name]" id="sale_image_file_name"></div>' +
          '<div class="mt-2"><input type="submit" name="commit" value="保存" class="input-button change-records" data-disable-with="保存"></div>' +
          '</form>' +
          '<div class="error-messages" id="sale_file_errors_' + file['id'] + '"></div>' +
          '</ul>' +
          '<div class="btn-close"><a class="btn-close__link" data-dismiss="modal"> 閉じる</a></div>' +
          '</div>' +
          '</div>' +
          '</div>' +
          '</div>' +
          '</div>'

          if (file['deletable']) {
            var deleteLink = '<a class="link__delete" href="#delete_record_file" data-toggle="modal" data-delete-url="/kanri/images/sale/' + file['id'] + '/destroy_file?current_folder_id=' + folderId +'">削除</a>'
          } else {
            var deleteLink = ''
          }
          var changeLink = '<a class="btn-change__link link__change" href="#modalChangeFile_' + file['id'] + '" data-toggle="modal" data-change-url="/kanri/images/sale/' + file['id'] + '/update_folder?current_folder_id=' + folderId +'">ファイル名編集</a>'
          var editMenuHtml = '<div class="btn-group dropleft"><button aria-expanded="false" aria-haspopup="true" class="dropdown-toggle"' +
            'data-toggle="dropdown" type="button"><i class="fas fa-ellipsis-h"></i></button>' +
            '<div class="dropdown-menu" style="">' +
            '<ul>' +
            '<li class="edit-action image file"><i class="fa fa-pencil"></i>' + changeLink + '</li>' +
            '<li class="dropdown-divider"></li>' +
            '<li class="delete-action image file"><i class="fa fa-trash"></i>' + deleteLink + '</li>' +
            '</ul>' +
            '</div>' +
            '</div>'

          if ($("#sale_file_list").data('type') == 'bukken') {
            var element = '<li class="column">' +
            '<div class="item btn-pic"><img src="' + decodedUrl + '" width="50" height="50"></div>' +
            '<div class="item"><a href="' + decodedUrl + '" target="_blank">' + decodedName + '</a>' + '</div>' + 
            '<div class="item"></div>' +
            '<div class="item">' + file['time'] + '</div>' +
            '<div class="item"><input class="input-button" type="submit" value="画像を選択" onclick="ImageSale.loadImageUrl(' + file['id'] + ')"></div>' +
            '</li>';
          } else {
            var element = '<li class="column">' +
            '<div class="item btn-pic"><img src="' + decodedUrl + '" width="50" height="50"></div>' +
            '<div class="item"><a href="' + decodedUrl + '" target="_blank">' + decodedName + '</a></div>' + 
            '<div class="item"></div>' +
            '<div class="item">' + file['time'] + '</div>' +
            '<div class="item btn-file"><a class="btn-download__link" href="' + downloadUrl + '" target="_blank"></a></div>' +
            '<div class="item">' + editMenuHtml + '</div>' +
            '</li>';
          }

          $("#sale_file_list").append(element);
          $("#" + copy_id).autoResize();
          $(".popup").append(changeFileModal);
        });

        // 現在のフォルダidを更新
        $('#sale_image_file_current_folder_id').val(folderId)
        $('#sale_image_folder_current_folder_id').val(folderId)
      }
    });
  },

  loadImageUrl: function(file_id) {
    response = $.ajax({
      url: '/kanri/images/sale/get_file_url',
      data: { file_id: file_id },
      method: 'GET'
    }).done(function(file){
      $(function(){
        let pic_tab = $('#pic_tab').val()
        let pic_index = $('#pic_index').val()
        let partition_index = $('#partition_index').val()

        var path = location.pathname
        if (path.match(/^\/kanri\/rentals/)) {
          var pic_id_prefix = 'apartment_apartment_pic_attributes_remote_pic'
        } else {
          var pic_id_prefix = 'bukken_bukken_pic_attributes_remote_pic'
        }
        if (file['url']) {
          switch (pic_tab) {
            case 'naigaikan':
              $('[id$=' + pic_id_prefix + pic_index + '_image_url').val(file['url']).change();
              break;
            case 'multi_partition':
              $(`#bukken_multiple_partitions_attributes_${partition_index}_bukken_land_area_pics_attributes_${pic_index - 1}_remote_image_url`).val(file['url']).change();
              break;
            default:
              var visibleFields = $('.arealist').children('.nested-fields:visible');
              $(visibleFields[pic_index - 1]).find('[id$=remote_pic_image_url').val(file['url']).change();
          }
        }
        toastr.success('画像' + pic_index + 'に読み込みました。')
        $('#modalSelectImage').modal('hide');
      });
    }).fail(function(){
      toastr.error('画像を読み込めませんでした。')
    });
  },

  updateFolderPath: function(folderId) {
    if (!folderId) { folderId = $('#sale_image_folder_current_folder_id').val() }
    if (!folderId) { return }

    response = $.ajax({
      url: '/kanri/images/sale/get_folder_path',
      data: { folder_id: folderId },
      method: 'GET',
      success: function(folders) {
        if (folders.length > 0) {
          var FolderPathElement = ''
          $.each(folders, function(index, folder) {
            if (folder['id'] == folderId) {
              FolderPathElement = FolderPathElement + folder['name'] + ' / '
            } else {
              FolderPathElement = FolderPathElement + '<a href="javascript:void(0);" class="folder-link" data-folder-id=' + folder['id'] + '>' + folder['name'] + '</a> / '
            }
          });
          FolderPathElement = FolderPathElement.replace(/ \/ $/, '');
          $('#sale_folder_path').html(FolderPathElement);
        }
      }
    });
  },

  handleSortFolderList() {
    $('.sale-sort-file-column').on('click', function () {
      const currentFolderId = $('#sale_image_file_current_folder_id').val()
      const currentSortColumn = $('#current_sort_column').val()
      const currentSortOrder = $('#current_sort_order').val()
      const clickedColumn = $(this).data('column')
  
      if (clickedColumn == currentSortColumn) {
        if (currentSortOrder == 'asc') {
          ImageSale.updateFolderList(currentFolderId, clickedColumn, 'desc')
          $('#current_sort_order').val('desc')
          ImageSale.displaySortIcon(clickedColumn, 'desc')
        } else {
          ImageSale.updateFolderList(currentFolderId, clickedColumn, 'asc')
          $('#current_sort_order').val('asc')
          ImageSale.displaySortIcon(clickedColumn, 'asc')
        }
      } else {
        if (currentSortColumn == 'name') {
          ImageSale.updateFolderList(currentFolderId, 'updated_at', currentSortOrder)
          $('#current_sort_column').val('updated_at')
          ImageSale.displaySortIcon('updated_at', currentSortOrder)
        } else {
          ImageSale.updateFolderList(currentFolderId, 'name', currentSortOrder)
          $('#current_sort_column').val('name')
          ImageSale.displaySortIcon('name', currentSortOrder)
        }
      }
    });
  },

  displaySortIcon(column, order) {
    $('.sort-icon').hide()
    $(`.sort-icon.${column}-${order}`).show()
  },

  handleOpenFolderTree: function() {
    $("#sale_folder_tree").on('click', '.folder-icon', function () {
      var folderElement = $(this)
      var folderId = folderElement.data('folder-id')
      var openBtnClass = 'btn-folder02__link'
      var closeBtnClass = 'btn-folder__link'

      if (folderElement.hasClass(openBtnClass)) {
        folderElement.removeClass(openBtnClass);
        folderElement.addClass(closeBtnClass);
        ImageSale.closeFolderTree(folderId);
      } else {
        folderElement.addClass(openBtnClass);
        folderElement.removeClass(closeBtnClass);
        ImageSale.updateFolderTree(folderId);
      }
    });
  },

  closeFolderTree: function(folderId) {
    var childFoldersElement = $('#sale_folder_tree_' + folderId).parent().next();
    childFoldersElement.empty();
  },

  updateFolderTree: function(folderId) {
    if (!$('#sale_folder_tree').length) { return }

    if (folderId == 1) { // root
      var childFoldersElement = $('#sale_folder_tree').children('ul');
    } else {
      var childFoldersElement = $('#sale_folder_tree_' + folderId).parent().next();
    }
    childFoldersElement.empty();

    response = $.ajax({
      url: '/kanri/images/sale/get_folder_list',
      data: { folder_id: folderId },
      method: 'GET',
      success: function(folder) {
        if (folder['children'].length > 0) {
          var html = '';
          html = ImageSale.generateFolderHtml(folder['children']);

          childFoldersElement.html(html);
        }
      }
    });
  },

  generateFolderHtml: function(folders) {
    if (folders.length < 1) {
      return '';
    }

    var html = '';
    $.each(folders, function(index, folder) {
      html = html +
      '<li class="folder-list__item">' +
      '<div class="item">' +
      '<div class="btn-folder">' +
      '<a class="btn-folder02__link folder-icon" href="javascript:void(0);" data-folder-id=' + folder['id'] + ' id="sale_folder_tree_' + folder['id'] + '"></a>' +
      '<a class="folder-link" href="javascript:void(0);" data-folder-id=' + folder['id'] + '><span id="folder_name_' + folder['id'] + '">' + folder['name'] + '</span></a>' +
      '</div>' +
      ImageSale.generateFolderHtml(folder['children']) + 
      '</div>' +
      '</li>'
    });
    html = '<ul class="folder-list">' +
    html +
    '</ul>'

    return html;
  },

  handleDeleteItem: function() {
    $('#sale_folder_list').on('click', '.link__delete', function () {
      const url_delete = $(this).data('delete-url')
      $('#delete_record_folder .delete-records').attr('href', url_delete)
    })

    $('#sale_file_list').on('click', '.link__delete', function () {
      const url_delete = $(this).data('delete-url')
      $('#delete_record_file .delete-records').attr('href', url_delete)
    })
  },

  handleModal: function() {
    $("#modalSelectImage").on('click', function (e) {
      if(!$(e.target).closest('.hoverwindow').length){
        $(this).modal('hide');
      }
    });

    $(".select_sale_image").on('click', function (e) {
      let pic_tab = $(this).data('pic-tab');
      let pic_index = $(this).data('pic-index');
      let partition_index = $(this).data('partition-index');
      $('#pic_tab').val(pic_tab);
      $('#pic_index').val(pic_index);
      $('#partition_index').val(partition_index)

      ImageSale.updateFolderPath();
      ImageSale.updateFolderList();
      ImageSale.updateFolderTree(1);
    });
  },

  initFolder: function() {
    var path = location.pathname
    // 画像管理のみ
    if (path.match(/^\/kanri\/images\/sale/)) {
      ImageSale.updateFolderPath();
      ImageSale.updateFolderList();
      ImageSale.updateFolderTree(1);
    }
  },
}
