var NewsCorporate = {
  init: function() {
    NewsCorporate.filterCategory();
    NewsCorporate.sort();
  },

  filterCategory: function () {
    $('#betsudai_news_category').bind('change', function () {
      const data = { category: $(this).val() }
      NewsCorporate.searchNews(data);
    })
  },

  sort: function () {
    $('.news-co-list').on('click', '.news-sort', function () {
      const order = $(this).data('order');
      const sort_by = $(this).data('sort_by');
      const category = $('#betsudai_news_category').val()
      const data = { 'sort_by': sort_by, 'order': order, 'category': category }
      NewsCorporate.searchNews(data);
    })
  },

  searchNews: function (data) {
    $.ajax({
      url: '/kanri/news/corporate/search',
      data: data,
      method: 'get',
      success: function (response) {
      },
    });
  },
}

export default NewsCorporate;