Staff = {
  init: function() {
    Staff.submitStaff();
    Staff.searchStaff();
    Staff.handleSort();
    Staff.handleSubmitStaffOrder();
    Staff.sortDepartment();
    Staff.toggleBukkenManager();
  },
  submitStaff: function() {
    $('.submit-staff').bind('click', function (e) {
      e.preventDefault();
      if (Staff.isValidForm()) {
        $('.modal-loading').modal('show');
        Staff.checkValidate();
        return false;
      } else {
        Staff.showErrorMsg('js');
        return false;
      }
    })
  },
  isValidForm: function() {
    const inputs = $('.input-required');
    var rs = true
    var names = [];
    for(var i=0; i < inputs.length; i++){
      if($(inputs[i]).val().trim().length == 0){
        rs = false;
        names.push($(inputs[i]).data('validate-name'))
      }
    }
    // validate staff profile image when display site = ベツダイホーム
    if (this.ValidateImageStaff().length > 0) {
      rs = false
      names = names.concat(this.ValidateImageStaff())
    }
    if(rs == false){
      names = names.filter(function(item, i, ar){ return ar.indexOf(item) === i; })
      $('.staff-error-message span.fields').html(names.join('、'));
    }
    return rs;
  },
  searchStaff: function () {
    $('#btnSearchAccount').on('click', function () {
      Staff.submitForm()
    })
  },
  submitForm: function () {
    const form_search = $('#formStaffSearch')
    const url_search = form_search.attr('action');
    $('.modal-loading').modal('show')
    $.ajax({
      url: url_search,
      type : 'GET',
      data : form_search.serialize(),
      success: function () {
        $('.modal-loading').modal('hide')
      },
      error: function () {
        toastr.error("エラーが発生しました。");
        $('.modal-loading').modal('hide')
        $(this).prop('disabled', false);
      }
    })
  },
  handleSort: function () {
    $('.customerlist').sortable({
      items: 'tr.sortable',
      update: function(table, ui) {
        let infoMessage = $(ui.item[0]).attr('data-id') + "がドラッグ&ドロップされました。";

        $('.account-drag-info-area .info-area.message-info').html(infoMessage);
        $('.account-drag-info-area').fadeIn(500);
      },
    });
  },
  handleSubmitStaffOrder: function () {
    $('#submit-staff-order').on('click', function (event) {
      event.preventDefault();
      $('.modal-loading').modal('show');

      let order = $('.customerlist').sortable('toArray', { attribute: 'data-id' });
      let url = $(this).data('url');

      $.ajax({
        url: url,
        type : 'POST',
        data : { order: order },
        success: function () {
          $('.account-drag-info-area').fadeOut(500);
          $('.modal-loading').modal('hide');
        },
        error: function (request, status, error) {
          $('.modal-loading').modal('hide');
          $('.account-drag-info-area .info-area.message-info').html('エラーが発生しました。');
        }
      })
    });
  },
  ValidateImageStaff: function () {
    const inputs = $('.img-required');
    const displaySite = $('#account_display_site_3').is(':checked');
    let names = [];
    for(var i=0; i < inputs.length; i++){
      if($(inputs[i]).data('image-value').length == 0 && $(inputs[i]).val().trim().length == 0 && displaySite){
        names.push($(inputs[i]).data('validate-name'))
      }
    }
    return names
  },

  checkValidate: function () {
    var $form = $('#staff_form')
    var parameters = new FormData($form[0]);
    parameters.set('_method', 'POST');

    $.ajax({
      url: '/kanri/staff/ajax_check_validate',
      data: parameters,
      method: 'POST',
      processData: false, // FormData
      contentType: false, // FormData
      success: function(response) {
        if (response.valid) {
          $("#staff_form").submit();
        } else {
          $('.staff-error-message .ajax-message').html(response.errors);
          Staff.showErrorMsg('ajax');
        }
      },
      error: function () {
        toastr.error("エラーが発生しました。");
      },
      complete: function () {
        $('.modal-loading').modal('hide');
      }
    })
  },

  showErrorMsg: function (from) {
    toastr.remove();
    if(from == 'js') {
      $('.staff-error-message .ajax-message').addClass('hidden');
      $('.staff-error-message .js-message').removeClass('hidden');
    } else {
      $('.staff-error-message .js-message').addClass('hidden');
      $('.staff-error-message .ajax-message').removeClass('hidden');
    }
    
    const erros = $('.staff-error-message').html();
    toastr.error(erros);
  },

  sortDepartment: function () {
    let checkboxStaffId = (index, value) => `<input type="hidden" name="account[staff_departments_attributes][${index}][department_id]" value="${value}" class="staff-department-staff-id" data-index="${index}" data-value="${value}">`;
    let checkboxOrderNo = (index, value) => `<input type="hidden" name="account[staff_departments_attributes][${index}][order_no]" value="${index}" class="staff-department-order-no" data-index="${index}" data-value="${value}">`;

    $('#staff_form').on('click', '.staff-department-checkbox', function (event) {
      let maxOrderNo = 0
      $('.staff-department-order-no').each(function () {
        if ($(this).val() > maxOrderNo) {
          maxOrderNo = parseInt($(this).val())
        }
      })

      if ($(this).is(':checked')) {
        let checkDestroySelector = $(this).closest('.check-group').find(`.staff-department-destroy[data-department-id="${$(this).val()}"]`)

        if (checkDestroySelector.val() === 'true') {
          checkDestroySelector.val(false)
          $(this).closest('.check-group').find(`.staff-department-order-no[data-department-id="${$(this).val()}"]`).val(maxOrderNo + 1)
        } else {
          $(this).closest('.check-group').append(checkboxStaffId(maxOrderNo + 1, $(this).val()))
          $(this).closest('.check-group').append(checkboxOrderNo(maxOrderNo + 1, $(this).val()))
        }
      } else {
        $(this).closest('.check-group').find(`input[data-value="${$(this).val()}"]`).remove()
        $(this).closest('.check-group').find(`.staff-department-destroy[data-department-id="${$(this).val()}"]`).val(true)
      }
    })
  },

  toggleBukkenManager: function() {
    let role_field = $('#account_mask_role');
    handleBukkenManagerRow(role_field.val())

    role_field.bind('change', function (e) {
      handleBukkenManagerRow(role_field.val())
    })
  },
}

function handleBukkenManagerRow(role) {
  let not_bukken_managers = ['oita_rental', 'partner__admin', 'partner__editor'];
  let bukken_manager_row = $('#bukkenManagerRow');

  if (not_bukken_managers.includes(role)) {
    bukken_manager_row.hide();
  } else {
    bukken_manager_row.show();
  }
}
