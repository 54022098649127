var News = {
  init: function() {
    News.changeLimit();
    News.sort();
    News.validate();
  },

  changeLimit: function () {
    $('#news_per').bind('change', function () {
      News.searchNews();
    })
  },

  sort: function () {
    $('.news-list').on('click', '.news-sort', function () {
      const order = $(this).data('order');
      const sort_by = $(this).data('sort_by');
      $('#news_search_form input#sort_news').val(`${sort_by} ${order}`);
      News.searchNews();
    })
  },

  searchNews: function () {
    $('form#news_search_form input#news_search_btn').trigger('click');
  },

  validate: function() {
    $('.new_betsudai_news, .edit_betsudai_news').on('ajax:success', function (news) {
      const data = news.detail[0]
      let toastErrors = data.errors;

      // clear old encode errors
      $("p.js-error-encode").remove();
      const encode_errors = data.encode_errors;
      if (encode_errors) {
        toastErrors.push("※ 黄色のハイライト文字が文字化けになっているので、再度入力してください。");

        // iterator encode errors for each input
        encode_errors.forEach(function(error) {
          // get input has encoding error
          let inputName = Object.keys(error)[0];
          let newsInput = $("#betsudai_news_" + inputName);
          let inputChars = newsInput.val().split('');
          // mark incorrect text
          error[inputName].forEach(function(encodeIndex) {
            inputChars[encodeIndex] = "<mark>" + inputChars[encodeIndex] + "</mark>";
          });

          // encode error element
          let errorElement = "<p class='color-chestnut-rose mt-2 js-error-encode'>" + inputChars.join('') + "</p>";
          newsInput.closest("td").append(errorElement);
        });
      }

      if (toastErrors.length > 0) {
        toastr.error(toastErrors.join("<br>"))
      }
    })
  },
}

export default News;
