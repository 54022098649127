import Sortable from "./sortable";
import { handleAjaxError } from "../handle_error";

var FloorPlan = {
  init: function() {
    if (!$('.floor-plan-page').length) {
      return
    }

    FloorPlan.handleUploadImages();
    FloorPlan.handleUploadGellaryImages();
    FloorPlan.triggerUploadImgButton();
    FloorPlan.handleChangePic();
    FloorPlan.handleSelectTags();
    FloorPlan.handleChangeInputTag();
    FloorPlan.handleResetTag();
    FloorPlan.handleClickAddTags();
    FloorPlan.handleAddTag();
    FloorPlan.handleUpdateTag();
    FloorPlan.handleDeleteTag();
    FloorPlan.handleDeleteUsedTag();
    FloorPlan.handleUpdateStatus();
    FloorPlan.updateStatus();
  },

  handleUploadImages: function() {
    $('#btn-floor-plan-image').bind('click', function () {
      $('#floor-plan-image-modal').modal('show');
    })

    FloorPlan.initDropZone();
    FloorPlan.deleteImage();
    FloorPlan.showImages();
  },

  handleUploadGellaryImages: function() {
    $('#btn-floor-plan-gallery-image').bind('click', function () {
      $('#floor-plan-gallery-image-modal').modal('show');
    })

    let preloaded = [];

    $('.floor-plan-gallery-images-modal .images-hidden').each(function () {
      let parent = $(this).closest('.wrap-image-prev');

      if (parent.find('.image-destroy-flag').val() != 'true') {
        preloaded.push({
          id: $(this).attr('data-index'),
          src: $(this).val()
        })
      };
    })

    $('.floor-plan-gallery-images').imageUploader({
      label: '一括登録する画像をここにドラック & ドロップしてください',
      modelInputName: 'betsudai_home_floor_plan',
      attrInputName: 'gallery_images_attributes',
      previewClassName: '.floor-plan-gallery-images-modal .bh-image-list-preview',
      findListImageTagsCallback: function () { 
        return [];
      },
      preloaded: preloaded,
      imageTagsClassName: '',
      extensions: ['.jpg', '.jpeg', '.png'],
      mimes: ['image/jpeg', 'image/png'],
      showImagesCallback: FloorPlan.showGalleryImages,
      setElementSelect2Callback: function () {},
      deleteImageCallback: FloorPlan.deleteGalleryImage,
    });

    FloorPlan.deleteGalleryImage();
    FloorPlan.showGalleryImages();
  },

  deleteGalleryImage: function() {
    $('.floor-plan-page .floor-plan-gallery-images-modal .image-prev .delete-image').click(function (e) {
      e.preventDefault();

      let indexOfItem = $(this).attr('data_index');
      let parentItem = $(this).closest('.bh-image-item');
      let inputDestroyItem = `<input type="hidden" value="true" name="betsudai_home_floor_plan[gallery_images_attributes][${indexOfItem}][_destroy]">`;

      parentItem.parent().append(inputDestroyItem);
      $(this).closest('.bh-image-item').remove();
      $(`.floor-plan-gallery-image .uploaded-image[data-index="${indexOfItem}"]`).remove();
      $(`.floor-plan-gallery-images-modal .uploaded-image[data-index="${indexOfItem}"]`).remove();


      if (!$('.floor-plan-gallery-images-modal .uploaded-image').length) {
        $('.floor-plan-gallery-images-modal .image-uploader').removeClass('has-files');
      }

      FloorPlan.showGalleryImages();
    })
  },

  showGalleryImages: function() {
    $('.floor-plan-gallery-image').empty();
    $('.floor-plan-gallery-images-modal .image-uploader .uploaded').children().clone().appendTo('.floor-plan-gallery-image');
  },

  initDropZone: function () {
    let preloaded = FloorPlan.collectImageUrls();

    $('.floor-plan-images').imageUploader({
      label: '一括登録する画像をここにドラック & ドロップしてください',
      modelInputName: 'betsudai_home_floor_plan',
      attrInputName: 'images_attributes',
      imageTagsClassName: 'floor-plan-image-tags',
      previewClassName: '.floor-plan-images-modal .bh-image-list-preview',
      findListImageTagsCallback: function () {
        return [];
      },
      preloaded: preloaded,
      extensions: ['.jpg', '.jpeg', '.png'],
      mimes: ['image/jpeg', 'image/png'],
      showImagesCallback: FloorPlan.showImages,
      setElementSelect2Callback: function () {},
      deleteImageCallback: FloorPlan.deleteImage,
    })
  },

  appendButtonAddNewTag: function () {
    $('.select2-search__field').each(function () {
      if ($(this).val().length !== 0) {
        let btn = `<button type="button" class="btn-dynamic-add-tag">
                       <i class="fa fa-plus-circle"></i>
                    </button>`;
        $(this).parent().append(btn);
      }
    })
  },

  collectListTags: function () {
    let collectionTag = JSON.parse($('.floor-plan-image-tag-collection').val());
    let tags = [];

    $.each(collectionTag, function(_key, value) {
      tags.push({
        id: value[0],
        value: value[1],
      })
    })

    return tags;
  },

  collectImageUrls: function () {
    let result = [];

    $('.floor-plan-images-modal .images-hidden').each(function () {
      let parent = $(this).closest('.wrap-image-prev');

      if (parent.find('.image-destroy-flag').val() != 'true') {
        result.push({
          id: $(this).attr('data-index'),
          src: $(this).val()
        })
      }
    })

    return result;
  },

  deleteImage: function () {
    $('.floor-plan-page .floor-plan-images-modal .image-prev .delete-image').click(function (e) {
      e.preventDefault();

      let indexOfItem = $(this).attr('data_index');
      let parentItem = $(this).closest('.bh-image-item');
      let inputDestroyItem = `<input type="hidden" value="true" name="betsudai_home_floor_plan[images_attributes][${indexOfItem}][_destroy]">`;

      parentItem.parent().append(inputDestroyItem);
      $(this).closest('.bh-image-item').remove();
      $(`.floor-plan-image .uploaded-image[data-index="${indexOfItem}"]`).remove();
      $(`.floor-plan-images-modal .uploaded-image[data-index="${indexOfItem}"]`).remove();


      if (!$('.floor-plan-images-modal .uploaded-image').length) {
        $('.floor-plan-images-modal .image-uploader').removeClass('has-files');
      }

      FloorPlan.showImages();
    })
  },

  showImages: function () {
    $('.floor-plan-image').empty();
    $('.floor-plan-images-modal .image-uploader .uploaded').children().clone().appendTo('.floor-plan-image');
  },

  triggerUploadImgButton: function () {
    $('#upload-featured-img-button').click(function() {
      $('#featured-img-input-field').trigger('click');
    });
  },

  handleChangePic: function () {
    $('#featured-img-input-field').change(function(event) {
      if (event.target.files.length > 0) {
        let dataId = event.target.getAttribute('data-id');
        let output = document.getElementById(dataId);
        output.src = URL.createObjectURL(event.target.files[0]);
        output.style.display = 'block';
      }
    });
  },

  handleSelectTags: function () {
    $('.floor-plan-page').on('click', '.tags-value', function(event) {
      event.preventDefault();

      let checkBox = $(this).find('input');
      let label = $(this).find('span.title');

      checkBox.attr('checked', !checkBox.is(':checked'));
      label.toggleClass('selected');
    });
  },

  handleResetTag: function () {
    $('.floor-plan-page').on('click', '.btn-reset-tag', function(event) {
      event.preventDefault();

      let defaultValue = $(this).data('default-value');
      $(this).closest('.wrapper-tag').find('input.input-tag').val(defaultValue);
      $(this).closest('.actions-group').find('button').attr('disabled', true);
    });
  },

  handleChangeInputTag: function () {
    $('.floor-plan-page').on('keyup', 'input.input-tag', function(event) {
      event.preventDefault();

      $(this).closest('.wrapper-tag').find('.actions-group button').attr('disabled', false);
    });
  },

  handleClickAddTags: function () {
    $('button.btn-new-tags').click(function() {

      let groupId = $(this).data('group-id');
      $('.category-wrapper').hide();
      $(`.${groupId}`).show();
    });
  },

  handleAddTag: function () {
    $('button.btn-new-floor-plan-tag').click(function(event) {
      event.preventDefault();

      let input = $(this).closest('.add-new-wrapper').find('input.input-new-tag');
      let name = input.val().trim();
      let categoryId = input.data('category-id');

      if (name.length > 0 ) {
        $.ajax({
          url: '/kanri/floor_plans/tags',
          data: {
            'tag[name]': name,
            'tag[floor_plan_tag_category_id]': categoryId,
          },
          method: 'POST',
          dataType: 'json',
          success: function(response) {
            toastr.success('タグを追加しました');
            $('#tag-modal').modal('hide');
            $('.input-new-tag').val(null);
            FloorPlan.appendListTag(response.tag);
            FloorPlan.appendPopupTag(response.tag);
          },
          error: function (request, status, error) {
            if (request.status === 422) {
              return toastr.error('タグ名が既に存在します');
            }
            handleAjaxError(request, status, error);
          }
        });
      } else {
        toastr.error('タグ名を入力してください');
      }
    });
  },

  handleUpdateTag: function () {
    $('.floor-plan-page').on('click', '.btn-update-floor-plan-tag', function(event) {
      event.preventDefault();

      let input = $(this).closest('.wrapper-tag').find('input.input-tag');
      let name = input.val().trim();
      let categoryId = $(this).closest('.tag-list').data('category-id');
      let buttons = $(this).closest('.actions-group').find('button');
      let tagId = $(this).closest('.wrapper-tag').data('tag-id');

      if (name.length > 0 ) {
        $.ajax({
          url: '/kanri/floor_plans/tags',
          data: {
            'id': tagId,
            'tag[name]': name,
            'tag[tag_type]': categoryId,
          },
          method: 'PUT',
          success: function(response) {
            toastr.success('更新されました');
            $('#tag-modal').modal('hide');
            buttons.attr('disabled', true);
            FloorPlan.updateTagName(response.tag);
          },
          error: function (request, status, error) {
            if (request.status === 422) {
              return toastr.error('タグ名が既に存在します');
            }
            handleAjaxError(request, status, error);
          },
        })
      } else {
        toastr.error('タグ名を入力してください')
      }
    });
  },

  appendListTag: function(tag) {
    let html = `<li class="item tag-${tag.id}">
                  <label class="tags-value">
                    <input class="input-checkbox input-tag-ids" type="checkbox" value="${tag.id}" name="betsudai_home_floor_plan[tag_ids][]" id="betsudai_home_floor_plan_tag_ids_${tag.id}">
                    <span class="title">${tag.name}</span>
                  </label>
                </li>`;
    $(html).appendTo(`.tags-group.category-${tag.floor_plan_tag_category_id} .tags-select`);
  },

  appendPopupTag: function(tag) {
    let html = `<div class="form-group row wrapper-tag mb-4 wrapper-tag-${tag.id}" data-tag-id="${tag.id}">
                  <div class="col-9">
                    <input class="input-text w-98 input-tag" placeholder="タグ名を入力" value="${tag.name}">
                  </div>
                  <div class="col-3 actions-group">
                    <div class="d-flex justify-content-end">
                      <button class="btn btn-success btn-update-floor-plan-tag" disabled="true">更新</button>
                      <button class="btn btn-secondary mx-3 btn-reset-tag" data-default-value="${tag.name}" disabled="true">リセット</button>
                      <div class="d-flex justify-content-end align-items-center rounded-circle btn-pointer btn-delete-floor-plan-tag">
                        <i class="fa fa-trash text-danger mx-2"></i>
                      </div>
                    </div>
                  </div>
                </div>`;
    $(html).appendTo(`.category-wrapper.group-${tag.floor_plan_tag_category_id} .tag-list`);
  },

  updateTagName: function(tag) {
    $(`.tags-group.category-${tag.floor_plan_tag_category_id} li.item.tag-${tag.id} span.title`).html(tag.name);
    $(`.tag-modal .category-wrapper.group-${tag.floor_plan_tag_category_id} .wrapper-tag-${tag.id} .btn-reset-tag`).data('default-value', tag.name);
  },

  handleDeleteTag: function() {
    $('.floor-plan-page').on('click', '.btn-delete-floor-plan-tag', function(event) {
      event.preventDefault();

      let tagId = $(this).closest('.wrapper-tag').data('tag-id');

      $.ajax({
        url: `/kanri/floor_plans/taggings/take_by_tag/${tagId}`,
        method: 'GET',
        success: function(response) {
          if ( response.tagging || $(`.tags-select li.item.tag-${tagId} span.title`).hasClass('selected')) {
            $('.delete-floor-plan-tag-used').data('tag-id', tagId);
            $('#modal-confirm-destroy-tag').modal('show');
          } else {
            FloorPlan.deleteTag(tagId);
          }
        },
        error: function (request, status, error) {
          handleAjaxError(request, status, error);
        },
      });
    });
  },

  deleteTag: function(tagId) {
    $.ajax({
      url: '/kanri/floor_plans/tags',
      method: 'DELETE',
      data: {
        'id': tagId,
      },
      success: function(res) {
        toastr.success('削除されました');
        $('#tag-modal').modal('hide');
        $(`#tag-modal .wrapper-tag-${tagId}`).remove();
        $(`.tags-select li.item.tag-${tagId}`).remove();
      },
      error: function (request, status, error) {
        handleAjaxError(request, status, error);
      }
    });
  },

  handleDeleteUsedTag: function() {
    $('.floor-plan-page').on('click', '.delete-floor-plan-tag-used', function(event) {
      event.preventDefault();

      let tagId = $(this).data('tag-id');

      FloorPlan.deleteTag(tagId);
      $('#modal-confirm-destroy-tag').modal('hide');
    });
  },

  handleUpdateStatus: function() {
    $('.select-floor-plan-status').change(function(event) {
      event.preventDefault();

      $('#modal_change_status').data('id', $(this).data('id'));
      $('#modal_change_status').data('status', $(this).data('value'));
      $('#modal_change_status').modal('show');
    });

    $('#modal_change_status').on("hidden.bs.modal", function (event) {
      event.preventDefault();

      let previousValue = $(this).data('status');
      let id = $(this).data('id');

      $(`select.floor-plan-${id}`).val(previousValue);
    });
  },

  updateStatus: function() {
    $('a.btn-confirm-status-change').click(function(event) {
      event.preventDefault();

      var id = $('#modal_change_status').data('id');
      
      $.ajax({
        url: `/kanri/floor_plans/${id}/update_status`,
        method: 'PUT',
        data: { 'status': $('.select-floor-plan-status').val() },
        success: function(response) {
          if(response.errors) {
            let previousValue = $('#modal_change_status').data('status');
            
            $(`select.floor-plan-${id}`).val(previousValue);
            toastr.error(response.errors);
          } else {
            $(`select.floor-plan-${id}`).data('status', $(`select.floor-plan-${id}`).val());
            $('#modal_change_status').data('status', $(`select.floor-plan-${id}`).val());
            toastr.success('公開ステータスを更新しました。');
          }
          $('#modal_change_status').modal('hide');
        },
        error: function (request, status, error) {
          handleAjaxError(request, status, error);
        }
      });
    });
  },
}

export default FloorPlan;
