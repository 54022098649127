import moment from 'moment'

export function handleDownloadCSV(request_url, file_name) {
    let xhr = new XMLHttpRequest();
    xhr.open("GET", request_url);
    xhr.responseType = "blob";
    xhr.onload = function () {
        $('.modal-loading').modal('hide');
        saveAs(this.response, file_name + moment().format('-YYYYMMDD-Hmmss') + ".csv");
    };
    xhr.send();
}