import SearchMap from '../../../search_map';

var SearchGoogleMapModal = {
  init: function () {
    SearchGoogleMapModal.initMap();
    SearchGoogleMapModal.start();
    SearchGoogleMapModal.applyMapData();
  },

  currentButtonOpenModal: null,

  initMap: function(){
    if($('#googleMapSearchModal').length > 0){
      if(typeof window.google == 'undefined' || typeof google.maps == 'undefined'){
        var map_url_obj = { key: SearchMap.key, libraries: 'places', region:'JP', language: 'ja', v: '3' }
        var map_url = 'https://maps.googleapis.com/maps/api/js?' + $.param(map_url_obj)
        $.getScript(map_url, function(){
          SearchGoogleMapModal.initMapAutocomplete()
        });
      } else {
        SearchGoogleMapModal.initMapAutocomplete()
      }
    }
  },
  applyMapData: function () {
    $('#event_apply_map_data').on('click', function () {
      const $currentButtonOpenModal = SearchGoogleMapModal.currentButtonOpenModal
      if (!$currentButtonOpenModal) return

      const $parent = $currentButtonOpenModal.closest('.main-contents')
      const $address = $('#event_map_address');
      const $latitude = $('.event-map-lat');
      const $longitude = $('.event-map-lng');

      if ($address.val()) {
        $parent.find('.mapAddress').val($address.val())
      }

      if ($latitude.val()) {
        $parent.find('.latitude').val($latitude.val())
      }

      if ($longitude.val()) {
        $parent.find('.longitude').val($longitude.val())
      }

      $("#googleMapSearchModal").modal('hide')
    });
  },

  initMapAutocomplete: function() {
    let address = $('#event_map_address');
    let latitude = $('.event-map-lat');
    let longitude = $('.event-map-lng');
    let markers = [];

    let lat = $('#googleMapModalLat').val() || 33.233087;
    let lng = $('#googleMapModalLng').val() || 131.606501;
    latitude.val(lat)
    longitude.val(lng)

    if ($('#googleMapModalAddress').val()) {
      address.val($('#googleMapModalAddress').val())
    } else {
      address.val('')
    }

    if (latitude.val().trim().length > 0 && longitude.val().trim().length > 0) {
      lat = parseFloat(latitude.val());
      lng = parseFloat(longitude.val());
    }
    const latLng = { lat: lat, lng: lng }
    const map = new google.maps.Map(document.getElementById("event_google_map"), {
      center: latLng,
      zoom: 13,
      streetViewControl: false,
      mapTypeId: 'roadmap',
    });

    markers.push(
      new google.maps.Marker({
        position: latLng,
        map
      })
    )

    $('#event_find_address').on('click',function() {
      let q = address.val();
      if(q){
        $.ajax({
          url: 'https://maps.googleapis.com/maps/api/geocode/json',
          data: { address: q, key: SearchMap.key },
          method: 'GET',
          success: function(response) {
            var geometry = response.results[0].geometry;
            if(geometry){
              $('.event-map-lat').val(geometry.location.lat)
              $('.event-map-lng').val(geometry.location.lng)
              markers.forEach((marker) => {
                marker.setMap(null);
              });
              let latLng = new google.maps.LatLng(geometry.location.lat, geometry.location.lng)
              markers.push(
                new google.maps.Marker({
                  map,
                  position: latLng
                })
              );
              map.fitBounds(new google.maps.LatLngBounds(geometry.viewport.southwest, geometry.viewport.northeast));
            }
          },
        });
      }
    });

    $("#event_map_address").keydown(function(e){
      // 13 Enter key code
      if(e.which === 13){
        $("#event_find_address").click();
      }
    });

    let geocoder = new google.maps.Geocoder();
    map.addListener("click", (mapsMouseEvent) => {
      markers.forEach((marker) => {
        marker.setMap(null);
      });
      markers = [];
      geocoder.geocode({
        'latLng': mapsMouseEvent.latLng
      }, function(results, status) {
        if (status == google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            var addr = results[0].formatted_address
            addr = addr.replace(/[^\p{L}\p{N}\p{P}\p{Z}]/gu, '-').replace('日本、-', '');
            address.val(addr);
            latitude.val(mapsMouseEvent.latLng.lat());
            longitude.val(mapsMouseEvent.latLng.lng());
          }
        }
      })

      markers.push(
        new google.maps.Marker({
          map,
          position: mapsMouseEvent.latLng
        })
      )
    });
  },

  start: function () {
    $(() => {
      $(document).on('click', '.openSearchGoogleMapModal', function() {
        const $openBtn = $(this)
        SearchGoogleMapModal.currentButtonOpenModal = $openBtn
        const $parent = $openBtn.closest('.main-contents')
        const address = $parent.find('.mapAddress').val()
        const lat = $parent.find('.latitude').val()
        const lng = $parent.find('.longitude').val()
        $('#googleMapModalAddress').val(address)
        $('#googleMapModalLat').val(lat)
        $('#googleMapModalLng').val(lng)
        SearchGoogleMapModal.initMapAutocomplete()
        $("#googleMapSearchModal").modal('show')
      })
    });
  }
}

export default SearchGoogleMapModal;
