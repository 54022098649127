var Contact = {
  init: function() {
    Contact.sort();
    Contact.filter();
  },

  sort: function () {
    $('.contacts-table').on('click', '.contact-sort', function () {
      const order = $(this).data('order');
      const sort_by = $(this).data('sort_by');
      $('#contact_search_form input#sort_contact').val(`${sort_by} ${order}`);
      Contact.searchContact();
    })
  },

  filter: function () {
    $('#contact_search_form #q_status_eq').bind('change', function () {
      Contact.searchContact();
    })
  },

  searchContact: function () {
    $('form#contact_search_form input#contact_search_btn').trigger('click');
  },
}

export default Contact;