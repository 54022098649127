import {handleAjaxError} from "../handle_error";

var ArticleTag = {
  init: function() {
    ArticleTag.sortArticleCount();
  },

  searchArticle: function (data) {
    $.ajax({
      url: '/kanri/articles/tags.js',
      data: data,
      method: 'get',
      success: function (response) {
      },
    });
  },

  sortArticleCount: function () {
    $('.article-tag-table').on('click', '.article-tag-sort-count', function (e) {
      const order = $(this).data('order-by-article-count')
      const data = { 'order_by_article_count': order }
      ArticleTag.searchArticle(data)
    })
  }

}

export default ArticleTag;