var Buyers = {
  init: function() {
    Buyers.sort();
    Buyers.filler();
    Buyers.changeLimit();
  },

  filler: function() {
    $('#q_buyers_type_eq').bind('change', function () {
      Buyers.searchBuyers();
    })
  },

  sort: function () {
    $('.buyers-table').on('click', '.buyers-sort', function () {
      const order = $(this).data('order');
      const sort_by = $(this).data('sort_by');
      $('#buyers_search_form input#sort_buyers').val(`${sort_by} ${order}`);
      Buyers.searchBuyers();
    })
  },

  changeLimit: function () {
    $('#buyer_limit').bind('change', function () {
      $('#buyers_search_form input[name=per]').val($(this).val());
      Buyers.searchBuyers();
    })
  },

  searchBuyers: function () {
    $('form#buyers_search_form input#buyers_search_btn').trigger('click');
  },
}

export default Buyers;