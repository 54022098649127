import {handleAjaxError} from "../handle_error";

var Tag = {
  init: function () {
    Tag.resetTag()
    Tag.enableTag()
    Tag.newTag()
    Tag.editTag()
    Tag.validTagBeforeDelete()
    Tag.confirmDeleteTag()
    Tag.updateTagsSelected()
  },

  resetTag: function () {
    $(document).off('click', '.btn-reset-tag');
    $(document).on('click', '.btn-reset-tag', function () {
      const indexTag = $(this).closest('.wrapper-tag').data('index-tag')
      const valTagPrev = $(`.hidden-tag${indexTag}`).val()
      $(`.tag${indexTag}`).val(valTagPrev)
      $(this).closest(`.wrapper-tag${indexTag}`).find('.btn-edit-tag').prop('disabled', true)
      $(this).closest(`.wrapper-tag${indexTag}`).find('.btn-reset-tag').prop('disabled', true)
    })
  },

  enableTag: function () {
    $(document).off('keydown', '.input-tag');
    $(document).on('keydown', '.input-tag', function () {
      const indexTag = $(this).closest('.wrapper-tag').data('index-tag')
      $(`.wrapper-tag${indexTag}`).find('.btn-edit-tag').prop('disabled', false);
      $(`.wrapper-tag${indexTag}`).find('.btn-reset-tag').prop('disabled', false);
    })
  },

  newTag: function () {
    $(document).off('click', '.btn-new-tag');
    $(document).on('click', '.btn-new-tag', function () {
      const valTag = $('.input-new-tag').val()
      const typeTag = $('#tag_type').val()
      if (valTag.length > 0) {
        $.ajax({
          url: '/kanri/tags',
          data: {
            'tag[name]': valTag,
            'tag[tag_type]': typeTag,
          },
          method: 'POST',
          success: function(res) {
            toastr.success('タグを追加しました')
            $('#tag-modal').modal('hide')
            $('.input-new-tag').val(null)
            Tag.updateTagsSelected()
            Tag.showSelectedTags()
          },
          error: function (request, status, error) {
            if (request.status === 422) {
              return toastr.error('タグ名が既に存在します')
            }
            handleAjaxError(request, status, error);
          }
        })
      } else {
        toastr.error('タグ名を入力してください')
      }
    })
  },

  editTag: function () {
    $(document).off('click', '.btn-edit-tag');
    $(document).on('click', '.btn-edit-tag', function () {
      const indexTag = $(this).closest('.wrapper-tag').data('index-tag')
      const idTag = $(`.tag${indexTag}`).data('tag-id')
      const valTag = $(`.tag${indexTag}`).val()
      const typeTag = $('#tag_type').val()

      if (valTag.length > 0 ) {
        $.ajax({
          url: `/kanri/tags/${idTag}`,
          data: {
            'tag[name]': valTag,
            'tag[tag_type]': typeTag,
          },
          method: 'PUT',
          success: function(res) {
            toastr.success('更新されました')
            $('#tag-modal').modal('hide')
            Tag.updateTagsSelected()
            Tag.showSelectedTags()
          },
          error: function (request, status, error) {
            if (request.status === 422) {
              return toastr.error('タグ名が既に存在します')
            }
            handleAjaxError(request, status, error);
          },
        })
      } else {
        toastr.error('タグ名を入力してください')
      }
    })
  },
  validTagBeforeDelete: function () {
    $(document).off('click', '.btn-delete-tag');
    $(document).on('click', '.btn-delete-tag', function () {
      const indexTag = $(this).closest('.wrapper-tag').data('index-tag')
      const idTag = $(`.tag${indexTag}`).data('tag-id')
      $.ajax({
        url: `/kanri/tags/${idTag}/check_tag_used`,
        method: 'GET',
        success: function(response) {
          if ((response.result.length > 0) || Tag.checkTagUsed(idTag)) {
            $('.delete-tag-used').data('tag-id', idTag)
            $('#modal-confirm-destroy-tag').modal('show')
          } else {
            Tag.deleteTag(idTag)
          }
        },
        error: function (request, status, error) {
          handleAjaxError(request, status, error);
        },
      })
    })
  },
  confirmDeleteTag: function () {
    $(document).off('click', '.delete-tag-used');
    $(document).on('click', '.delete-tag-used', function () {
      const idTag = $(this).data('tag-id')
      Tag.deleteTag(idTag)
      $('#modal-confirm-destroy-tag').modal('hide')
    })
  },

  deleteTag: function (idTag) {
    const typeTag = $('#tag_type').val()
    $.ajax({
      url: `/kanri/tags/${idTag}`,
      method: 'DELETE',
      data: {
        'tag[tag_type]': typeTag,
      },
      success: function(res) {
        toastr.success('削除されました')
        $('#tag-modal').modal('hide')
        Tag.updateTagsSelected()
        Tag.showSelectedTags()
      },
      error: function (request, status, error) {
        handleAjaxError(request, status, error);
      }
    })
  },
  showSelectedTags: function () {
    let selectedTagsString = $('#selected_tags').val() || ""
    let selectedTags = selectedTagsString.split(" ")
    selectedTags.forEach(function (tagId) {
      let tagSelector = $(`.input-bh-tag-ids[value="${tagId}"]`)
      if (tagSelector.length !== 0 && !tagSelector.is(':checked')) {
        tagSelector.prop('checked', true)
      }
    })
  },
  updateTagsSelected: function () {
    $('.input-bh-tag-ids').click(function () {
      let selectedTagsString = $('#selected_tags').val() || ""
      let selectedTags = selectedTagsString.split(" ")

      if ($(this).is(':checked')) {
        selectedTags.push($(this).val())
      } else {
        selectedTags = selectedTags.filter(tagId => tagId != $(this).val())
      }

      $('#selected_tags').val(selectedTags.join(" "))
    })
  },
  checkTagUsed: function (tagId) {
    let selectedTagsString = $('#selected_tags').val() || ""
    let selectedTags = selectedTagsString.split(" ")

    console.log(selectedTags)
    console.log(selectedTags.includes(tagId.toString()))

    return selectedTags.includes(tagId.toString())
  }
}

export default Tag;
