jQuery.extend(jQuery.validator.messages, {
    required: "必須項目です",
    maxlength: jQuery.format("{0} 文字以下を入力してください"),
    minlength: jQuery.format("{0} 文字以上を入力してください"),
    rangelength: jQuery.format("{0} 文字以上 {1} 文字以下で入力してください"),
    email: "メールアドレスを入力してください",
    url: "URLを入力してください",
    dateISO: "日付を入力してください",
    number: "有効な数字を入力してください",
    digits: "0-9までを入力してください",
    equalTo: "同じ値を入力してください",
    range: jQuery.format(" {0} から {1} までの値を入力してください"),
    max: jQuery.format("{0} 以下の値を入力してください"),
    min: jQuery.format("{0} 以上の値を入力してください"),
    creditcard: "クレジットカード番号を入力してください"
  }), jQuery.validator.addMethod("kana", function(t, a) {
    return this.optional(a) || /^([ァ-ヶーぁ-ん]+)$/.test(t)
  }, "全角ひらがな･カタカナを入力してください"), jQuery.validator.addMethod("hiragana", function(t, a) {
    return this.optional(a) || /^([ぁ-ん]+)$/.test(t)
  }, "全角ひらがなを入力してください"), jQuery.validator.addMethod("katakana", function(t, a) {
    return this.optional(a) || /^([ァ-ヶー]+)$/.test(t)
  }, "全角カタカナを入力してください"), jQuery.validator.addMethod("hankana", function(t, a) {
    return this.optional(a) || /^([ｧ-ﾝﾞﾟ]+)$/.test(t)
  }, "半角カタカナを入力してください"), jQuery.validator.addMethod("alphabet", function(t, a) {
    return this.optional(a) || /^([a-zA-z¥s]+)$/.test(t)
  }, "半角英字を入力してください"), jQuery.validator.addMethod("alphanum", function(t, a) {
    return this.optional(a) || /^([a-zA-Z0-9]+)$/.test(t)
  }, "半角英数字を入力してください"), jQuery.validator.addMethod("postnum", function(t, a) {
    return this.optional(a) || /^\d{3}\-\d{4}$/.test(t)
  }, "郵便番号を入力してください（例:010-2345）"), jQuery.validator.addMethod("mobilenum", function(t, a) {
    return this.optional(a) || /^0¥d0-¥d{4}-¥d{4}$/.test(t)
  }, "携帯番号を入力してください（例:010-2345-6789）"), jQuery.validator.addMethod("telnum", function(t, a) {
    return this.optional(a) || /^[0-9-]{12}$/.test(t)
  }, "電話番号を入力してください（例:012-345-6789）"), jQuery.validator.addMethod("telnum_2", function(t, a) {
    return this.optional(a) || /^[0-9\-]+$/.test(t)
  }, "電話番号を入力してください（例:012-345-6789・0123456789）");