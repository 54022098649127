var Catalog = {
  init: function () {
    Catalog.start()
    Catalog.triggerUploadImgButton();
    Catalog.handleChangePic();
  },
  triggerUploadImgButton: function () {
    $('#upload-img-button').click(function() {
      $('#img-input-field').trigger('click');
    });
  },
  start: function() {
    let catalogType = $('.catalog-type-radio-button:checked').val()
    Catalog.handleChangeCatalogType(catalogType)
    Catalog.handleChangeSeries()

    $('.catalog-type-radio-button').on('change', function(e) {
      Catalog.handleChangeCatalogType(e.target.value)
    })

    $('#series').on('click', function() {
      Catalog.handleChangeSeries()
    })
  },
  handleChangePic: function () {
    $('#img-input-field').change(function(event) {
      if (event.target.files.length > 0) {
        let dataId = event.target.getAttribute('data-id');
        let output = document.getElementById(dataId);
        output.src = URL.createObjectURL(event.target.files[0]);
        output.style.display = 'block';
      }
    });
  },
  handleChangeCatalogType: function (catalogType) {
    if (catalogType == 'product_catalog') {
      $('.check-box-series').removeClass('d-none')
    } else if (catalogType == 'common_catalog') {
      $('.brand-wrapper').addClass('d-none')
      $('#catalog-form .brand-checkbox').each(function (e) {
        $(this).prop('checked', false)
      });
      $('.check-box-series').addClass('d-none')
      $('#series').prop('checked', false)
      $('#catalog-series').val(false)
    }
    Catalog.handleChangeSeries();
  },
  handleChangeSeries: function () {
    let catalogType = $('.catalog-type-radio-button:checked').val()
    let series = $("#series").is(':checked')
    if (catalogType == 'product_catalog' && series) {
      $('.website-url').addClass('required')
      $('.brand-wrapper').removeClass('d-none')
      $('#catalog-series').val(true)
    } else {
      $('.website-url').removeClass('required')
      $('.brand-wrapper').addClass('d-none')
      $('#catalog-form .brand-checkbox').each(function (e) {
        $(this).prop('checked', false)
      });
      $('#catalog-series').val(false)
    }
  },
}

export default Catalog;
