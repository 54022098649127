import kanjidate from 'kanjidate';
import { handleAjaxError } from '../handle_error';
import { isAnyInputEmpty } from '../validate-input';

var RentalApart = {
  init: function() {
    RentalApart.handleOpenConfirmPopup();
    RentalApart.save();
    RentalApart.convertToGengou();
    RentalApart.initAutoCalculatePrice();
    RentalApart.submitFormAparment();
    if( $('#rental-container').length )
    {
      RentalApart.handleConditionOptions();
    }
  },

  submitFormAparment: function () {
    $('.submit-apart').on('click', function () {
      if (RentalApart.isValid()) {
        $('#comfirmArea').modal('show')
      } else {
        const erros = $('.apartment-error-message').html()
        toastr.error(erros)
      }
    })
  },

  save: function(){
    $('.save-apartment').bind('click', function(e){
      $(this).prop('disabled', true);
      var $form = $('.apartment-form')
      //var parameters = $form.serialize();
      var parameters = new FormData($form[0]);
      var id = $form.find('.apartment-id').val();
      var link = $form.attr('action')
      var method = 'POST'
      if(id){
        link = `${link}/${id}`
        method = 'PUT'
      }
      $.ajax({
        url: link,
        data: parameters,
        method: method,
        processData: false, // FormData
        contentType: false, // FormData
        success: function(response) {
          if(response.result){
            window.location.href = response.url;
          }
          $(this).prop('disabled', false);
        },
        error: function(request, status, error){
          handleAjaxError(request, status, error);
          $(this).prop('disabled', false);
        }
      });
    });
  },
  isAddressValid: () => {
    return !isAnyInputEmpty(['apartment_address', 'apartment_latitude', 'apartment_longitude']);
  },
  isExpectedDateValid: () => {
    // 即時の場合は他項目の入力の必要はなし(apartment_enter_expected_type = 1)
    const isExpectedTypeImmediately = $("#apartment_enter_expected_type option:selected").text() === '即時';
    const isExpectedTypeCorrect = !isAnyInputEmpty(['apartment_enter_expected_date'])
      && !isAnyInputEmpty(['apartment_enter_expected_type'], '0');
    const isExpectedSpanCorrect =
      !isAnyInputEmpty(['apartment_enter_expected_year', 'apartment_enter_expected_month'])
        && !isAnyInputEmpty(['apartment_enter_expected_span'], '0');

    return isExpectedTypeImmediately || isExpectedTypeCorrect || isExpectedSpanCorrect;
  },
  isTransportationValid: () => {
    return (!isAnyInputEmpty(['apartment_access_point', 'apartment_access_required']) &&
      !isAnyInputEmpty(['apartment_access', 'apartment_transportation'], '0')) || RentalApart.isNotTransportationValidTarget()
  },

  isBranchOfficeValid: () => {
    return !isAnyInputEmpty(['apartment_branch_office_id']);
  },

  isApartmentFee: () => {
    return !isAnyInputEmpty(['apartment_fee', 'apartment_fee_price']);
  },
  isApartmentDeposit: () => {
    return !isAnyInputEmpty(['apartment_deposit', 'apartment_deposit_price']);
  },
  isValid: function(){
    const inputs = $('.input-required');
    var rs = true
    var names = [];
    for(var i=0; i < inputs.length; i++){
      if ($(inputs[i]).val().trim().length == 0
          || ($(inputs[i]).val().trim() == '0' && $(inputs[i]).data('only-nonzero'))) {

        rs = false;
        names.push($(inputs[i]).data('validate-name'))
      }
    }

    if (!RentalApart.isAddressValid()) {
      rs = false;
      names.push('物件所在地');
    }

    if (!RentalApart.isExpectedDateValid()) {
      rs = false;
      names.push('入居可能予定日');
    }

    if (!RentalApart.isTransportationValid()) {
      rs = false;
      names.push('交通の利便');
    }

    if (!RentalApart.isApartmentFee()) {
      rs = false;
      names.push('礼金');
    }

    if (!RentalApart.isApartmentDeposit()) {
      rs = false;
      names.push('敷金');
    }

    if (!RentalApart.isBranchOfficeValid()) {
      rs = false;
      names.push('物件所持店舗');
    }

    if(rs == false){
      names = names.filter(function(item, i, ar){ return ar.indexOf(item) === i; })
      $('.apartment-error-message span.fields').html(names.join('、'));
    }
    return rs;
  },

  isNotTransportationValidTarget: () => {
    const apartmentType = $("#apartment_apart_type option:selected").text(); // TODO: 他のメソッドでも使えるようにスコープが大きいところへ移動させる
    return apartmentType == '貸駐車場' || apartmentType == '貸看板用地登録' // 貸駐車場と貸看板用地は物件種目をみて必須処理を無効にする TODO: メソッド化
  },

  handleOpenConfirmPopup: function(){
    $('#comfirmArea').on('shown.bs.modal', function (e) {
      $('.apartment-error-message').hide();
    })
  },

  convertToGengou: function(){
    var greorianYear = parseInt($('.gregorian-year').val());
    if(greorianYear > 0){
      var gengou = kanjidate.toGengou(greorianYear, 1, 1);
      $('.gengou-text').val(gengou.gengou);
      $('.gengou-year').val(gengou.nen);
    }
  },

  initAutoCalculatePrice: function () {
    let apartment_price = $("input[id='apartment_price']");
    let deposit = $("input[id='apartment_deposit']");
    let deposit_price = $("input[id='apartment_deposit_price']");
    let fee = $("input[id='apartment_fee']");
    let fee_price = $("input[id='apartment_fee_price']");
    let shikibiki = $("input[id='apartment_shikibiki']");
    let shikibiki_price = $("input[id='apartment_shikibiki_price']");

    let deposit_price_label = $("input[id='deposit_price']");
    let fee_price_label = $("input[id='fee_price']");
    let shikibiki_price_label = $("input[id='shikibiki_price']");

    $(apartment_price).bind('change', function () {
      let apartment_price_value = $(this).val();
      if (!apartment_price_value) {
        deposit_price.val(0);
        deposit_price_label.val(0);
        fee_price.val(0);
        fee_price_label.val(0);
        shikibiki_price.val(0);
        shikibiki_price_label.val(0);
      }

      let price = apartment_price_value * deposit.val();
      deposit_price.val(price);
      deposit_price_label.val(price);

      price = apartment_price_value * fee.val();
      fee_price.val(price);
      fee_price_label.val(price);

      price = apartment_price_value * shikibiki.val();
      shikibiki_price.val(price);
      shikibiki_price_label.val(price);
    });

    $(deposit).bind('change', function () {
      let deposit_value = $(this).val();
      if (!deposit_value) {
        deposit_price.val(0);
        deposit_price_label.val(0);
      }

      let price = deposit_value * apartment_price.val();
      deposit_price.val(price);
      deposit_price_label.val(price);
    });

    $(fee).bind('change', function () {
      let fee_value = $(this).val();
      if (!fee_value) {
        fee_price.val(0);
        fee_price_label.val(0);
      }

      let price = fee_value * apartment_price.val();
      fee_price.val(price);
      fee_price_label.val(price);
    });

    $(shikibiki).bind('change', function () {
      let shikibiki_value = $(this).val();
      if (!shikibiki_value) {
        shikibiki_price.val(0);
        shikibiki_price_label.val(0);
      }

      let price = shikibiki_value * apartment_price.val();
      shikibiki_price.val(price);
      shikibiki_price_label.val(price);
    });

  },
  
  handleConditionOptions: function() {
    $('#apart_use').bind('change', function() {
      let apartmentType = $(this).val();
      let conditionOptionElement = $('#condition');
      let parkingOptions = { '0': '空き予定', '1': '空き' };
      let otherOptions = { '0': '空き予定', '1': '空家', '2': '未完成', '3': '完成済' };

      conditionOptionElement.html(null);
      conditionOptionElement.append(`<option value>--</option>`);
      if (apartmentType == '3') {
        Object.keys(parkingOptions).forEach(function(key) {
          conditionOptionElement.append(`<option value="${key}">${parkingOptions[key]}</option>`);
        })
      } else {
        Object.keys(otherOptions).forEach(function(key) {
          conditionOptionElement.append(`<option value="${key}">${otherOptions[key]}</option>`);
        })
      }
    });
  }
}

export default RentalApart;
