import {handleAjaxError} from "../handle_error";

var MagazinePickup = {
  init: function () {
    MagazinePickup.start()
    MagazinePickup.toggleBtnNewMagazinePickup()
    MagazinePickup.resetSort()
    MagazinePickup.newMagazinePickup()
    MagazinePickup.deleteMagazinePickup()
    MagazinePickup.updateMagazinePickups()
  },

  start: function () {
    $('.sortable-magazine-pickup').sortable(
      {
        handle: '.sort-icon-pickup',
        cursor: 'move',
        scroll: false
      }
    )
  },

  updateMagazinePickups: function () {
    $(document).on('click', '.btn-update-magazine-pickup', function () {
      const order = $('.sortable-magazine-pickup').sortable(
        'toArray',{ attribute: 'data-magazine-pickup' }
      );
      // handle call api to update index magazine pickup after sort
      $.ajax({
        url: '/kanri/magazine_pickups/update_all',
        data: {
          'order': order
        },
        method: 'POST',
        success: function(res) {
          toastr.success('更新されました')
          $('#magazine-pickup-modal').modal('hide')
        },
        error: function (request, status, error) {
          handleAjaxError(request, status, error);
        }
      })
    })
  },

  toggleBtnNewMagazinePickup: function () {
    $('#magazine_id').on('change', function () {
      $('.btn-new-magazine-pickup').prop('disabled', $(this).val().length == 0)
    })
  },

  resetSort: function () {
    $('#magazine-pickup-modal').on('hidden.bs.modal', function () {
      $(".sortable-magazine-pickup").sortable("cancel")
    })
  },

  newMagazinePickup: function () {
    $(document).on('click', '.btn-new-magazine-pickup', function () {
      const inputVal = $('#magazine_id option:selected').val()
      $.ajax({
        url: '/kanri/magazine_pickups',
        data: {
          'magazine_pickup[magazine_id]': inputVal,
        },
        method: 'POST',
        success: function(res) {
          document.getElementById('magazine_id').selectedIndex = 0
        },
        error: function (request, status, error) {
          handleAjaxError(request, status, error);
        }
      })
    })
  },

  deleteMagazinePickup: function () {
    $(document).on('click', '.btn-delete-magazine-pickup', function () {
      const idMagazinePickup = $(this).data('magazine-pickup-id')
      $.ajax({
        url: `/kanri/magazine_pickups/${idMagazinePickup}`,
        method: 'DELETE',
        success: function(res) {
          toastr.success('削除されました')
        },
        error: function (request, status, error) {
          handleAjaxError(request, status, error);
        },
      })
    })
  }
}

export default MagazinePickup;
