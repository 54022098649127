import moment from 'moment'
import kanjidate from 'kanjidate'
import RentalApart from './pages/rental_apart.js'
import SaleBukken from "./pages/sale_bukken.js";
import './pages/staff.js'
import './pages/banner.js'
import Customer from './pages/customer.js';
import SearchAparment from './pages/search_aparment.js';
import Company from "./pages/company";
import './pages/image_common.js'
import './pages/image_sale.js'
import './pages/image_fukuoka.js'
import  './pages/scenarios'
import News from './pages/news.js'
import MailMagazine from './pages/mail_magazine'
import EventBuilder from './pages/event_builder'
import ConstructionCase from './pages/construction_case'
import SearchGoogleMapModal from './pages/betsudai_homes/events/locations/search_google_map_modal.js'
import EventGeneralInfo from './pages/event_general_info'
import EventLocationInfos from './pages/event_location_infos'
import EventContact from './pages/event_contact'
import MailMagazineSetting from "./pages/mail_magazine_setting";
import Voice from './pages/voice.js'
import SearchBukken from "./pages/search_bukken";
import Stepmail from "./pages/stepmail";
import Buyers from "./pages/buyers";
import SalesResult from "./pages/sales_result";
import User from "./pages/user";
import SearchMap from "./pages/search_map";
import Event from "./pages/event.js";
import Article from "./pages/article.js";
import NewsCorporate from "./pages/news_corporate.js";
import Contact from "./pages/contact.js";
import ArticleTag from "./pages/article_tag";
import Sortable from "./pages/sortable";
import SortableList from "./pages/sortable_list";
import Dashboard from "./pages/dashboard";
import Brand from './pages/brands';
import Catalog from "./pages/catalog";
import EditBlocks from './pages/betsudai_homes/events/locations/edit_blocks';
import CatalogContact from "./pages/catalog_contact";
import Reservation from "./pages/reservation";
import EventGeneratorContact from "./pages/event_generator_contact"
import './libs/jquery.validate.min'
import './libs/jquery.validate.japlugin'
import './libs/default.validate.event'
import Magazine from './pages/magazine'
import MagazinePickup from './pages/magazine_pickup'
import MagazineNisetai from './pages/magazine_nisetai'
import FloorPlan from './pages/floor_plan'
import Tag from './pages/tag'
import ReleaseExpiredBukken from './pages/release_expired_bukken'
import PicturesPositions from './components/pictures_positions';
import Department from './pages/department'

$(document).on('turbolinks:load', function () {
  $.datepicker.regional['ja'] = {
    "closeText": "Done",
    "prevText": "Prev",
    "nextText": "Next",
    "currentText": "Today",
    "monthNames": [
      "01月",
      "02月",
      "03月",
      "04月",
      "05月",
      "06月",
      "07月",
      "08月",
      "09月",
      "10月",
      "11月",
      "12月"
    ],
    "dayNamesMin": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "weekHeader": "Wk",
    "dateFormat": "yy-mm-dd",
    "firstDay": 0,
    "isRTL": false,
    "showMonthAfterYear": true,
    "yearSuffix": "年"
  };
  $.datepicker.setDefaults($.datepicker.regional['ja']);

  $(".change-url-action").change(function() {
    var url = $(this).data('url');
    $($(this).parents('form')[0]).attr('action', url);
  });

  $("#btn-rentals-option").click(function () {
    const url = $('#FormRentalsOption').attr('action')
    window.location.href = url
  })

  $("#btn-sales-option").click(function () {
    const url = $('#FormSalesOption').attr('action')
    window.location.href = url
  })

  $("#btn-sales-fuk-option").click(function () {
    const url = $('#FormSalesFukOption').attr('action')
    window.location.href = url
  })

  $("#btn-factsheet-option").click(function () {
    const url = $('#FormFactsheetOption').attr('action')
    window.open(url, '_blank')
  })

  $("#btn-event-new-option").click(function () {
    const url = $('#FormEventBuilderOption').attr('action')
    window.location.href = url
  })

  $("#btn-banner-new-option").click(function () {
    const url = $('#FormBannerOption').attr('action')
    window.location.href = url
  })

  $(".clickable").click(function() {
    const redirect_link = $(this).parent('tr').data("href")
    const target = $(this).parent('tr').data("target")
    if (target === '_blank') {
      window.open(redirect_link, target)
    } else {
      window.location.href = redirect_link
    }
  })

  counterInputText();
  counterInputTextNoLimit();
  clearInput();
  clearRadioCheckbox();
  dateRangePicker();
  inputDatePicker();
  inputMonthPicker();
  getCurrentDate();
  convertGengou();
  closeModal();
  validateNumber();
  disableWheelInputNumber();
  deleteRecords();
  findLatLngFromAddress();
  redirectUrlWhenChange();
  checkAll();
  search();
  showLoading();
  toogleHelper();
  convertStringToFullWith();
  goBack();
  correctDatepickerValue();

  SaleBukken.init();
  SearchBukken.init();
  RentalApart.init();
  Staff.init();
  Banner.init();
  Customer.init();
  SearchAparment.init();
  Company.init();
  ImageCommon.init();
  ImageSale.init();
  ImageFukuoka.init();
  News.init();
  MailMagazine.init();
  EventBuilder.init();
  ConstructionCase.init();
  SearchGoogleMapModal.init();
  EditBlocks.init();
  EventGeneralInfo.init();
  EventLocationInfos.init();
  EventContact.init();
  MailMagazineSetting.init();
  Scenarios.init();
  Voice.init();
  Stepmail.init();
  Buyers.init();
  SalesResult.init();
  User.init();
  SearchMap.init();
  Event.init();
  Article.init();
  NewsCorporate.init();
  Contact.init();
  ArticleTag.init();
  Sortable.init();
  SortableList.init();
  Brand.init();
  Catalog.init();
  CatalogContact.init();
  Reservation.init();
  EventGeneratorContact.init()
  Magazine.init()
  MagazinePickup.init()
  MagazineNisetai.init()
  FloorPlan.init();
  Tag.init()
  ReleaseExpiredBukken.init()
  Department.init()

  PicturesPositions.init();
  // only init in Dashboard page
  if ($('section.sidebar-section a[href="/kanri/dashboard"]').closest('li').hasClass('active')) {
    Dashboard.init();
  }
});

// remove msg toastr before turbolinks cache it
document.addEventListener("turbolinks:before-cache", function() {
  toastr.remove();
})

function convertStringToFullWith () {
  $(".check-full-with").on('change', function(e){
    var str = $(this).val();
    var position = e.currentTarget.selectionStart;
    str = str.replace(/\u301C/g, "\uFF5E"); // WAVE DASH
    str = str.replace(/\u2212/g, "\uFF0D"); // Minus Sign
    str = str.replace(/\u00A2/g, "\uFFE0"); // CENT SIGN
    str = str.replace(/\u00A3/g, "\uFFE1"); // POUND SIGN
    str = str.replace(/\u00AC/g, "\uFFE2"); // NOT SIGN
    str = str.replace(/\u2013/g, "\uFF0D"); // En Dash
    str = str.replace(/\u2014/g, "\u2015"); // EM DASH
    str = str.replace(/\u2016/g, "\u2225"); // Double Vertical Line
    str = str.replace(/\u203E/g, "\uFFE3"); // OVERLINE
    str = str.replace(/\u00A0/g, "\u0020"); // NO-BREAK SPACE
    str = str.replace(/\u00F8/g, "\u03A6"); // LATIN SMALL LETTER O WITH STROKE
    str = str.replace(/\u203A/g, "\u3009"); // SINGLE RIGHT
    $(this).val(str);
    e.currentTarget.selectionEnd = position;
  })
}

export function counterInputText(){
  $(".counter-input-text").bind('keyup', function(){
    var that = $(this);
    var maxLength = parseInt(that.data('max-length'));
    if(that.val().length > maxLength){
      that.val(that.val().substr(0, maxLength));
      $(that.data('counter')).text(that.val().length);
      return false;
    }
    $(that.data('counter')).text(that.val().length)
    return true;
  })
}
// 文字カウントのみ(入力制限なし)
function counterInputTextNoLimit(){
  $(".counter-input-text-no-limit").bind('keyup', function(){
    var that = $(this);
    var maxLength = parseInt(that.data('max-length'));
    if(that.val().length > maxLength){
      //that.val(that.val().substr(0, maxLength));
      $(that.data('counter')).text(that.val().length);
      return false;
    }
    $(that.data('counter')).text(that.val().length)
    return true;
  })
}
function clearInput(){
  $(".action-clear-input").bind('click', function(){
    $($(this).data('selector-input')).val('');
  });
}

function clearRadioCheckbox(){
  $(".action-clear-radio, .action-clear-checkbox").bind('click', function(){
    $($(this).data('selector-input')).prop('checked',false);
  });
}

function dateRangePicker() {
  var config = {
    format: 'YYYY/MM/DD',
    onSelect: function(dateText) {
      $(this).change();
    }
  }
  $('.start_date').datepicker(config);
  $('.end_date').datepicker(config);
  $(".start_date").on("change", function(e) {
    $(this).closest('.input-daterange').find('.end_date').datepicker( "option", "minDate", new Date($(this).val()));
  });
  $(".end_date").on("change", function(e) {
    $(this).closest('.input-daterange').find('.start_date').datepicker( "option", "maxDate", new Date($(this).val()));
  });
  $('.start_date, .end_date').mask("9999-99-99", { placeholder: "yyyy-mm-dd" });
}

function inputDatePicker(){
  // $('.input-datepicker').datepicker({format: 'yyyy-mm-dd', language: 'ja', todayHighlight: true}).on('changeDate', function (ev) {
  //   const $that = $(this);
  //   if($that.data('set-input-date')){
  //     const d = moment(ev.date);
  //     $($that.data('selector-year')).val(d.format('YYYY'));
  //     $($that.data('selector-month')).val(d.format('MM'));
  //     $($that.data('selector-day')).val(d.format('DD'));
  //   }
  // });
  $('.input-datepicker').datepicker({
    autoclose: true,
    language: 'ja',
    // modalの上に表示させるため
    beforeShow: function() {
      setTimeout(function(){
        $('.ui-datepicker').css('z-index', 9999999);
      }, 0);
    }
  });

  $(document).on('focus',".base_event_date_time_fields .input-datepicker", function() {
    $(this).datepicker();
  });

  $('.input-datepicker').mask("9999-99-99", { placeholder: "yyyy-mm-dd" });

  $('.input-datetimepicker').datetimepicker({
    format: 'yyyy-mm-dd hh:ii',
    language: 'ja',
    minuteStep: 1,
    autoclose: true,
    forceParse: false
  });

  $('.datetimepicker-minute-step30').datetimepicker({
    format: 'yyyy-mm-dd hh:ii',
    language: 'ja',
    minuteStep: 30,
    autoclose: true,
    forceParse: false
  });

  $('.input-datetimepicker, .datetimepicker-minute-step30').mask("9999-99-99 99:99", { placeholder: "yyyy-mm-dd hh:ii" });
}

function inputMonthPicker(){
  $('.input-monthpicker').bootstrapDP({
    format: 'yyyy-mm',
    viewMode: "months",
    minViewMode: 'months',
    forceParse: false,
    autoclose: true,
    language: 'ja'
  });

  $('.input-monthpicker').bind('change', function(){
    if ($(this).attr('data-skip-bind-change') === 'true') return;

    var targetId = $(this).attr('id').replace(/_mpicker$/, ''); // hiddenタグのname末尾を「_mpicker」とすること
    var targetObj = $(`#${targetId}`)

    if (targetObj) {
      var yyyy_mm = $(this).val()
      if (yyyy_mm) {
        targetObj.val(yyyy_mm + '-01') // 常に 01日 をセット
      } else {
        targetObj.val('')
      }
    }
  });

  $('.input-monthpicker').mask('9999-99', { placeholder: 'yyyy-mm' });
}

function getCurrentDate(){
  $(".get-current-date").bind('click', function(){
    const $that = $(this);
    const d = moment();
    $($that.data('selector-fulldate')).datepicker("setDate", new Date());
    $($that.data('selector-year')).val(d.format('YYYY'));
    $($that.data('selector-month')).val(d.format('MM'));
    $($that.data('selector-day')).val(d.format('DD'));
  });
}

function convertGengou(){
  $('.gengou-text').bind('change', function(){
    const $that = $(this);
    setGengouDate($that, $($that.data('gengou-year')), $($that.data('gregorian-year')), $($that.data('gregorian-month')), $($that.data('gengou-input')))
  });

  $('.gengou-year').bind('change', function(){
    const $that = $(this);
    setGengouDate($($that.data('gengou-text')), $that, $($that.data('gregorian-year')), $($that.data('gregorian-month')), $($that.data('gengou-input')))
  });

  $('.generate-gengou').bind('click', function(){
    const $that = $(this);
    setGengouDate($($that.data('gengou-text')), $($that.data('gengou-year')), $($that.data('gregorian-year')), $($that.data('gregorian-month')), $($that.data('gengou-input')))
  });

  $('.gregorian-year').bind('change', function(){
    const $that = $(this);
    setGregorianDate($that, $($that.data('gregorian-month')), $($that.data('gengou-input')))
  });

  $('.gregorian-month').bind('change', function(){
    const $that = $(this);
    setGregorianDate($($that.data('gregorian-year')), $that, $($that.data('gengou-input')))
  });
}

function setGengouDate(gengouText, gengouYear, gregorianYear, gregorianMonth, hiddenDate){
  if(gengouYear.val().length > 0 ){
    const year = kanjidate.fromGengou(gengouText.val(), gengouYear.val());
    gregorianYear.val(year);
    var month = 1;
    hiddenDate.val(`${year}-${month}-01`);
  }
}


function setGregorianDate(gregorianYear, gregorianMonth, hiddenDate){
  var year = gregorianYear.val()
  var month = 1;
  if(year.length > 0){
    if(gregorianMonth.val().length > 0){
      month = gregorianMonth.val();
    }
    hiddenDate.val(`${year}-${month}-01`);
  }else{
    hiddenDate.val('');
  }
}

function closeModal(){
  $(document).on('click', '.close-modal', function(){
    $($(this).parents('.modal')).modal('toggle');
  });
}

function validateNumber(){
  /*$('input[type="number"]').on('keypress',function(e){
    if(isNaN(this.value+""+String.fromCharCode(e.charCode)))
      return false;
  }).on("cut copy paste",function(e){
    e.preventDefault();
  });*/

  $('input.only-number').on('input',function(){
    onlyNumber(this);
  })
}

function disableWheelInputNumber(){
  $('input[type="number"]').on('wheel',function(){
    $(this).blur();
  });
}

function deleteRecords () {
  $('.table-apartment, ' +
    '.betsudai-table, ' +
    '.table-companies, ' +
    '.table-scenarios, ' +
    '.table-bukken-list,' +
    '.table-stepmails,' +
    '.table-user,' +
    '.table-contacts, ' +
    '.mail-setting-contents, ' +
    '.table-magazine,' +
    '.table-event').on('click', '.link__delete', function () {
    const url_delete = $(this).data('delete-url')
    $('#delete_record .delete-rentals, #delete_record .delete-records').attr('href', url_delete)
    $('.delete-records').on('click', function () {
      $(this).parent('li.navlist__item').addClass('disabled');
    })
  })
}

function findLatLngFromAddress() {
  $('.find-lat-lng').on('keyup',function(){
    var $that = $(this);
    $.ajax({
        url: 'https://maps.googleapis.com/maps/api/geocode/json',
        data: { address: $that.val(), key: 'AIzaSyCeuvom3WdL4D6By9JZMo3O_HGBp_lTcJ8' },
        method: 'GET',
        success: function(response) {
          var geometry = response.results[0].geometry
          if(geometry){
            $($that.data('latitude')).val(geometry.location.lat)
            $($that.data('longitude')).val(geometry.location.lng)
          }
        },
    });
  })
}

function redirectUrlWhenChange(){
  $('.redirect-url-when-change').bind('change', function(){
    var $optionSelected = $($(this).find("option:selected"));
    window.location.href = $optionSelected.data('url');
  })
}

function checkAll(){
  $('.check-all').bind('click', function(){
    var checked = false;
    var $that = $(this);
    if($that.attr('type') == 'checkbox'){
      checked = $that.prop('checked');
    }else{
      if($that.data('checked') == false){
        checked = true;
      }
      $that.data('checked', checked);
    }

    $($that.data('elements')).prop('checked', checked);

  })
}

function search() {
  $(".popup").on('click', '#search-company', function () {
    $.ajax({
      url: '/kanri/company/search',
      data: { name: $('#search-company-name').val()},
      method: 'GET',
      success: function(response) {
        const $table = $('.company-table');
        $table.find("tr.company-data").remove();
        $.each(response, function( index, value ) {
          $table.find('tbody').append($('<tr class="company-data">')
            .append($('<td>').append($('<a>').attr('href', 'javascript:;').attr('data-id', value.company_id).text(value.name))))
        });
      },
    });
  });

  $(".popup").on('click', '#search-fukuoka-company', function () {
    $.ajax({
      url: '/kanri/company/search?type=fuk',
      data: { name: $('#search-company-name').val()},
      method: 'GET',
      success: function(response) {
        const $table = $('.company-table');
        $table.find("tr.company-data").remove();
        $.each(response, function( index, value ) {
          $table.find('tbody').append($('<tr class="company-data">')
            .append($('<td>').append($('<a>').attr('href', 'javascript:;').attr('data-id', value.company_id).text(value.name))))
        });
      },
    });
  });

  $(".popup").on('click', 'tr.company-data a', function () {
    const $that = $(this);
    $('#rental-company-name, #sale-company-name').val($that.text());
    $('#apartment_company_id, #bukken_company_id, #fukuoka_bukken_company_id').val($that.data('id'));
    $('#searchCompany').modal('toggle');
  });

  $(".popup").on('keyup', '#search-company-name', function (e) {
    if (e.key === 'Enter' || e.keyCode === 13) {
      $('#search-company').trigger('click');
    }
  });
}

export function onlyNumber($this) {
  var str = $this.value;
  while (str.match(/[^\d]/)) {
    str = str.replace(/[^\d]/, "");
  }
  if ($this.dataset.maxLength) {
    str = str.substr(0, $this.dataset.maxLength)
  }
  if(str){
    if ($this.min) {
      if (parseInt(str) < parseInt($this.min)){
        str = `${$this.min}`;
      }
    }
    if ($this.max) {
      if (parseInt(str) > parseInt($this.max)){
        str = `${$this.max}`;
      }
    }
  }
  $this.value = str;
}
export function disableWheelNumberField() {
  $('input[type=number]').on('mousewheel', function (e) {
    $(e.target).blur();
  });
}

function showLoading(){
  $('.show-betsudai-loading').bind('click', function(){
    $('.modal-loading').modal('show');
  })
}

function toogleHelper(){
  $('.betsudai-helper .helper-icon').bind('click', function(){
    $($(this).parent()).toggleClass('show');
  })
}

function goBack() {
  $('.btn-back').bind('click', function(){
    window.history.back();
  })
}

function correctDatepickerValue() {
  const allPickerClasses = '.input-datepicker, .input-datetimepicker, .start_date, .end_date, .input-monthpicker';

  $(allPickerClasses).on('focusin', function() {
    $(this).attr('data-prevval', $(this).val());
  });

  $(allPickerClasses).on('change', function() {
    let current = $(this).val();
    // relace month format(yyyy-mm) with temp date format(yyyy-mm-01) to validate
    if ($(this).hasClass('input-monthpicker')) current += '-01';

    if (current && !Date.parse(current)) {
      let fieldTxt = $(this).attr("data-datepicker-label") || $(this).closest('tr').find('th').text() || $(this).attr("placeholder");
      toastr.error(fieldTxt + "の日付の形式が不正です");
      const prev = $(this).attr('data-prevval');
      $(this).val(Date.parse(prev) ? prev : '');
    }
  });
}
