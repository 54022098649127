var Event = {
  init: function() {
    const stable = $("#event_sortable")
    stable.sortable({
      placeholder: 'ui-state-highlight',
      revert: (true, 100),
      scroll: true,
      helper: function(event, ui){
        // adjust placeholder td width to original td width
        ui.children().each(function(){
          $(this).width($(this).width());
        });
        return ui;
      },
      update: function(table, ui) {
        var rows = table.target.rows;
        var itemStr = "";
        var infoMessage = ""+$(ui.item[0]).attr('data-order')+"がドラッグ&ドロップされました。";
        for (var i=0; i<rows.length; i++) {
          if(i == rows.length - 1){
            itemStr += rows[i].id;
          }else{
            itemStr += rows[i].id+",";
          }
        }
        $("#infoArea").html(infoMessage);
        $('#ids_change_order').val(itemStr);
        $('#dragInfoArea').fadeIn(500);
      },
      start: function(e, ui) {
        $("#infoArea").html("ドラッグが開始された行："+ ui.item[0].id);
        ui.placeholder.height(ui.helper.outerHeight());
      },
    });
    stable.disableSelection();

    Event.toggleTermField();
    Event.filter();
    Event.validate();
  },
  toggleTermField: function () {
    $('.event-is-always-held').change(function () {
      if(this.value == 'false') {
        $('#event-term-field').css('visibility','visible');
      } else {
        $.each($('#event-term-field input'), function(k, v) {
          v.value = null;
        })
        // $.each($('#event-term-field select'), function(k, v) {
        //   v.value = '00';
        // })
        $('#event-term-field').css('visibility','hidden');
      }
    })
  },
  validate: function() {
    $('.new_event, .edit_event').on('ajax:success', function (event) {
      const data = event.detail[0]
      let toastErrors = data.errors;

      // clear old encode errors
      $("p.js-error-encode").remove();
      const encode_errors = data.encode_errors;
      if (encode_errors) {
        toastErrors.push("※ 黄色のハイライト文字が文字化けになっているので、再度入力してください。");

        // iterator encode errors for each input
        encode_errors.forEach(function(error) {
          // get input has encoding error
          let inputName = Object.keys(error)[0];
          let eventInput = $("#event_" + inputName);
          let inputChars = eventInput.val().split('');
          // mark incorrect text
          error[inputName].forEach(function(encodeIndex) {
            inputChars[encodeIndex] = "<mark>" + inputChars[encodeIndex] + "</mark>";
          });

          // encode error element
          let errorElement = "<p class='color-chestnut-rose mt-2 js-error-encode'>" + inputChars.join('') + "</p>";
          eventInput.closest("td").append(errorElement);
        });
      }

      if (toastErrors.length > 0) {
        toastr.error(toastErrors.join("<br>"))
      }
    })
  },
  filter: function () {
    $('#EventFilterNumberPage').on('change', function () {
      const form_search = $('#EventFilterForm')
      Event.search(form_search.serialize());
    })
  },
  search: function (data) {
    $('.modal-loading').modal('show');
    const url = $('#EventFilterForm').attr('action')
    return $.ajax({
      url: url,
      data: data,
      method: 'get',
      success: function () {
        $('.modal-loading').modal('hide');
        Event.init();
      }
    });
  },
}

export default Event;
