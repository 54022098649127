var PicturesPositions = {
  init: function () {
    if (!$('#modal-pic-arround-positions').length) {
      return
    }

    PicturesPositions.initSort();
    PicturesPositions.handleSubmitForm();
  },

  initSort: function() {
    $('#pic-arround-sortable').sortable({ revert: true });
  },

  handleSubmitForm: function() {
    $('#modal-pic-arround-positions .save-pic-order').click(function(event) {
      event.preventDefault();

      let positions = $('#pic-arround-sortable').sortable('toArray', { attribute: 'data-id' });

      $.each(positions, function(index, imageId) {
        $(`tr[data-id="${imageId}"]`).find('input[type=hidden]').val(index);
      });

      $('.pic-arround-positions-form').submit();
    });
  },
}

export default PicturesPositions;
