$(function() {
  $("#event_contact_form").validate({
    rules: {
      "event_builder_contact[content_type][]": {
        required: !0
      },
      "event_builder_contact[name]": {
        required: !0
      },
      "event_builder_contact[name_kana]": {
        required: !0,
        katakana: !0
      },
      "event_builder_contact[mailAddress]": {
        required: !0,
        email: !0
      },
      "event_builder_contact[phone]": {
        required: !0,
        telnum_2: !0
      },
      "event_builder_contact[zip]": {
        required: !0
      },
      "event_builder_contact[address]": {
        required: !0
      },
      "event_builder_contact[customer_from][]": {
        required: !0
      },
      "action-confirm": {
        required: !0
      },
      "event_builder_contact[year]": {
        required: !0
      },
      "event_builder_contact[month]": {
        required: !0
      },
      "event_builder_contact[day]": {
        required: !0
      },
      "event_builder_contact[place_visit][]": {
        required: !0
      }
    },
    messages: {
      "event_builder_contact[content_type][]": {
        required: "お問合せの内容をお選びください。"
      },
      "event_builder_contact[name]": {
        required: "お名前を入力してください。"
      },
      "event_builder_contact[name_kana]": {
        required: "フリガナを入力してください。",
        katakana: "全角カタカナで入力してください"
      },
      "event_builder_contact[mailAddress]": {
        required: "メールアドレスを入力してください。",
        email: "正しいメールアドレスを入力してください。"
      },
      "event_builder_contact[place]": {
        required: "建設予定地を入力してください。"
      },
      "event_builder_contact[phone]": {
        required: "電話番号を入力してください。"
      },
      "event_builder_contact[zip]": {
        required: "郵便番号を入力してください。"
      },
      "event_builder_contact[address]": {
        required: "住所を入力してください。"
      },
      "event_builder_contact[customer_from][]": {
        required: "このイベントを何で知りましたか？"
      },
      "action-confirm": {
        required: "規約に同意されておりません"
      },
      "event_builder_contact[year]": {
        required: "来場年をお選びください。"
      },
      "event_builder_contact[month]": {
        required: "来場月をお選びください。"
      },
      "event_builder_contact[day]": {
        required: "来場日をお選びください。"
      },
      "event_builder_contact[place_visit][]": {
        required: "来場予定会場を入力してください。"
      }
    },
    errorClass: "myError",
    errorElement: "p"
  })
});
