var EventGeneralInfo = {
  init: function () {
    EventGeneralInfo.start()
    EventGeneralInfo.changeEventHappenTimeType();
    EventGeneralInfo.triggerClickCalendarIcon();
    EventGeneralInfo.savePreview();
    EventGeneralInfo.datepickerSelectPastDate()
    EventGeneralInfo.handleColorPicker()
    EventGeneralInfo.customDatepicker()
    EventGeneralInfo.handleSelectTime();
    EventGeneralInfo.initSelectPeriod();
  },

  handleSelectTime: function () {
    $('.select-time .dropdown-item').click(function (e) {
      e.preventDefault();

      let value = $(this).data('value');
      let selectWrap = $(this).closest('.select-time');
      selectWrap.find('.dropdown-toggle').html(value);
      selectWrap.find('input[type=hidden]').val(value);

      if (selectWrap.hasClass('start-time')) {
        let endTimeWrapper = $(this).closest('.item.nested-fields').find('.select-time.end-time');
        if (parseInt(value) >= parseInt(endTimeWrapper.find('input[type=hidden]').val()) ) {
          endTimeWrapper.find('.dropdown-toggle').html('--:--');
          endTimeWrapper.find('input[type=hidden]').val('');
        }

        endTimeWrapper.find('.dropdown-item').each(function (i) {
          let selectValue = parseInt($(this).data('value'));

          if (parseInt(value) >= selectValue) {
            $(this).addClass('disabled');
          } else {
            $(this).removeClass('disabled');
          }
        });
      }
    });
  },

  initSelectPeriod: function () {
    $('.datepicker-start-date').datepicker({
      autoclose: true,
      language: 'ja',
      minDate: new Date(),
      beforeShow: function() {
        setTimeout(function(){
          $('.ui-datepicker').css('z-index', 9999999);
        }, 0);
      },
      beforeShowDay: function (date) {
        if (date.getDay() == 3) {
          return [false];
        }
        return [true];
      }
    });

    $('.datepicker-end-date').datepicker({
      autoclose: true,
      language: 'ja',
      minDate: new Date(),
      beforeShow: function() {
        setTimeout(function(){
          $('.ui-datepicker').css('z-index', 9999999);
        }, 0);
      },
      beforeShowDay: function (date) {
        if (date.getDay() == 3) {
          return [false];
        }
        return [true];
      }
    });

    $('.select-date .input-group-append').click(function (e) {
      e.preventDefault();

      $(this).closest('.input-group').find('input.form-control').focus();
    });
  },

  start: function () {
    $('.manual_event_calendar').on('cocoon:after-insert', function () {
      EventGeneralInfo.customDatepicker()
      EventGeneralInfo.datepickerSelectPastDate()
      $('.input-datepicker-event').mask("9999-99-99", { placeholder: "yyyy-mm-dd" });
    });

    $('.period_event_calendar').on('cocoon:after-insert', function () {
      EventGeneralInfo.initSelectPeriod();
      EventGeneralInfo.handleSelectTime();
    })
  },
  customDatepicker: function () {
    $('.input-datepicker-event').datepicker({
      autoclose: true,
      language: 'ja',
      minDate: new Date(),
      // modalの上に表示させるため
      beforeShow: function() {
        setTimeout(function(){
          $('.ui-datepicker').css('z-index', 9999999);
        }, 0);
      }
    });
  },

  changeEventHappenTimeType: function () {
    $(document).on('click', ".event_happen_time_type input[type='radio']", function() {
      var inputValue = $(this).attr("value");
      var targetBox = $("." + inputValue);
      $(".event_happen_time").not(targetBox).hide();

      $(targetBox).show();

      if (inputValue === 'manual_event') {
        $('.basic-info-display-date').removeClass('d-none')
      } else {
        $('.basic-info-display-date').addClass('d-none')
        $('.basic-info-display-date-input').val('')
      }
    });
  },
  triggerClickCalendarIcon: function () {
    $(document).on('click', '#event_date_status .calendar', function() {
      console.log($(this).prev())
      $(this).prev().datepicker("show")
    })
  },
  savePreview: function () {
    $('.btn-edit-preview').on('click', function () {
      $("#betsudai_home_events_basic_info_action").val('save-preview')
      $("#btn_submit_betsudai_home_event_builder").trigger('click')
    })
  },
  datepickerSelectPastDate: function () {
    $('.manual_event_calendar .input-datepicker-event').change(function () {
      var updatedDate = $(this).val();
      var instance = $(this).data("datepicker");
      var date = $.datepicker.parseDate(instance.settings.dateFormat || $.datepicker._defaults.dateFormat, updatedDate, instance.settings);

      if (date < new Date()) {
        $(this).datepicker("setDate", new Date());
      }
    });
  },
  handleColorPicker: function() {
    if ($('.picker_launcher').length > 0) {
      $.each($('.picker_launcher'), function () {
        new ColorPicker(this, $(this).data('color'));
        $(this).on('colorChange', (event) => {
          const color = event.detail.color.hexa;
          $(this).next().val(color)
        })
      })
    }
  }
}

export default EventGeneralInfo;
