import {handleAjaxError} from "../handle_error";

var Department = {
  init: function () {
    if ($('#sort-department-modal').length > 0) {
      Department.initiateSortIcon()
      Department.toggleBtnNewDepartment()
      Department.resetSort()
      Department.newDepartment()
      Department.handleDeleteDepartment()
      Department.handleConfirmDeleteDepartment()
      Department.updateSortDepartment()
    }
  },

  initiateSortIcon: function () {
    $('.sortable-department').sortable(
      {
        handle: '.sort-department-icon',
        cursor: 'move',
        scroll: false
      }
    )
  },

  updateSortDepartment: function () {
    $(document).on('click', '.btn-update-department', function () {
      let order = $('.sortable-department').sortable(
        'toArray',{ attribute: 'data-sort-department' }
      );
      let group = $('#department_group').val();

      $.ajax({
        url: '/kanri/departments/update_all',
        data: { order: order, group: group },
        method: 'POST',
        success: function(res) {
          toastr.success('更新されました')
        },
        error: function (request, status, error) {
          handleAjaxError(request, status, error);
        }
      })
    })
  },

  toggleBtnNewDepartment: function () {
    $('#department_name').on('change', function () {
      $('.btn-new-department').prop('disabled', $(this).val().length == 0)
    })
  },

  resetSort: function () {
    $('#sort-department-modal').on('hidden.bs.modal', function () {
      $(".sortable-department").sortable("cancel")
    })
  },

  newDepartment: function () {
    $(document).on('click', '.btn-new-department', function (event) {
      event.preventDefault();

      let departmentName = $('#department_name').val();
      let departmentGroup = $('#department_group').val();

      $.ajax({
        url: '/kanri/departments/ajax_create_department',
        type : 'POST',
        data : { name: departmentName, group: departmentGroup },
        success: function (response) {
          toastr.success('部署を追加しました。');
          $('#department_name').val('');
        },
        error: function (response, _status, _error) {
          return toastr.error(response.responseJSON.messages.join('<br>'));
        }
      });
    });
  },

  handleDeleteDepartment: function () {
    $(document).on('click', '.btn-delete-department', function (event) {
      event.preventDefault();

      let departmentId = $(this).data('department-id');

      $.ajax({
        url: `/kanri/departments/check_used/${departmentId}`,
        type : 'GET',
        success: function (response) {
          if (response.used) {
            $('#delete-used-department-modal').data('id', departmentId);
            $('#delete-used-department-modal').modal('show');
          } else {
            Department.deleteDepartment(departmentId);
          }
        }
      });
    });
  },

  handleConfirmDeleteDepartment: function () {
    $('#delete-used-department-modal .btn-delete-used-department').on('click', function (event) {
      event.preventDefault();

      let departmentId = $('#delete-used-department-modal').data('id');
      Department.deleteDepartment(departmentId);
    });
  },

  deleteDepartment: function (departmentId) {
    let departmentGroup = $('#department_group').val();
    $.ajax({
      url: '/kanri/departments/ajax_delete_department',
      type : 'DELETE',
      data : { id: departmentId, group: departmentGroup },
      success: function (response) {
        toastr.success('部署を削除しました。');
        $('#delete-used-department-modal').modal('hide');
      },
      error: function (response, _status, _error) {
        return toastr.error(response.responseJSON.messages.join('<br>'));
      }
    });
  },
}

export default Department;
