import {handleAjaxError} from "../handle_error";

var EventContact = {
  init: function () {
    EventContact.initFilter();
    EventContact.changeStatus();
    EventContact.showModalDetail();
    EventContact.sort();
  },

  initFilter: function () {
    $('#event_contact_status').bind('change', function () {
      $('.modal-loading').modal('show')
      EventContact.searchContact();
    })

    $(".contact-status-filter").on('click', function() {
      $(".contact-status-filter").removeClass('active');
      $(this).addClass('active')
      $('#event_contact_status').val($(this).data("status"))
      $('.modal-loading').modal('show')
      EventContact.searchContact();
    });
  },

  searchContact: function () {
    $('form#event_contact_search_form input#btn_event_contact_search').trigger('click');
  },

  sort: function () {
    $('.table-manage-contact').on('click', '.event-contact-sort', function () {
      const order = $(this).data('order');
      const sort_by = $(this).data('sort_by');
      $('#event_contact_search_form input#event_contact_sort').val(`${sort_by} ${order}`);
      $('.modal-loading').modal('show')
      EventContact.searchContact();
    })
  },

  changeStatus: function() {
    $('.select-contact-status')
      .on('focus', function () {
        $(".btn-confirm-status-contact").attr('data-status-old', $(this).val())
      })
      .on('change', function () {
        $(".btn-confirm-status-contact").attr('data-url', $(this).parent('.select').data('url'))
        $(".btn-confirm-status-contact").attr('data-status', $(this).val())
        $(".btn-confirm-status-contact").attr('data-id', $(this).parent('.select').attr('id'))
        $(this).val($(".btn-confirm-status-contact").attr('data-status-old'));
        $("#modal_contact_change_status").modal('show')
      })

    $(".btn-confirm-status-contact").on('click', function() {
      const status = $(this).attr('data-status')
      const url = $(this).attr('data-url')
      $.ajax({
        url: url,
        data: { status: status },
        method: 'PUT',
        success: function(response) {
          if(response.result) {
            let id = $(".btn-confirm-status-contact").attr('data-id');
            let select = $("#"+id).find('select')
            select.removeClass("supported not_supported")
            select.addClass(status)
            select.val($(".btn-confirm-status-contact").attr('data-status'));
            toastr.success('ステータスを更新しました。')
          }
        },
        error: function (request, status, error) {
          handleAjaxError(request, status, error);
        },
        complete: function () {
          $("#modal_contact_change_status").modal('hide')
        }
      })
    });
  },

  showModalDetail: function() {
    $(".btn-contact-detail").on('click', function() {
      const url = $(this).attr('data-url')
      $('.modal-loading').modal('show')
      $.ajax({
        url: url,
        method: 'GET',
        success: function(response) {
          $("#showDetailContact").modal('show')
          EventContact.updateContactStatus();
          EventContact.assignStaff();
          EventContact.postComment();
          EventContact.slideModal();
        },
        error: function (request, status, error) {
          handleAjaxError(request, status, error);
        },
        complete: function () {
          $('.modal-loading').modal('hide')
        }
      })
    });
  },

  slideModal: function() {
    $(".btn-contact-next, .btn-contact-previous").on('click', function() {
      const url = $(this).attr('data-url')
      $.ajax({
        url: url,
        method: 'GET',
        success: function(response) {
          EventContact.updateContactStatus();
          EventContact.assignStaff();
          EventContact.postComment();
          EventContact.slideModal();
        },
        error: function (request, status, error) {
          handleAjaxError(request, status, error);
        }
      })
    });
  },

  updateContactStatus: function() {
    $(".change-contact-status").bind('change', function() {
      const that = $(this)
      const status = that.val()
      const url = that.parent(".select").attr('data-url')
      const id = that.parent(".select").attr('data-id')
      $.ajax({
        url: url,
        data: { status: status },
        method: 'PUT',
        success: function(response) {
          if(response.result) {
            that.removeClass("supported not_supported")
            that.addClass(status)
            
            let select = $("#status_contact_"+id).find('select')
            select.val(status).removeClass("supported not_supported").addClass(status)
            toastr.success('ステータスを更新しました。')
          }
        },
        error: function (request, status, error) {
          handleAjaxError(request, status, error);
        },
      })
    });
  },

  assignStaff: function() {
    $(".contact-assign-status").bind('change', function() {
      const account_id = $(this).val()
      const url = $(this).parent(".select").attr('data-url')
      $.ajax({
        url: url,
        data: { account_id: account_id },
        method: 'PUT',
        success: function(response) {
          if(response.result) {
            toastr.success('担当者設定を更新しました。')
          }
        },
        error: function (request, status, error) {
          handleAjaxError(request, status, error);
        },
      })
    });
  },

  postComment: function() {
    $(".btn-submit-comment").css('opacity', '0.5').attr('disabled', 'disabled');
    $('#contact_comment').on('keyup',function() {
      if ($.trim($(this).val())) {
        $(".btn-submit-comment").css('opacity', '1').removeAttr('disabled');
      } else {
        $(".btn-submit-comment").css('opacity', '0.5').attr('disabled', 'disabled');
      }
    });

    $(".btn-submit-comment").on('click', function() {
      const comment = $("#contact_comment").val();
      const url = $(this).attr('data-url');
      const contactType = $(this).attr('data-type');
      const contactId = $(this).attr('data-id');
      $.ajax({
        url: url,
        data: {
          comment: comment,
          contact_comment_type: contactType,
          contact_comment_id: contactId
        },
        method: 'POST',
        success: function(response) {
          if(response) {
            toastr.success('コメントしました。')
          }
          $("#contact_comment").val('')
          $('#contact_comment_body').scrollTop($('#contact_comment_body')[0].scrollHeight);
        },
        error: function (request, status, error) {
          handleAjaxError(request, status, error);
        },
      })
    });
  },
}

export default EventContact;
