var SortableList = {
  init: function() {
    if(!$('.datalist.sortable-list').length){
      return;
    }
    SortableList.initSortable();
    SortableList.submitSortable();
  },

  initSortable: function() {
    $('.sortable-list').sortable({
      placeholder: 'ui-state-highlight',
      revert: (true, 100),
      handle: ".drag",
      scroll: true,
      items: 'tr.sortable'
    });
  },
  submitSortable: function() {
    $('#submit-sortable-btn').on('click', function (event) {
      event.preventDefault();
  
      let order = $('.sortable-list').sortable('toArray', { attribute: 'data-id' });
  
      $('#ids_change_order').val(order);
      $('#update_sort').submit();
    });
  }
}
export default SortableList;
