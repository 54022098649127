import {handleDownloadCSV} from "../download_csv";

var SearchBukken = {
  init: function () {
    SearchBukken.filter();
    SearchBukken.sort();
    SearchBukken.detailSearchBukken();
    SearchBukken.resetSearchBukkenForm();
    SearchBukken.exportCSV();
  },
  filter: function () {
    $('#BukkenFilterNumberPage, #BukkenFilterKubun, #BukkenFilterStatus, #BukkenFilterReleaseExpiredDate, #BukkenFilterIsPortalTop').on('change', function () {
      // reset filter values
      $('#search_kubun').val($('#BukkenFilterKubun').val());
      $('#search_status').val($('#BukkenFilterStatus').val());
      if ($('#BukkenFilterReleaseExpiredDate option:selected').text() === '5日') {
        $('#search_release_expired_date').prop("checked", "checked")
      } else {
        $('#search_release_expired_date').prop("checked", null)
      }

      SearchBukken.searchBukken($('#formSearchBukken, #BukkenFilterNumberPage, #BukkenFilterReleaseExpiredDate, #BukkenFilterIsPortalTop').serialize());
    })
  },
  searchBukken: function (data) {

    // hide model search
    $('#filterArea').modal('hide')

    $('.modal-loading').modal('show');
    const url = $('#BukkenFilterForm').attr('action')
    return $.ajax({
      url: url,
      data: data,
      method: 'get',
      success: function () {
        $('.modal-loading').modal('hide');
      }
    });
  },
  sort: function () {
    $('.table-bukken-list').on('click', '.bukken-sort', function () {
      const order = $(this).data('order');
      const sort_by = $(this).data('sort_by');
      const number_page = $('#BukkenFilterNumberPage').val();
      const kubun = $('#BukkenFilterKubun').val();
      const status = $('#BukkenFilterStatus').val();
      const release_expired_date = $('#BukkenFilterReleaseExpiredDate').val();
      const data = { order: order, sort_by: sort_by, number_page: number_page, kubun: kubun, status: status, release_expired_date: release_expired_date }
      const data_string = Object.keys(data).map(function(key) {
        return key + '=' + data[key]
      }).join('&');
      const form_search = $('#formSearchBukken').serialize();
      const query_string = form_search + '&' + data_string;
      SearchBukken.searchBukken(query_string)
    })
  },
  detailSearchBukken: function () {
    $('.btnSearchBukken').on('click', function () {
      // reset filter values
      $('#BukkenFilterKubun').val($('#search_kubun').val())
      $('#BukkenFilterStatus').val($('#search_status').val())
      if ($('#search_release_expired_date').prop("checked")) {
        $('#BukkenFilterReleaseExpiredDate').val($('#search_release_expired_date').val())
      } else {
        $('#BukkenFilterReleaseExpiredDate').val(null)
      }

      SearchBukken.searchBukken($('#formSearchBukken, #BukkenFilterNumberPage, #BukkenFilterReleaseExpiredDate, #BukkenFilterIsPortalTop').serialize());
    })
  },
  resetSearchBukkenForm: function () {
    $('.btnResetSearchBukkenForm').on('click', function () {
      $('#formSearchBukken')[0].reset();
    })
  },
  exportCSV: function () {
    $('.btnExportBukkenCsv').on('click', function () {
      $('.modal-loading').modal('show');
      const url_csv = $(this).data('url-csv')
      const form_search = $('#formSearchBukken')
      let request_url = url_csv + `${url_csv.includes("?") ? '&' : '?'}` + form_search.serialize()
      handleDownloadCSV(request_url, "bukken")
    })
  },
}

export default SearchBukken;
