// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("../src/cocoon");
require('file-saver');
require('select2');

window.toastr = require('toastr')

import './libs/jquery.tablednd_0_5'
// Masked Input Plugin: https://github.com/digitalBush/jquery.maskedinput
import './libs/jquery.maskedinput.min'
// 元のライブラリに不具合があったため、ソースコードを forkした上で不具合修正し、コードを配置
// 元ソース: https://github.com/R-TEK/colr_pickr
// fork先（修正後）のソース: https://github.com/DavidHopee/colr_pickr
import './libs/colr_pickr.min'
import 'moment/dist/moment'
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker'
import 'bootstrap-datepicker/dist/locales/bootstrap-datepicker.ja.min'
import 'bootstrap-datetime-picker/js/bootstrap-datetimepicker'
import 'bootstrap-datetime-picker/js/locales/bootstrap-datetimepicker.ja'
import 'kanjidate/kanjidate'
import './bootstrap_ui.js'
import './utils.js'
import './jquery.autoresize.js'
import 'select2';
import 'select2/dist/css/select2.css';
import "select2/dist/css/select2.min.css";
import './libs/image-uploader'

var bootstrapDatepicker = $.fn.datepicker.noConflict(); // return $.fn.datepicker to previously assigned value
$.fn.bootstrapDP = bootstrapDatepicker;                 // give $().bootstrapDP the bootstrap-datepicker functionality
require('jquery-ui-dist/jquery-ui');

import jquery from 'jquery';
window.$ = window.jquery = jquery;

toastr.options = {
  positionClass: 'toast-top-center',
  timeOut: 5000,
  preventDuplicates: true
}

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
