import Chart from 'chart.js/auto';

const Dashboard = {
  init: function() {
    Dashboard.setData();
    Dashboard.drawCustomerChart();
    Dashboard.drawBukkensPublicChart();
    Dashboard.cardRefresh();
    Dashboard.changeContext();
    Dashboard.setFirstTime();
  },
  setData: function() {
    // show spinner loading in button
    window.loading = '<span aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span>';

    // Customer chart variable
    Dashboard.configLineChart('customersChart', 'customersChart')

    // bukkens public chart variable
    Dashboard.configBarChart('bukkens_public_chart', 'bukkens_public')

    // bukken table variable
    window.table_bukken = []

    // Apartment variable
    window.apartment_public = {}
  },
  configLineChart: function(element, target) {
    window[target] = {};
    window[target].element = document.getElementById(element);
    window[target].data = {
      type: 'line',
      data: {
        labels: [],
        datasets: []
      },
    };
  },
  configBarChart: function (element, target) {
    window[target] = {};
    window[target].element = document.getElementById(element);
    window[target].data = {
      type: 'bar',
      data: {
        labels: [],
        datasets: []
      },
      options: {
        scales: {
          y: {
            ticks: {
              stepSize: 1,
              callback: function(value, index, values) {
                return  (index === values.length-1) ? '件数   ' + value : value
              }
            }
          }
        }
      }
    };
  },
  getData: function(context, entity, target, type='chart', from='', to='') {
    var params = { context, entity, type }
    if (from!=='')
      params['from'] = from
    if (to!=='')
      params['to'] = to
    $.ajax({
      url: '/kanri/dashboard/data_analytic',
      data: params,
      method: 'GET',
      dataType: 'JSON',
      success: function(response) {
        if (type==='chart') {
          window[target].data.data = response;
          Dashboard.updateChart(target);
          // active filter
          Dashboard.fillInFilter(target, response.filter)
        } else if (type==='table') {
          window[target] = response;
          Dashboard.updateTable(target);
        } else {
          window[target] = response;
          Dashboard.updateCard(target);
        }
      },
      error: function (request, status, error) {
      }
    })
  },
  fillInFilter: function(target, data) {
    let currentGroup = $(`div[data-target="${target}"]`);
    // remove all older active data
    currentGroup.find('label.active').removeClass('active');
    currentGroup.find('input.start_date').datepicker('setDate', null)
    currentGroup.find('input.end_date').datepicker('setDate', null)
    // fill in new data
    currentGroup.find(`label input[data-context="${data.context}"]`).parent().addClass('active')
    currentGroup.find('input.start_date').datepicker('setDate', new Date(data.start_date))
    currentGroup.find('input.end_date').datepicker('setDate', new Date(data.end_date))
  },
  drawCustomerChart: function() {
    //Line charts
    let customers_context = window.customersChart.element.getContext('2d');
    window.customersChart.chart = new Chart(customers_context, window.customersChart.data);
  },
  updateChart: function(target) {
    window[target].chart.update();
    // remove loading after chart rendered
    $(`div[data-target="${target}"]`).closest('.component').find('.loading').html('');
  },
  changeContext: function() {
    Dashboard.changeInputContext();
    Dashboard.changeSelectContext();
    Dashboard.changeDaterangeContext();
  },
  changeInputContext: function() {
    $(document).on('change', 'input.context', function() {
      let self = $(this);
      let context = self.attr('data-context')
      let closest_group = self.closest('.context-data');
      let entity = closest_group.attr('data-entity');
      let target = closest_group.attr('data-target');
      // show loading
      self.closest('.component').find('.loading').html('').append(window.loading);
      Dashboard.getData(context, entity, target)
    });
  },
  changeSelectContext: function() {
    $(document).on('change', 'select[name="context"]', function() {
      let self = $(this);
      let context = self.val();
      let closest_group = self.closest('.select-title');
      let entity = closest_group.attr('data-entity');
      let target = closest_group.attr('data-target');
      let type = closest_group.attr('data-type');
      // show loading
      self.closest('.component').find('.loading').html('').append(window.loading);
      Dashboard.getData(context, entity, target, type)
    });
  },
  changeDaterangeContext: function() {
    $(document).on('change', '[data-context="daterange"]', function() {
      let self = $(this);
      let context = self.attr('data-context');
      let from = self.closest('.input-group').find('.start_date').val();
      let to = self.closest('.input-group').find('.end_date').val();
      let closest_group = self.closest('.context-data');
      let entity = closest_group.attr('data-entity');
      let target = closest_group.attr('data-target');
      if (to !== '') {
        // show loading
        self.closest('.component').find('.loading').html('').append(window.loading);
        Dashboard.getData(context, entity, target, 'chart', from, to)
      }
    });
  },
  setFirstTime: function() {
    // get data with context 'daily' in first time
    $('input[data-context="daily"]').trigger('change');
    $('select[name="context"]').trigger('change');
    $('.card-refresh').trigger('click');
  },
  drawBukkensPublicChart: function() {
    let bukkens_public_context = window.bukkens_public.element.getContext('2d');
    window.bukkens_public.chart = new Chart(bukkens_public_context, window.bukkens_public.data);
  },
  drawApartmentsPublicChart: function() {
    let apartment_public_context = window.apartment_public.element.getContext('2d');
    window.apartment_public.chart = new Chart(apartment_public_context, window.apartment_public.data)
  },
  cardRefresh: function() {
    $(document).on('click', '.card-refresh', function(e) {
      e.preventDefault();
      let self = $(this);
      let context = self.attr('data-context');
      let closest_group = self.closest('.context-data');
      let entity = closest_group.attr('data-entity');
      let target = closest_group.attr('data-target');
      let btnRefresh = closest_group.find('.fa-refresh')
      // show loading
      btnRefresh.addClass('fa-spin')
      self.closest('.component').find('.loading').html('').append(window.loading);
      Dashboard.getData(context, entity, target, 'card')
    })
  },
  updateCard: function(target) {
    let data = window[target];
    let totalNumber = data.total;
    let updatedAt = data.updatedAt;
    let $target = $(`#${target}`);
    let btnRefresh = $target.closest('.context-data').find('.fa-refresh')
    $({ totalVal: 0 }).animate({ totalVal: totalNumber }, {
      duration: 3000,
      easing:'swing',
      step: function() {
        $target.text(Dashboard.commaSeparateNumber(Math.round(this.totalVal)));
      }
    });
    $(`div[data-target="${target}"] .bottom-text`).find('small').fadeOut(600, function() {
      $(this).text(updatedAt).fadeIn(600);
    });
    // remove loading
    btnRefresh.removeClass('fa-spin')
    $(`div[data-target="${target}"]`).closest('.component').find('.loading').html('');
  },
  commaSeparateNumber: function(val) {
    while (/(\d+)(\d{3})/.test(val.toString())){
      val = val.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    }
    return val;
  },
  updateTable: function(target) {
    var rows = '';
    $.each(window[target], function(index, val) {
      if (index%2 == 0)
        rows += '<tr>'
      rows += `<td class="col-sm-4">${val.name}</td><td class="col-sm-2 text-center">${val.total}</td>`;
      if (index%2 == 1)
        rows += '</tr>'
    })
    // insert rows into table
    $(`div[data-target="${target}"]`).closest('.component').find('table tbody').html('').append(rows)
    // remove loading
    $(`div[data-target="${target}"]`).closest('.component').find('.loading').html('');
  }
}

export default Dashboard;
