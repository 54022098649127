import {handleAjaxError} from "../handle_error";

var Article = {
  init: function() {
    Article.filterCategory();
    Article.sort();
  },

  filterCategory: function () {
    $('#article_category').bind('change', function () {
      const data = { category: $(this).val() }
      Article.searchArticle(data);
    })
  },

  searchArticle: function (data) {
    $.ajax({
      url: '/kanri/articles/search',
      data: data,
      method: 'get',
      success: function (response) {
      },
    });
  },

  sort: function () {
    $('.table-article').on('click', '.article-sort', function (e) {
      const sortBy = $(this).data('sort_by')
      const order = $(this).data('order')
      const category = $('#article_category').val()
      const data = { 'sort_by': sortBy, 'order': order, 'category': category }
      Article.searchArticle(data)
    })
  }

}

export default Article;