import {handleAjaxError} from "../handle_error";
import {handleDownloadCSV} from "../download_csv";

var SearchAparment = {
  init: function () {
    SearchAparment.checkedCheckbox();
    SearchAparment.filterNumberPage();
    SearchAparment.filterKubun();
    SearchAparment.sortApartment();
    SearchAparment.searchApartment();
    SearchAparment.resetSearchApartmentForm();
    SearchAparment.exportCSV();
    SearchAparment.backToTab();
    SearchAparment.bulkChangeSelect();
    SearchAparment.clearInputCompanyBulk();
    SearchAparment.submitBulkChange();
    SearchAparment.searchCompany();
    SearchAparment.filter();
  },

  checkedCheckbox: function () {
    // handle checkbox for oita_city

    $('#oita_city_all').on('change', function () {
      const check_all = $('#oita_city_all').is(':checked')
      $('.chk-oita, .chk-s-oita').prop('checked', check_all)
    })

    const chk_oitas = $('.chk-oita').length
    for (let i = 1; i <= chk_oitas; i++) {
      $(`#chk-oita-${i}`).on('change', function () {
        const chk = $(`#chk-oita-${i}`).is(':checked')
        $(`.chk-s-oita-${i}`).prop('checked', chk)
      })
    }

    // handle checbox for beppu_city

    $('#beppu_city_all').on('change', function () {
      const check_all = $('#beppu_city_all').is(':checked')
      $('.chk-beppu, .chk-s-beppu').prop('checked', check_all)
    })

    const chk_beppus = $('.chk-beppu').length + chk_oitas
    for (let i = chk_oitas + 1; i <= chk_beppus; i++) {
      $(`#chk-beppu-${i}`).on('change', function () {
        const chk = $(`#chk-beppu-${i}`).is(':checked')
        $(`.chk-beppu-${i}`).prop('checked', chk)
      })
    }

    // handle checkbox for oita province

    $('#oita_provinces').on('change', function () {
      const check_all = $('#oita_provinces').is(':checked')
      $('.chk-province, .chk-s-province').prop('checked', check_all)
    })

    const chk_province = $('.chk-province').length + chk_beppus
    for (let i = chk_beppus + 1; i <= chk_province; i++) {
      $(`#chk-province-${i}`).on('change', function () {
        const chk = $(`#chk-province-${i}`).is(':checked')
        $(`.chk-province-${i}`).prop('checked', chk)
      })
    }
  },

  filterNumberPage: function () {
    $('#number_page').on('change', function () {
      SearchAparment.filterAjaxAparment('', '')
    })
  },

  filterKubun: function () {
    $('#filter_kubun').on('change', function () {
      const number_page = $('#number_page').val()
      const form_search = $('#formSearchApartment')
      const url_search = form_search.attr('action');
      $('#search_kubun').val($('#filter_kubun').val())

      // show loading
      $('.modal-loading').modal('show')

      $.ajax({
        url: url_search,
        type: 'GET',
        data: form_search.serialize() + `&number_page=${number_page}`,
        success: function () {
          $('.modal-loading').modal('hide')
        }
      })
    })
  },

  filter: function () {
    $('#AartmentFilterIsPortalTop').on('change', function () {
      // reset filter values
      $('#search_is_portal_top').val($('#AartmentFilterIsPortalTop').val());

      SearchAparment.searchAparment($('#AartmentFilterIsPortalTop').serialize());
    })
  },
  searchAparment: function (data) {

    // hide model search
    $('#filterArea').modal('hide')
    $('.modal-loading').modal('show');
    const url = $('#formFilterApartment').attr('action')
    return $.ajax({
      url: url,
      data: data,
      method: 'get',
      success: function () {
        $('.modal-loading').modal('hide');
      }
    });
  },

  sortApartment: function () {
    $('.table-apartment').on('click', '.sort-asc, .sort-desc', function () {
      const order = $(this).data('order')
      const sort_by = $(this).data('sort_by')
      SearchAparment.filterAjaxAparment(order, sort_by)
    })
  },

  filterAjaxAparment: function (order, sort_by) {
    const number_page = $('#number_page').val()
    const form_search = $('#formSearchApartment')
    const url_search = form_search.attr('action')

    // show loading
    $('.modal-loading').modal('show')

    $.ajax({
      url: url_search,
      type: 'GET',
      data: form_search.serialize() +
        `&number_page=${number_page}&order=${order}&sort_by=${sort_by}`,
      success: function () {
        $('.modal-loading').modal('hide')
      }
    })
  },

  searchApartment: function () {
    $('.btnSearchApartment').on('click', function () {
      // reset value filter
      $('#number_page').val(10)
      $('#filter_kubun').val($('#search_kubun').val())

      const form_search = $('#formSearchApartment')
      const url_search = form_search.attr('action')

      // hide model search
      $('#filterArea').modal('hide')

      // show loading
      $('.modal-loading').modal('show')

      $.ajax({
        url: url_search,
        type : 'GET',
        data : form_search.serialize(),
        success: function () {
          $('.modal-loading').modal('hide')
        }
      })
    })
  },

  resetSearchApartmentForm: function () {
    $('.btnResetApartmentForm').on('click', function () {
      $('#formSearchApartment')[0].reset();
    })
  },

  exportCSV: function () {
    $('.btnExportCsv').on('click', function () {
      $('.modal-loading').modal('show');
      const url_csv = $(this).data('url-csv')
      const file_name = $(this).data('tab')
      const form_search = $('#formSearchApartment')
      let request_url = url_csv + '?' + form_search.serialize()
      handleDownloadCSV(request_url, file_name === 'all' ? 'apartments' : file_name)
    })
  },

  backToTab: function () {
    $('#back_to_tab').on('click',function () {
      var url = window.location.href;
      window.location.href = url.slice(0, url.lastIndexOf('/'));
    })
  },

  bulkChangeSelect: function () {
    $('#kubun_bulk, #dealing_type_bulk').on('change', function () {
      SearchAparment.toggleBtnBulkChange();
    })
  },

  clearInputCompanyBulk: function () {
    $('.input-company-bulk').on('click', function () {
      SearchAparment.toggleBtnBulkChange();
    })
  },

  submitBulkChange: function () {
    $('.btn-accept-bulk-change').on('click', function () {
      const url_bulk_change = $('#formBulkChange').attr('action')
      const data_bulk_change = $('#formBulkChange, #formSearchApartment').serialize()

      $.ajax({
        url: url_bulk_change,
        type : 'POST',
        data : data_bulk_change,
        success: function () {
          $('#bulk_change').modal('hide');
        },
        error: function (request, status, error) {
          handleAjaxError(request, status, error);
        }
      })
    })
  },

  toggleBtnBulkChange: function () {
    const kubun_bulk = $('#kubun_bulk').val()
    const dealing_type_bulk = $('#dealing_type_bulk').val()
    const company_id_bulk = $('#company_id_bulk').val()

    if (!kubun_bulk && !dealing_type_bulk && !company_id_bulk) {
      $('.btn-bulk-change').prop('disabled', true)
    } else {
      $('.btn-bulk-change').prop('disabled', false)
      SearchAparment.handleTitleBulkChange(kubun_bulk, dealing_type_bulk, company_id_bulk)
    }
  },

  searchCompany: function () {
    $(".popup").on('click', '#search-company-bulk', function () {
      $.ajax({
        url: '/kanri/company/search',
        data: { name: $('#search-company-name-bulk').val()},
        method: 'GET',
        success: function(response) {
          const $table = $('.company-table-bulk');
          $table.find("tr.company-data-bulk").remove();
          $.each(response, function( index, value ) {
            $table.find('tbody').append($('<tr class="company-data-bulk">')
              .append($('<td>').append($('<a>').attr('href', 'javascript:;').attr('data-id', value.company_id).text(value.name))))
          });
        },
      });
    });

    $(".popup").on('click', 'tr.company-data-bulk a', function () {
      const $that = $(this)
      const company_id_bulk = $('#company_id_bulk')
      $('#rental-company-bulk').val($that.text())
      company_id_bulk.val($that.data('id'))
      $('#searchCompanyBulk').modal('toggle')
      $('.btn-bulk-change').prop('disabled', false);

      SearchAparment.handleTitleBulkChange(
        $('#kubun_bulk').val(),
        $('#dealing_type_bulk').val(),
        company_id_bulk.val()
      )
    });


    $(".popup").on('keyup', '#search-company-name', function (e) {
      if (e.key === 'Enter' || e.keyCode === 13) {
        $('#search-company-bulk').trigger('click')
      }
    })
  },

  handleTitleBulkChange: function (bulk_kubun, dealing_type_bulk, company_id_bulk) {
    let desc_kubun = '', desc_dealing_type = '', desc_company_id = '';

    if (bulk_kubun) {
      desc_kubun = '区分'
    }

    if (dealing_type_bulk) {
      desc_dealing_type = '取引形態'
    }

    if (company_id_bulk) {
      desc_company_id = '業者'
    }

    let desc_title = [desc_kubun, desc_dealing_type, desc_company_id].filter(function (e) {
      return e !== '';
    })
    desc_title = desc_title.toString().replace(/,/g, '、')

    $('#bulk_change .title-bulk-change').text('一括変更した後は元に戻せません。本当に変更しますか？')
    $('#desc_title').val(desc_title + 'を一括変更しました。')
  }
}

export default SearchAparment;