var SalesResult = {
  init: function() {
    SalesResult.sort();
    SalesResult.filler();
  },

  filler: function() {
    $('#q_sales_type_eq').bind('change', function () {
      SalesResult.search();
    })
  },

  sort: function () {
    $('.sales-results-table').on('click', '.sales-result-sort', function () {
      const order = $(this).data('order');
      const sort_by = $(this).data('sort_by');
      $('#sales_results_search_form input#sort_sales_results').val(`${sort_by} ${order}`);
      SalesResult.search();
    })
  },

  search: function () {
    $('form#sales_results_search_form input#sales_results_search_btn').trigger('click');
  },
}

export default SalesResult;