import {handleAjaxError} from "../handle_error";
import moment from 'moment'

var MailMagazineSetting = {
  init: function() {
    MailMagazineSetting.start();
  },

  bukkens: [],
  selected_bukkens: [],

  start: function () {
    MailMagazineSetting.bukkens = []
    MailMagazineSetting.selected_bukkens = []
    MailMagazineSetting.toggleFilterByAddress();

    $(".mail-search-bukken").on('click', function () {
      if ($("[name='bukken_types[]'").is(':checked') == false) {
        toastr.error("必ず物件種別を選択してください！")
      } else {
        MailMagazineSetting.searchBukken()
      }
    });

    $(".btn-confirm").on('click', function () {
      MailMagazineSetting.selected_bukkens = $.merge([], MailMagazineSetting.bukkens);
      $(".modal-search-bukken").modal('hide')
      MailMagazineSetting.updateHiddenField()
      MailMagazineSetting.buildBukkenList()
      MailMagazineSetting.toggleTableBukken()
    })

    $(document).on('change', '#checkall', function() {
      const ckbox = $("input[name='cb_check_all_customer']");
      $('#table_setting_customer input[name="customer_id[]"]').prop('checked', ckbox.is(':checked'))
      $('#table_setting_customer input[name="customer_id[]"]').each(function() {
        MailMagazineSetting.cloneOrRemoveCheckboxField(this)
      })
      MailMagazineSetting.updateSendCount(this)
    })

    $(document).on('change', '#table_setting_customer input[name="customer_id[]"]', function() {
      MailMagazineSetting.cloneOrRemoveCheckboxField(this)
      MailMagazineSetting.updateSendCount(this)
    })

    $(".btn-mailcheck-confirm").on('click', function () {
      // NOTE: 絞り込みのパラメータを付与しないと空で保存されてしまうので、必ず行う
      MailMagazineSetting.appendSearchInput();

      const filterInputIds = ["member_from_in_filter", "membersince_to_filter", "membersince_from_filter"];
      let search_options = {};
      filterInputIds.forEach((input) => {
        if ($(`#${input}`).length > 0) {
          let inputName = input.replace("_filter","");
          search_options[inputName] = $(`#${input}`).val();
        }
      });

      const customer_ids = $('#mailmagazine_setting_mailcheck input[name="exclude_customers[]"]');
      if (customer_ids.length > 0 || !$.isEmptyObject(search_options)) {
        $('#table_setting_customer input[name="customer_id[]"]').remove()
        MailMagazineSetting.batchUpdateMailcheck(search_options);
      } else {
        $('#setting_customer').modal('hide')
      }
    })

    $("#send_mail_test").on('click', function () {
      if (MailMagazineSetting.validMailTest()) {
        MailMagazineSetting.sendMail()
      }
    })

    $("#submit_draft").on('click', function () {
      $('.modal-loading').modal('show')
      $("#mailmagazine_status").val('draft');
      $('#formMailmagazine').submit()
    })

    $("#submit_send_mail").on('click', function () {
      $('.modal-loading').modal('show')
      $("#mailmagazine_status").val('process');
      $('#formMailmagazine').submit()
    })

    $("#btn_setting_mailcheck").on('click', function() {
      MailMagazineSetting.getCustomerList(true)
    })

    $(".btn_search_customer").on('click', function() {
      MailMagazineSetting.searchCustomer();
    })

    $("#btn_clear_search").on('click', function() {
      $("#mail_address").val('');
      $("#membersince_from").val('');
      $("#membersince_to").val('');
      $(".member_from_in[type=checkbox]").prop('checked', false);
      $('#mailmagazine_setting_mailcheck input[name="exclude_customers[]"]').remove();
      MailMagazineSetting.searchCustomer();
    })

    $(".btn_search_mail_address").on('click', function() {
      MailMagazineSetting.searchCustomer();
    });

    $("#mail_address").keypress(function(e){
      // Enter押しても検索
      if (e.keyCode == 13) {
        $(".btn_search_mail_address").click();
        return false;
      }
    });

    $("#release_date").change(function() {
        if (this.checked) {
          $('#release_date_from').val(moment().subtract(7, 'days').format("YYYY-MM-DD"));
          $('#release_date_to').val(moment().format("YYYY-MM-DD"));
        } else {
          $('.input-datepicker').val('');
        }
    });

    $('.input-datepicker').change(function(event){
      if ($.trim(event.target.value) !== '') {
        $("#release_date").prop('checked', false);
      }
    });

    if (/mail_magazine\/[0-9]+\/edit/.test(window.location.href)) {
      MailMagazineSetting.getBukkenAssign();
    } else {
      MailMagazineSetting.toggleTableBukken();
    }
  },

  searchCustomer: function () {
    MailMagazineSetting.appendSearchInput();
    MailMagazineSetting.getCustomerList(false)
    MailMagazineSetting.updateCustomerListCheckbox();
  },

  cloneOrRemoveCheckboxField: (item) => {
    if (item.checked) {
      $(".customer_id_" + item.value).remove()
    } else {
      const input = $(item).clone().attr('type', 'hidden').attr('name', 'exclude_customers[]').attr('class', 'customer_id_' + item.value)
      input.appendTo( "#mailmagazine_setting_mailcheck" );
    }
  },

  updateSendCount: function () {
    const customersCount = $("#customers_not_excluded_count").val()
    const exclude_customers = $('#mailmagazine_setting_mailcheck input[name="exclude_customers[]"]').map(function() {
      return $(this).val();
    }).get().sort()
    const checkOffCount = $.unique(exclude_customers).length
    const newSendCount = customersCount - checkOffCount
    $(".send-count").html(newSendCount)
  },

  batchUpdateMailcheck: (search_options) => {
    const form = $('#mailmagazine_setting_mailcheck')
    const url_update_mailcheck = form.attr('action');
    const mail_magazine_id = $("#mailmagazine_mail_magazine_id").val();

    $('#setting_customer').modal('hide')
    $('.modal-loading').modal('show')
    $.ajax({
      url: url_update_mailcheck,
      type : 'POST',
      data : form.serialize() + `&mail_magazine_id=${mail_magazine_id}` + `&${$.param(search_options)}`,
      success: function (response) {
        $('.modal-loading').modal('hide')
        toastr.success('送信対象を設定しました')
      },
      error: function (request, status, error) {
        handleAjaxError(request, status, error);
      }
    })
  },

  getCustomerList: (showLoading) => {
    const mail_address = $("#mail_address").val();
    const membersince_from = $("#membersince_from").val();
    const membersince_to = $("#membersince_to").val();
    const member_from_in = $(".member_from_in:checked").map(function() {
      return $(this).val();
    }).get().join(',');
    const exclude_customers = $('#mailmagazine_setting_mailcheck input[name="exclude_customers[]"]').map(function() {
      return $(this).val();
    }).get().join(',');

    if (showLoading) {
      $('.modal-loading').modal('show')
    }

    let data = {}
    if ($.trim(mail_address) !== '') {
      data.mail_address = mail_address;
    }
    data.is_search = true;

    if (membersince_from) data.membersince_from = membersince_from;
    if (membersince_to) data.membersince_to = membersince_to;
    if (member_from_in) data.member_from_in = member_from_in;
    if (exclude_customers) data.exclude_customers = exclude_customers;

    $.ajax({
      url: '/kanri/mail_magazine/customers',
      type : 'GET',
      data : data,
      success: function (response) {
        if (showLoading) {
          $('.modal-loading').modal('hide')
          $('#setting_customer').modal('show')
        }
      },
      error: function (request, status, error) {
        handleAjaxError(request, status, error);
      }
    })
  },

  getBukkenAssign: function () {
    const id = window.location.href.match(/\/[0-9]+\//)[0].replaceAll("/", "");
    $('.modal-loading').modal('show')

    $.ajax({
      url: '/kanri/mail_magazine/assign_bukken',
      type : 'GET',
      data : { id: id },
      success: function (response) {
        if (response.bukkens.length){
          response.bukkens.forEach(function (bukken) {
            MailMagazineSetting.bukkens.push({
              model: bukken.model,
              id: bukken.id,
              bukken_id: bukken.bukken_id,
              price: bukken.price,
              release_date: moment(bukken.release_date).format("YYYY/MM/DD"),
              bukken_name: bukken.bukken_name,
              bukken_title: bukken.bukken_title,
              bukken_type: bukken.bukken_type,
              detail_path: bukken.detail_path
            });
          });

          MailMagazineSetting.selected_bukkens = $.merge([], MailMagazineSetting.bukkens);
          MailMagazineSetting.buildBukkenList()
        }

        MailMagazineSetting.updateHiddenField()
        MailMagazineSetting.toggleTableBukken()
        $('.modal-loading').modal('hide')
      },
      error: function (request, status, error) {
        handleAjaxError(request, status, error);
      }
    })
  },

  sendMail: function () {
    const form = $('#mailmagazine_send_mail')
    const url_send_mail = form.attr('action');
    const mail_magazine_id = $('#mailmagazine_mail_magazine_id').val();
    const title = $('#mailmagazine_title').val();
    const greeting = encodeURIComponent(CKEDITOR.instances.mailmagazine_greeting.getData()) // iframe内のテキストを取得 + エンコードかける
    let ids = [];
    MailMagazineSetting.selected_bukkens.map(element => ids.push(`${element.model}-${element.id}`))

    $('.modal-loading').modal('show')
    $.ajax({
      url: url_send_mail,
      type : 'POST',
      data : form.serialize() + `&ids_bukken=${ids.join(',')}&title=${title}&greeting=${greeting}&mail_magazine_id=${mail_magazine_id}`,
      success: function (response) {
        $('.modal-loading').modal('hide')
        toastr.success('送信しました。')
      },
      error: function (request, status, error) {
        handleAjaxError(request, status, error);
      }
    })
  },

  searchBukken: function (page) {
    const form_search = $('#mailmagazine_search_bukken')
    const url_search = form_search.attr('action');
    let data = form_search.serialize()
    let pageQuery = page ? '&page=' + page : ''
    $("#table_search_bukkens").html('<tbody><tr><td class="text-center">検索中</td></tr></tbody>');
    $.ajax({
      url: url_search,
      type : 'GET',
      data : data + pageQuery,
      success: function () {
        $('.modal-loading').modal('hide')
        MailMagazineSetting.addBukken();
        MailMagazineSetting.toggleBtnAddBukken();
        MailMagazineSetting.paginateSearchBukken();
      },
      error: function (request, status, error) {
        handleAjaxError(request, status, error);
      }
    })
  },

  toggleBtnAddBukken: function () {
    $(".mail-add-bukken").each(function() {
      let bukken_id = $(this).data('id')
      let bukken_type = $(this).data('bukken-type')

      if (MailMagazineSetting.checkBukkenExist(bukken_id, bukken_type)) {
        $(this).addClass('bgcolor-dusty-gray').text('追加済').show()
      } else {
        $(this).addClass('bgcolor-gray').text('追加する').show()
      }
    })
  },

  addBukken: function () {
    $(".mail-add-bukken").on('click', function () {
      let bukken_id = $(this).data('id')
      let bukken_type = $(this).data('bukken-type')

      if(MailMagazineSetting.checkBukkenExist(bukken_id, bukken_type)){
        $(this).removeClass('bgcolor-dusty-gray').addClass('bgcolor-gray').text('追加する');
        MailMagazineSetting.bukkens = MailMagazineSetting.bukkens.filter(bukken => bukken.id !== bukken_id)
      } else {
        $(this).removeClass('bgcolor-gray').addClass('bgcolor-dusty-gray').text('追加済');
        MailMagazineSetting.bukkens.push({
          model: $(this).data('model'),
          id: bukken_id,
          price: $(this).data('price'),
          release_date: $(this).data('release-date'),
          bukken_name: $(this).data('name'),
          bukken_title: $(this).data('bukken-title'),
          bukken_type: $(this).data('bukken-type'),
          detail_path: $(this).data('url')
        });
      }
    });
  },

  paginateSearchBukken: function () {
    // 全てのページリンクに対して、data-pageを付与しつつ、検索可能なクラスに置き換え
    $("#paginate_search_bukkens").find('a').each(function() {
      var url = new URL($(this).attr('href'), window.location.origin);
      var params = url.searchParams;
      var page
      if( params.has('page') ) {
        page = params.get('page')
      } else {
        page = ''
      }
      $(this).attr('data-page', page);
      $(this).attr('href', 'javascript:void(0)');
      $(this).addClass('paginate-mail-search-bukken');
    })
    $(".paginate-mail-search-bukken").on('click', function () {
      if ($("[name='bukken_types[]'").is(':checked') == false) {
        toastr.error("必ず物件種別を選択してください！")
      } else {
        var page = $(this).data('page')
        MailMagazineSetting.searchBukken(page)
      }
    });
  },

  buildBukkenList: function () {
    let toggle_address = '物件名'
    let html = '<thead><tr><td>物件ID</td><td>情報公開日</td><td>'+toggle_address+'</td><td>価格</td><td></td><td></td></tr></thead>'
    html += '<tbody>'
    $.each(MailMagazineSetting.selected_bukkens, function(index, bukken) {
      html += '<tr>'
      html += '<td class="text-left">'+bukken.id+'</td>'
      html += '<td class="text-left">'+bukken.release_date+'</td>'
      html += '<td class="text-left">'+bukken.bukken_name+'</td>'
      html += '<td class="text-left color-chestnut-rose font-weight-bold">'+bukken.price+'</td>'
      html += '<td class="text-left"><a target="_blank" class="link__link" href = "'+bukken.detail_path+'">詳細</a></td>'
      html += '<td class="text-right"><a class="link__delete remove-bukken" data-id="'+bukken.id+'"><i class="fa fa-trash"></i></a></td>'
      html += '</tr>'
    });
    html += '</tbody>'

    MailMagazineSetting.updateBukkenTypeNames();

    $('#table_add_bukkens').html(html)
    $('#total_bukkens').text(MailMagazineSetting.selected_bukkens.length)
    MailMagazineSetting.removeBukken()
  },

  updateBukkenTypeNames: function () {
    let bukken_titles = []
    if (MailMagazineSetting.isExistTitle('大分新築戸建')) { bukken_titles.push('大分新築戸建') }
    if (MailMagazineSetting.isExistTitle('大分中古戸建')) { bukken_titles.push('大分中古戸建') }
    if (MailMagazineSetting.isExistTitle('大分中古マンション')) { bukken_titles.push('大分中古マンション') }
    if (MailMagazineSetting.isExistTitle('大分土地')) { bukken_titles.push('大分土地') }
    if (MailMagazineSetting.isExistTitle('大分収益物件')) { bukken_titles.push('大分収益物件') }
    if (MailMagazineSetting.isExistTitle('大分リゾート物件')) { bukken_titles.push('大分リゾート物件') }
    if (MailMagazineSetting.isExistTitle('福岡新築戸建')) { bukken_titles.push('福岡新築戸建') }
    if (MailMagazineSetting.isExistTitle('福岡土地')) { bukken_titles.push('福岡土地') }
    $('#bukken_titles').text(bukken_titles.join('、'))
  },

  isExistTitle: function (bukken_title) {
    const bukkens = MailMagazineSetting.selected_bukkens

    return bukkens.filter(function(bukken) { return bukken.bukken_title == bukken_title; }).length !== 0
  },

  removeBukken: function () {
    $('.remove-bukken').on('click', function () {
      const id = $(this).data('id')
      MailMagazineSetting.selected_bukkens = MailMagazineSetting.selected_bukkens.filter(bukken => bukken.id !== id)
      MailMagazineSetting.bukkens = $.merge([], MailMagazineSetting.selected_bukkens);
      $(this).parent( "td" ).parent( "tr" ).remove()
      $('#total_bukkens').text(MailMagazineSetting.selected_bukkens.length)
      MailMagazineSetting.toggleTableBukken()
      MailMagazineSetting.toggleBtnAddBukken()
      MailMagazineSetting.updateHiddenField();
      MailMagazineSetting.updateBukkenTypeNames();
    });
  },

  toggleTableBukken: function () {
    if (MailMagazineSetting.selected_bukkens.length) {
      if (!$(".bukken-register").hasClass('active')) {
        $(".bukken-register").addClass('active');
      }
    } else {
      $(".bukken-register").removeClass('active');
    }
  },

  checkBukkenExist: function (bukken_id, bukken_type) {
    if (MailMagazineSetting.bukkens.filter(bukken => bukken.id === bukken_id && bukken_type === bukken.bukken_type ).length)
      return true
    else {
      return false
    }
  },

  updateHiddenField: function () {
    let ids = [];
    MailMagazineSetting.selected_bukkens.map(bukken => ids.push(`${bukken.model}-${bukken.id}`))
    $("#mailmagazine_ids_bukken").val(ids.join(','));
  },

  toggleFilterByAddress: function () {
    $("#mail_bukken_type").on('change', function() {
      if (this.value === 'FukuokaBukken') {
        $(".wrap-bukken-name").find('label').text('物件所在地')
        $(".wrap-bukken-name").find('input').attr('placeholder', '物件所在地を入力してください')
      } else {
        $(".wrap-bukken-name").find('label').text('物件名')
        $(".wrap-bukken-name").find('input').attr('placeholder', '物件名を入力してください')
      }

      if (!MailMagazineSetting.selected_bukkens.length) {
        MailMagazineSetting.bukkens = []
      }

      $("#table_search_bukkens").html('<tbody><tr><td class="text-center">検索条件を指定すると物件が表示されます。</td></tr></tbody>');
    });
  },

  validMailTest: function () {
    let msg = ''
    if ($("#email").val() === '') {
      msg = "メールアドレスを入力してください"
    }

    if (msg !== ''){
      toastr.error(msg)
      return false
    }

    return true
  },

  appendSearchInput: () => {
    const searchInputValues = {
      "member_from_in": $(".member_from_in:checked").map(function() {
          return $(this).val();
        }).get().join(','),
      "membersince_to": $('#membersince_to').val(),
      "membersince_from": $('#membersince_from').val(),
      "mail_address": $('#mail_address').val(),
      "exclude_customers": $('#mailmagazine_setting_mailcheck input[name="exclude_customers[]"]').map(function() {
          return $(this).val();
        }).get().join(',')
    };

    $.each(searchInputValues, function(input, value) {
      $(`#${input}_filter`).remove();

      $('<input>').attr({
        type: 'hidden',
        id: `${input}_filter`,
        name: `mailmagazine[customer_filter][${input}]`,
        value: value,
      }).appendTo('#formMailmagazine');
    })
  },

  updateCustomerListCheckbox: () => {
    const customerForm = document.querySelector('#mailmagazine_setting_mailcheck');
    const uncheckedList = customerForm.querySelectorAll('input[name="exclude_customers[]"]');
    if (uncheckedList.length === 0) return;

    uncheckedList.forEach(checkbox => document.getElementById(`customer_id_${checkbox.value}`).checked = false);
  },
}

export default MailMagazineSetting;
