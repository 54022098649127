import {handleAjaxError} from "../handle_error";

const Stepmail = {
  init: function () {
    Stepmail.isValidFormStepmail();
    Stepmail.submitForm();
    Stepmail.setValInputStepmailTitle();
    Stepmail.setValInputStepmailBody();
    Stepmail.sendMail();
    Stepmail.toggleBtnSendMail();
  },

  isValidFormStepmail: function () {
    const inputs = $('.input-required');
    return Stepmail.isValid(inputs)
  },

  isValid: function (input_require) {
    let rs = true, names = [];

    for (let i=0; i < input_require.length; i++) {
      if ($(input_require[i]).val().trim().length === 0) {
        rs = false;
        names.push($(input_require[i]).data('validate-name'))
      }
    }
    if (rs === false) {
      names = names.filter(function(item, i, ar){ return ar.indexOf(item) === i; })
      $('.list-error-messages span.fields').html(names.join('、'));
    }
    return rs;
  },

  submitForm: function () {
    $('.submit-form-mail').on('click', function () {
      if (Stepmail.isValidFormStepmail()) {
        $('.modal-loading').modal('show')
        $('#formStepmail').submit()
      } else {
        Stepmail.showErrorMsg()
      }
    })
  },

  setValInputStepmailTitle: function () {
    $('.sl-mail-title').on('change', function () {
      Stepmail.setValue($('.mail-title'), $(this).val())
      // reset select title
      $('.sl-mail-title').val('')
    })
  },

  setValInputStepmailBody: function () {
    $('.sl-mail-body').on('change', function () {
      Stepmail.setValue($('.mail-body'), $(this).val())
      // reset select title
      $('.sl-mail-body').val('')
    })
  },

  setValue: function (input_filed, old_value) {
    const value = input_filed.val() + old_value
    input_filed.val(value)
  },

  isValidSendMail: function () {
    const inputs = $('.required-send-mail');
    return Stepmail.isValid(inputs)
  },

  sendMail: function () {
    $('.send-mail').on('click', function () {

      const form = $('#formStepmail')
      const url = $(this).data('url-send-mail')
      let param = form.serializeArray();
      param.push({name: '_method', method: 'POST'})

      if (Stepmail.isValidSendMail()) {
        toastr.remove()
        $('.modal-loading').modal('show')
        $.ajax({
          url: url,
          type: 'POST',
          data: param,
          success: function () {
            $('.modal-loading').modal('hide')
            toastr.success('テストメールを送信しました。')
          },
          error: function (request, status, error) {
            handleAjaxError(request, status, error);
          }
        })
      } else {
        Stepmail.showErrorMsg()
      }
    })
  },

  toggleBtnSendMail: function () {
    $('#mail-to').on('keyup', function () {
      $('.send-mail').attr('disabled', $('#mail-to').val().length === 0 )
    })
  },

  showErrorMsg: function () {
    toastr.remove()
    const erros = $('.list-error-messages').html()
    toastr.error(erros)
  }
}

export default Stepmail;
