Scenarios = {
  init: function () {
    Scenarios.searchCategory();
    Scenarios.sortTable();
  },

  searchCategory: function () {
    $('#scenarios_category').on('change', function () {
      const form = $('#formSearchScenarios')
      $('#scenarios_category_sort').val($(this).val());
      // show loading
      $('.modal-loading').modal('show')

      $.ajax({
        url: form.attr('action'),
        type: 'GET',
        data: form.serialize(),
        success: function () {
          $('.modal-loading').modal('hide')
          Scenarios.sortTable();
          $('#ids_change_order').val("");
          $("#infoArea").html("");
          $('#update_sort').hide();
        }
      })
    })
  },

  sortTable: function () {
    const stable = $("#scenarios_sortable")
    stable.sortable({
      placeholder: 'ui-state-highlight',
      revert: (true, 100),
      scroll: true,
      helper: function(event, ui) {
        // adjust placeholder td width to original td width
        ui.children().each(function() {
          $(this).width($(this).width());
        });
        return ui;
      },
      update: function(table, ui) {
        var rows = table.target.rows;
        var itemStr = "";
        var infoStr = ""+ui.item[0].id+"がドラッグ&ドロップされました。";
        for (var i=0; i<rows.length; i++) {
          if (i == rows.length - 1) {
            itemStr += rows[i].id;
          } else {
            itemStr += rows[i].id+",";
          }
        }
        $("#infoArea").html(infoStr);
        $('#ids_change_order').val(itemStr);
        $('#update_sort').show();
      },
      start: function(e, ui) {
        $("#infoArea").html("ドラッグが開始された行："+ ui.item[0].id);
        ui.placeholder.height(ui.helper.outerHeight());
      },
    });
    stable.disableSelection();
  }
}
