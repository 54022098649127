const User = {
  init: function () {
    User.isValidForm();
    User.submitForm();
    User.showLoading();
  },

  isValidForm: function () {
    const input_require = $('.input-required');

    let rs = true, names = [];

    for (let i=0; i < input_require.length; i++) {
      if ($(input_require[i]).val().trim().length === 0) {
        rs = false;
        names.push($(input_require[i]).data('validate-name'))
      }
    }
    if (rs === false) {
      names = names.filter(function(item, i, ar){ return ar.indexOf(item) === i; })
      $('.list-error-messages span.fields').html(names.join('、'));
    }
    return rs;
  },

  submitForm: function () {
    $('.submit-form-user').on('click', function () {
      if (User.isValidForm()) {
        $('#form_create_stepmail_user').modal('hide');
        $('.modal-loading').modal('show')

        $('#formSuser').submit()
      } else {
        User.showErrorMsg()
      }
    })
  },

  showErrorMsg: function () {
    toastr.remove()
    const erros = $('.list-error-messages').html()
    toastr.error(erros)
  },

  showLoading: function () {
    $('.table-user').on('click', '.show-betsudai-loading', function(){
      $('.modal-loading').modal('show');
    })
  }
}
export  default  User;
