var Voice = {
  init: function() {
    Voice.changeStaff();
  },


  changeStaff: function () {
    $('#q_apartment_staff_id_eq').on('change', function () {
      Voice.searchVoice();
    })
  },

  searchVoice: function () {
    $('form#voice_search_form input#voice_search_btn').trigger('click');
  },
}

export default Voice;