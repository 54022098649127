import {handleAjaxError} from "../handle_error";
import {handleDownloadCSV} from "../download_csv";

var Customer = {
  init: function() {
    Customer.assignStaff();
    Customer.sort();
    Customer.changeLimit();
    Customer.exportCSV();
    Customer.searchBtnClick();
    Customer.resetCustomerSearchForm();
    Customer.handleModal();
    Customer.updateMultipleStaff();
    Customer.handleCheckAll();
    Customer.handleAccordion();
    Customer.handleCollapseContent();
    Customer.initSelectStaff();
  },
  initSelectStaff: function () {
    $('.customer-sale-table .assign-staff-area .select-customer-staff').select2({ width: '180px' }).on('select2:open', function(e) {
      $('input.select2-search__field').prop('placeholder', '担当者名を入力してください');
    });

    $('.customer-contents .select-customer-staff').select2({ width: '180px' }).on('select2:open', function(e) {
      $('input.select2-search__field').prop('placeholder', '担当者名を入力してください');
    });
  },
  assignStaff: function(){
    $('.betsudai-table').on('click', '.customer-assign-staff', function(){
      var $that = $(this);
      var customer_type = $that.closest('.assign-staff-area').find('.select-customer-staff').data('customer-type')
      var staff_id = $that.closest('.assign-staff-area').find('.select-customer-staff').val();
      if (customer_type == 'rental') {
        var data = { apartment_staff_id: staff_id }
      } else {
        var data = { staff_id: staff_id }
      }
      $('.modal-loading').modal('show');
      $.ajax({
        url: $that.data('url'),
        data: data,
        method: 'POST',
        success: function(response) {
          if(response.result){
            toastr.success('担当者を変更しました。')
          }
          $('.modal-loading').modal('hide');
        },
        error: function (request, status, error) {
          handleAjaxError(request, status, error);
          $(this).prop('disabled', false);
        }
      })
    });
  },
  sort: function () {
    $('.betsudai-table').on('click', '.customer-sort', function () {
      const order = $(this).data('order');
      const sort_by = $(this).data('sort_by');
      $('.customer-sort').removeClass('active');
      $(this).addClass('active');
      $('#customer_search input#sort_customer').val(`${sort_by} ${order}`);
      Customer.searchCustomer();
    })
  },

  changeLimit: function () {
    $('#customer_limit').bind('change', function () {
      $('#customer_search input[name=per]').val($(this).val());
      Customer.searchCustomer();
    })
  },

  searchCustomer: function () {
    $('#customer_search button.submit').trigger('click');
  },

  exportCSV: function () {
    $('.customer-export-csv').on('click', function () {
      $('.modal-loading').modal('show');
      const url_csv = $(this).data('url')
      const form_search = $('#customer_search')
      let request_url = url_csv + '?' + form_search.serialize()
      handleDownloadCSV(request_url, "customer")
    })
  },

  searchBtnClick: function () {
    $('#customer_search button.submit').bind('click', function(){
      $('#customerFilterArea').modal('hide');
      $('.modal-loading').modal('show');
      $(this).data('search', true);
    });
  },

  resetCustomerSearchForm: function () {
    $('.btnResetCustomerSearchForm').on('click', function () {
      $('#customer_search')[0].reset();
      $('.default_mailcheck').prop('checked', true);
      $('.accordion-content').fadeOut(500);
    })
  },

  handleModal: function () {
    $('#customerFilterArea').on('shown.bs.modal', function (e) {
      if($('#customer_search button.submit').data('search') == false) {
        $('.default_mailcheck').prop('checked', true);
      }
    });

    $('#customerFilterArea').on('hidden.bs.modal', function (e) {
      if($('#customer_search button.submit').data('search') == false) {
        $('#default_mailcheck').prop('checked', false);
      }
    });
  },

  updateMultipleStaff: function(){
    $('.update-multi-staff-btn').bind('click', function(){
      $('#assign_all').modal('toggle');
      const form_update = $('#update-staff-form')
      const url_update = form_update.attr('action');
      const staff_id = form_update.find('#staff_id').val()
      const customer_id = form_update.find('#customer_id').val()
      $.ajax({
        url: url_update,
        data: { staff_id, customer_id },
        method: 'POST',
        success: function(response) {
          if(response.result){
            $('.modal-loading').modal('hide')
            $("select[name=customer_staff_id]").val(staff_id);
            toastr.success('担当者を一括変更しました。')
          }
        },
        error: function (request, status, error) {
          handleAjaxError(request, status, error);
        }
      })
    });
  },

  handleCheckAll: function () {
    $('.check-all-custom').bind('click', function(){
      var checked = false;
      var $that = $(this);
      if($that.data('checked') == false){
        checked = true;
      }
      $that.data('checked', checked);

      $($that.data('elements1')).prop('checked', checked);
      if ($('#acd-check1')[0].checked){
        $($that.data('elements2')).prop('checked', checked);
      }
    })
  },
  handleAccordion: function () {
    $('#acd-check1').change(function(){
      if (this.checked) {
        $('.accordion-content').fadeIn(500);
      } else {
        $('.accordion-content').fadeOut(500);
        $('.member-from2').prop('checked', false);
      }
    });
  },
  handleCollapseContent: function () {
    $(".title-pointer").click(function(){
      $(this).siblings(".collapse").eq(0).collapse("toggle");
    });
  },
}

export default Customer;
