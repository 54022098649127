import {handleDownloadCSV} from "../download_csv";
var ReleaseExpiredBukken = {
  init: function () {
    ReleaseExpiredBukken.exportCsv();
  },

  exportCsv: function () {
    $('.btnExportReleaseExpiredBukkenCsv').on('click', function () {
      $('.modal-loading').modal('show');
      const request_url = $(this).data('url-csv')
      handleDownloadCSV(request_url, "bukken")
    })
  },
}
export default ReleaseExpiredBukken;
