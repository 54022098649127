var Sortable = {
  init: function() {
    $(".sortable-block").sortable({
      placeholder: 'ui-state-highlight',
      revert: (true, 100),
      handle: ".drag_handle",
      scroll: true,
      helper: function(event, ui){
        // adjust placeholder td width to original td width
        ui.children().each(function(){
          $(this).width($(this).width());
        });
        return ui;
      },
      update: function(e, ui) {
        $(e.target).find(".order_no").each(function(index){
          $(this).val(index + 1)
        });
      },
      start: function(e, ui) {
        ui.placeholder.height(ui.helper.outerHeight());
        ui.item.css('box-shadow', '0 0 10px #000000')
      },
      stop: function(e, ui) {
        ui.item.css('box-shadow', 'none')

        // sortable後のiframeの中身がrefreshされるのを防ぐ
        if (ui.item.hasClass('squire')) {
          const id = ui.item.find('textarea').attr('id')
          if (typeof CKEDITOR != 'undefined' && id) {
            if (CKEDITOR.instances[id]) {
              CKEDITOR.instances[id].destroy();
            }
            if (ui.item.hasClass('coding')) {
              CKEDITOR.replace(id, {});
            }else {
              CKEDITOR.replace(id, {"language":"ja","toolbar":"mini","height":"10em","customConfig":"/ckeditor/config.js"});
            }
          }
        }
      },
    });
  },
  handleSubmit: function () {
    $('.locations-wrapper').each(function() {
      let sorted = $('.sortable-blocks').sortable('toArray');

      jQuery.each(sorted, function(i, val) {
        $('#' + val).find('input.order-field').val(i + 1);
      });
    });
  }
}
export default Sortable;
