import {handleAjaxError} from '../handle_error';

var EventGeneratorContact = {
  init: function() {
    EventGeneratorContact.sort();
    EventGeneratorContact.filter();
    EventGeneratorContact.changeStatus();
    EventGeneratorContact.showModalDetail();
  },

  sort: function () {
    $(document).on('click', '.created-sort', function () {
      const order = $(this).data('order');
      const sort_by = $(this).data('sort_by');
      $('#event_generator_contact').val(`${sort_by} ${order}`);
      $('.modal-loading').modal('show');
      EventGeneratorContact.searchEventGeneratorContact();
    })
  },

  filter: function () {
    $(document).on('click', '.event-generators-contact-status-filter', function() {
      $('.event-generators-contact-status-filter').removeClass('active');
      $(this).addClass('active');
      $('#event-generators-contact-status').val($(this).data('status'));
      $('.modal-loading').modal('show');
      EventGeneratorContact.searchEventGeneratorContact();
    });
  },

  changeStatus: function() {
    $(document)
      .on('focus', '.select-event-generator-contact-status', function () {
        $('.btn-confirm-status-event-generator-contact').attr('data-status-old', $(this).val())
      })
      .on('change', '.select-event-generator-contact-status', function () {
        $('.btn-confirm-status-event-generator-contact').attr('data-url', $(this).parent('.select').data('url'))
        $('.btn-confirm-status-event-generator-contact').attr('data-status', $(this).val())
        $('.btn-confirm-status-event-generator-contact').attr('data-id', $(this).parent('.select').attr('id'))
        $(this).val($('.btn-confirm-status-event-generator-contact').attr('data-status-old'));
        $('#modal_event_generator_contact_change_status').modal('show')
      });

    $(document).on('click', '.btn-confirm-status-event-generator-contact', function() {
      const status = $(this).attr('data-status');
      const url = $(this).attr('data-url');
      $.ajax({
        url: url,
        data: { status: status },
        method: 'PUT',
        success: function(response) {
          if(response.result) {
            let id = $('.btn-confirm-status-event-generator-contact').attr('data-id');
            let select = $('#'+id).find('select');
            select.removeClass('supported unsupported');
            select.addClass(status);
            select.val($('.btn-confirm-status-event-generator-contact').attr('data-status'));
            toastr.success('ステータスを更新しました。')
          }
        },
        error: function (request, status, error) {
          handleAjaxError(request, status, error);
        },
        complete: function () {
          $('#modal_event_generator_contact_change_status').modal('hide')
        }
      })
    });
  },

  showModalDetail: function() {
    $(document).on('click', '.btn-event-generator-contact-detail', function() {
      const url = $(this).attr('data-url');
      $('.modal-loading').modal('show');
      $.ajax({
        url: url,
        method: 'GET',
        success: function(response) {
          $('#modal_event_generator_contact_detail').modal('show');
          EventGeneratorContact.updateContactStatus()
          EventGeneratorContact.postComment();
          EventGeneratorContact.slideModal();

        },
        error: function (request, status, error) {
          handleAjaxError(request, status, error);
        },
        complete: function () {
          $('.modal-loading').modal('hide')
        }
      })
    });
  },

  slideModal: function() {
    $('.btn-generators-contact-next, .btn-generators-contact-previous').on('click', function() {
      const url = $(this).attr('data-url');
      $.ajax({
        url: url,
        method: 'GET',
        success: function(response) {
          EventGeneratorContact.updateContactStatus()
          EventGeneratorContact.postComment();
          EventGeneratorContact.slideModal();
        },
        error: function (request, status, error) {
          handleAjaxError(request, status, error);
        }
      })
    });
  },

  updateContactStatus: function() {
    $(document).on('change', '.change-inquiry-status', function() {
      const that = $(this);
      const status = that.val();
      const url = that.parent('.select').attr('data-url');
      const id = that.parent('.select').attr('data-id');
      $.ajax({
        url: url,
        data: { status: status },
        method: 'PUT',
        success: function(response) {
          if(response.result) {
            that.removeClass('supported unsupported');
            that.addClass(status);

            let select = $('#status_event_generator_contact_'+id).find('select');
            select.val(status).removeClass('supported unsupported').addClass(status);
            toastr.success('ステータスを更新しました。')
          }
        },
        error: function (request, status, error) {
          handleAjaxError(request, status, error);
        },
      })
    });
  },

  searchEventGeneratorContact: function () {
    $('#event_generator_contact_search_form input.btn-search').trigger('click');
  },
  postComment: function() {
    $('.btn-submit-generators-contact-comment').css('opacity', '0.5').attr('disabled', 'disabled');
    $(document).on('keyup', '#generators_contact_comment', function() {
      if ($.trim($(this).val())) {
        $('.btn-submit-generators-contact-comment').css('opacity', '1').removeAttr('disabled');
      } else {
        $('.btn-submit-generators-contact-comment').css('opacity', '0.5').attr('disabled', 'disabled');
      }
    });

    $('.btn-submit-generators-contact-comment').on('click', function() {
      const comment = $('#generators_contact_comment').val();
      const url = $(this).attr('data-url');
      const contactType = $(this).attr('data-type');
      const contactId = $(this).attr('data-id');
      if ($.trim(comment)) {
        $.ajax({
          url: url,
          data: {
            comment: comment,
            contact_comment_type: contactType,
            contact_comment_id: contactId
          },
          method: 'POST',
          success: function (response) {
            if (response) {
              toastr.success('コメントしました。')
            }
            $('#generators_contact_comment').val('');
            $('#event_generator_contact_body').scrollTop($('#event_generator_contact_body')[0].scrollHeight);
          },
          error: function (request, status, error) {
            handleAjaxError(request, status, error);
          },
        })
      }
    });
  },
};

export default EventGeneratorContact;
