import Sortable from "./sortable";
import {handleAjaxError} from "../handle_error";

var ConstructionCase = {
  init: function() {
    this.start();
    this.handleDropImages();
    this.deleteImage()
    this.updateStatus()
    this.showBhImages()
  },
  start: function() {
    if (! $('.form-construction-case').length) {
      return
    }

    $("#btn_construction_image").bind('click', function () {
      $("#construction_image").modal('show')
    })

    this.initSelect2()
    this.displayDataSelected()
    this.handleSubmit()
    this.handleKindCheckbox()
    this.handleSort()
    this.addNewTag()
    this.confirmRemoveTag()
    this.fillDisabledTags()
    this.handleInputChange()
  },
  initSelect2: function () {
    this.setElementSelect2()

    $('.add-block-btn').click(function () {
      setTimeout(function () {
        ConstructionCase.setElementSelect2()
      }, 100)
    })
  },
  setElementSelect2: function () {
    let option = {
      placeholder: 'タグを設定',
      language: {
        noResults: function() {
          $('.btn-dynamic-add-tag').remove()
          ConstructionCase.appendButtonAddNewTag()
          ConstructionCase.addNewTag()
          $('.select2-dropdown--above').hide()
          $('.select2-dropdown--below').hide()
        },
        searching: function () {
          $('.btn-dynamic-add-tag').remove()
          ConstructionCase.appendButtonAddNewTag()
          ConstructionCase.addNewTag()
          $('.select2-dropdown--above').show()
          $('.select2-dropdown--below').show()
        },
      },
      escapeMarkup: function(markup) {
        return markup;
      },
      templateResult: (data) => {
        var option = $(`<div class="select-option-wrapper">
                            <p class="tag-option">${data.text}</p>
                           </div>`)
        var optionRemove = $(`
        <p class="remove-tag" data-tag-id="${data.id}">
                                <i class="fa fa-times-circle" style="font-size: 20px"></i>
                            </p>
        `)

        optionRemove.on('mouseup', function (evt) {
          const tagId = $(this).attr('data-tag-id')
          $.ajax({
            url: `/kanri/tags/${tagId}/check_tag_used`,
            method: 'GET',
            success: function(response) {
              if (response.result.length > 0) {
                $('.delete-tag-image-btn').attr('data-tag-id', tagId)
                $('#delete_image_tag').modal('show')
              } else {
                ConstructionCase.deleteTag(tagId)
              }
            },
            error: function (request, status, error) {
              handleAjaxError(request, status, error);
            },
          })
          evt.preventDefault()
          evt.stopPropagation();
        });

        option.text(data.text);
        option.append(optionRemove);

        return option;
      }
    }

    $('.construction-image-tags').select2(option)
    $('.images-block-field-tags').select2(option)
    $('.image-block-field-tags').select2(option)
  },
  displayDataSelected: function () {
    // show selected value of construction image tags
    $('.construction-image-tags').each(function (index, item) {
      let constructionImageTagsString = $(item).parent().children('.construction-image-tag-ids-hidden').val()
      let constructionImageTags = JSON.parse(constructionImageTagsString)

      $(item).val(constructionImageTags).change()
    })

    // show selected value of image desc block tags
    $('.images-block-field-tags').each(function (index, item) {
      let imageDescBlockTagsString = $(item).parent().children('.image-desc-block-field-tag-ids-hidden').val()
      let imageDescBlockTags = JSON.parse(imageDescBlockTagsString)

      $(item).val(imageDescBlockTags).change()
    })

    // show selected value of image block tags
    $('.image-block-field-tags').each(function (index, item) {
      let imageBlockFieldTagsString = $(item).parent().children('.image-block-field-tag-ids-hidden').val()
      let imageBlockFieldTags = JSON.parse(imageBlockFieldTagsString)

      $(item).val(imageBlockFieldTags).change()
    })
  },
  handleKindCheckbox: function () {
    let standardHouseSelector = $('.standard-house-check')
    let customHouseSelector = $('.custom-house-check')

    standardHouseSelector.click(function () {
      if (customHouseSelector.is(':checked')) {
        customHouseSelector.prop('checked', false);
      }
    })

    customHouseSelector.click(function () {
      if (standardHouseSelector.is(':checked')) {
        standardHouseSelector.prop('checked', false);
      }
    })
  },
  handleSubmit: function () {
    $('.submit-preview').click(function () {
      $('#submit_action').val('save-preview')
    })
  },
  handleSort: function () {
    $(document).on('submit', '.form-construction-case', function(event) {
      Sortable.handleSubmit()
    });
  },
  handleDropImages: function () {
    let preloaded = ConstructionCase.findImagesUrl()
    $('.construction-case-images').imageUploader({
      label: '一括登録する画像をここにドラック & ドロップしてください',
      imagesInputName: '',
      preloaded: preloaded,
      extensions: ['.jpg', '.jpeg', '.png'],
      mimes: ['image/jpeg', 'image/png'],
    })
  },
  deleteImage: function () {
    $('#construction-case-form .image-prev .delete-image').click(function (e) {
      e.preventDefault()
      let indexOfItem = $(this).attr('data_index')
      let parentItem = $(this).closest('.bh-image-item')
      let inputDestroyItem = `<input type="hidden" value="true" name="betsudai_home_bh_construction_case[bh_images_attributes][${indexOfItem}][_destroy]">`
      parentItem.parent().append(inputDestroyItem)
      $(this).closest('.bh-image-item').remove()
      $(`.uploaded-image[data-index="${indexOfItem}"]`).remove();


      if (!$('.uploaded-image').length) {
        $('.image-uploader').removeClass('has-files')
      }

      ConstructionCase.showBhImages()
    })
  },
  findImagesUrl: function () {
    let result = []
    $('.bh-images-hidden').each(function () {
      result.push({
        id: $(this).attr('data-index'),
        src: $(this).val()
      })
    })

    return result
  },
  findListTags: function () {
    let tags = []

    $('label.bh-tags-value').each(function () {
      tags.push({
        id: $(this).children('input').val(),
        value: $(this).children('span').text(),
      })
    })

    return tags
  },
  findListImageTags: function () {
    let tags = []

    $('.construction-image-tags').first().children().each(function () {
      tags.push({
        id: $(this).val(),
        value: $(this).text(),
      })
    })

    return tags
  },
  addNewTag: function () {
    $('.btn-dynamic-add-tag').click(function (e) {
      let btnElem = this
      const valTag = $(this).parent().children('input').val()
      const tagType = 'image'

      $.ajax({
        url: '/kanri/tags',
        data: {
          'tag[name]': valTag,
          'tag[tag_type]': tagType,
        },
        method: 'POST',
        dataType: 'json',
        success: function(response) {
          let tagID = response.tags.id
          let tagName = response.tags.name
          let newOption = $("<option></option>").val(tagID).text(tagName)

          $('.construction-image-tags').append(newOption).trigger('change')
          $('.images-block-field-tags').append(newOption).trigger('change')
          $('.image-block-field-tags').append(newOption).trigger('change')

          $(btnElem).closest('.form-group').children('.construction-image-tags').children(`option[value=${tagID}]`).remove()
          $(btnElem).closest('.form-group').children('.images-block-field-tags').children(`option[value=${tagID}]`).remove()
          $(btnElem).closest('.form-group').children('.image-block-field-tags').children(`option[value=${tagID}]`).remove()

          let newOptionSelected = $("<option selected='selected'></option>").val(tagID).text(tagName)
          $(btnElem).closest('.form-group').children('.construction-image-tags').append(newOptionSelected).trigger('change')
          $(btnElem).closest('.form-group').children('.images-block-field-tags').append(newOptionSelected).trigger('change')
          $(btnElem).closest('.form-group').children('.image-block-field-tags').append(newOptionSelected).trigger('change')

          $(btnElem).parent().children('input').val(null)
          $(btnElem).remove()
        },
        error: function (request, status, error) {
          if (request.status === 422) {
            return toastr.error('タグ名が既に存在します')
          }
          handleAjaxError(request, status, error);
        }
      })
    })
  },
  confirmRemoveTag: function () {
    $('.delete-tag-image-btn').on('click', function(e){
      const tagId = $(this).attr('data-tag-id')
      ConstructionCase.deleteTag(tagId)
    })
  },
  appendButtonAddNewTag: function () {
    $('.select2-search__field').each(function () {
      if ($(this).val().length !== 0) {
        let btn = `<button type="button" class="btn-dynamic-add-tag">
                       <i class="fa fa-plus-circle"></i>
                    </button>`
        $(this).parent().append(btn)
      }
    })
  },
  findTagNameByTagId: function (tagId) {
    return $(`.construction-image-tags option[value=${tagId}]`).first().text()
  },
  handleTagListChange: function () {
    $('.btn-new-tag, .btn-update-tag, .btn-delete-tag').click(function () {
      setTimeout(function () {
        ConstructionCase.updateListTagSelect()
        ConstructionCase.setElementSelect2()
      }, 500)
    })
  },
  updateListTagSelect: function () {
    let tags = ConstructionCase.findListTags()
    let tagOptions = tags.reduce(function (result, item) {
      return result + `<option value="${item.id}">${item.value}</option>`
    }, '')

    $('.construction-image-tags').html(tagOptions)
    $('.images-block-field-tags').html(tagOptions)
    $('.image-block-field-tags').html(tagOptions)
  },
  fillDisabledTags: function () {
    $('.input-select-tags').each(function () {
      let tagIds = $(this).val()

      if (tagIds.length) {
        let index = $(this).attr('data_index')
        let tagNames = tagIds.map(item => ConstructionCase.findTagNameByTagId(item))
        $(this).closest('.block-box').find(`.input-select-tags-disabled[data_index=${index}]`).val(tagNames)
      }
    })
  },
  handleInputChange: function () {
    $('.apply-btn').click(function () {
      setTimeout(function () {
        ConstructionCase.fillDisabledTags()
      }, 50)
    })
  },
  updateStatus: function() {
    const btnConfirmStatusSelector = $('.btn-confirm-status-construction-case')

    $('.select-construction-case-status')
      .on('focus', function () {
        btnConfirmStatusSelector.attr('data-status-old', $(this).val())
      })
      .on('change', function () {
        btnConfirmStatusSelector.attr('data-url', $(this).parent('.select').data('url'))
        btnConfirmStatusSelector.attr('data-status', $(this).val())
        btnConfirmStatusSelector.attr('data-id', $(this).parent('.select').attr('id'))
        $(this).val(btnConfirmStatusSelector.attr('data-status-old'));
        $('#modal_construction_case_change_status').modal('show')
      })

    btnConfirmStatusSelector.on('click', function(){
      const status = $(this).attr('data-status')
      const url = $(this).attr('data-url')
      $.ajax({
        url: url,
        data: { status: status },
        method: 'POST',
        success: function(response) {
          if(response.result) {
            let id = btnConfirmStatusSelector.attr('data-id');
            let select = $('#'+id).find('select')
            select.val(btnConfirmStatusSelector.attr('data-status'));
            toastr.success('公開ステータスを更新しました。')
          }
        },
        error: function (request, status, error) {
          handleAjaxError(request, status, error);
        },
        complete: function () {
          $('#modal_construction_case_change_status').modal('hide')
        }
      })
    });
  },
  showBhImages: function () {
    $('.bh-image').empty()
    $('.image-uploader .uploaded').children().clone().appendTo('.bh-image')
  },
  deleteTag: function (tagId) {
    $.ajax({
      url: `/kanri/tags/${tagId}`,
      method: 'DELETE',
      data: {
        'tag[tag_type]': 'image'
      },
      success: function(response) {
        $('.btn-dynamic-add-tag').remove()
        $(`.remove-tag[data-tag-id="${tagId}"]`).closest('li').remove()
        $('.images-block-field-tags').children(`option[value=${tagId}]`).remove()
        $('.images-block-field-tags').trigger('change')
        $('.construction-image-tags').children(`option[value=${tagId}]`).remove()
        $('.construction-image-tags').trigger('change')
        $('.image-block-field-tags').children(`option[value=${tagId}]`).remove()
        $('.image-block-field-tags').trigger('change')
        $('#delete_image_tag').modal('hide')
        toastr.success('タグが削除されました')
      },
      error: function (request, status, error) {
        if (request.status === 422) {
          return toastr.error('タグを削除に失敗しました')
        }
      }
    })
  }
}
export default ConstructionCase;
