var MailMagazine = {
  init: function() {
    MailMagazine.changeFilter();
  },

  changeFilter: function () {
    $('#mail_magazine_status, #delivery_date_from, #delivery_date_to').bind('change', function () {
      $('.modal-loading').modal('show')
      MailMagazine.searchMailMagazine();
    })
  },

  searchMailMagazine: function () {
    $('form#mail_magazine_search_form input#mail_magazine_search_btn').trigger('click');
  },
}

export default MailMagazine;