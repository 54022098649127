import SearchMap from './search_map';
import {handleAjaxError} from "../handle_error";

var EventBuilder = {
  init: function () {
    EventBuilder.start();
    EventBuilder.changeStatus();
    EventBuilder.eventDateStatus();
    EventBuilder.initMap();
    EventBuilder.applyMapData();
    EventBuilder.savePreview();
    EventBuilder.openTabPreview();
  },

  bukkens: [],
  selected_bukkens: [],

  initMap: function(){
    if($('#searchPlace').length > 0){
      if(typeof window.google == 'undefined' || typeof google.maps == 'undefined'){
        var map_url_obj = { key: SearchMap.key, libraries: 'places', region:'JP', language: 'ja', v: '3' }
        var map_url = 'https://maps.googleapis.com/maps/api/js?' + $.param(map_url_obj)
        $.getScript(map_url, function(){
          EventBuilder.initMapAutocomplete()
        });
      }
      else {
        EventBuilder.initMapAutocomplete()
      }
    }
  },

  applyMapData: function () {
    $('#event_apply_map_data').on('click', function () {
      $('#event_builder_event_builder_place_attributes_map_address').val($('#event_map_address').val());
      if($('.event-map-lat').val().trim().length > 0) {
        $('#event_builder_event_builder_place_attributes_latitude').val($('.event-map-lat').val());
      }
      if($('.event-map-lng').val().trim().length > 0){
        $('#event_builder_event_builder_place_attributes_longitude').val($('.event-map-lng').val());
      }

      $('#searchPlace').modal('toggle');
    });
  },

  initMapAutocomplete: function(){
    let address = $('#event_map_address');
    let latitude = $('.event-map-lat');
    let longitude = $('.event-map-lng');
    let markers = [];

    let lat = 33.233087;
    let lng = 131.606501;
    if(latitude.val().trim().length > 0 && longitude.val().trim().length > 0){
      lat = parseFloat(latitude.val());
      lng = parseFloat(longitude.val());
    }
    const latlng = {lat: lat, lng: lng}
    const map = new google.maps.Map(document.getElementById("event_google_map"), {
      center: latlng,
      zoom: 13,
      streetViewControl: false,
      mapTypeId: "roadmap",
    });

    markers.push(
      new google.maps.Marker({
        position: latlng,
        map
      })
    )

    $('#event_find_address').on('click',function() {
      let q = address.val();
      if(q){
        $.ajax({
          url: 'https://maps.googleapis.com/maps/api/geocode/json',
          data: { address: q, key: SearchMap.key },
          method: 'GET',
          success: function(response) {
            var geometry = response.results[0].geometry;
            if(geometry){
              $('.event-map-lat').val(geometry.location.lat)
              $('.event-map-lng').val(geometry.location.lng)
              markers.forEach((marker) => {
                marker.setMap(null);
              });
              let latlng = new google.maps.LatLng(geometry.location.lat, geometry.location.lng)
              markers.push(
                new google.maps.Marker({
                  map,
                  position: latlng
                })
              );
              map.fitBounds(new google.maps.LatLngBounds(geometry.viewport.southwest, geometry.viewport.northeast));
            }
          },
        });
      }
    });

    $("#event_map_address").keydown(function(e){
      if(e.which === 13){
        $("#event_find_address").click();
      }
    });

    let geocoder = new google.maps.Geocoder();
    map.addListener("click", (mapsMouseEvent) => {
      markers.forEach((marker) => {
        marker.setMap(null);
      });
      markers = [];
      geocoder.geocode({
        'latLng': mapsMouseEvent.latLng
      }, function(results, status) {
        if (status == google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            var addr = results[0].formatted_address
            addr = addr.replace(/[^\p{L}\p{N}\p{P}\p{Z}]/gu, '-').replace('日本、-', '');
            address.val(addr);
            latitude.val(mapsMouseEvent.latLng.lat());
            longitude.val(mapsMouseEvent.latLng.lng());
          }
        }
      })

      markers.push(
        new google.maps.Marker({
          map,
          position: mapsMouseEvent.latLng
        })
      )
    });
  },

  start: function () {
    EventBuilder.bukkens = []
    EventBuilder.selected_bukkens = []

    $('a.add_fields.add_event_builder_block1')
      .data('association-insertion-method', 'append')
      .data('association-insertion-node', '#event_builder_drag1.cocoon');
    $('a.add_fields.add_event_builder_block2')
      .data('association-insertion-method', 'append')
      .data('association-insertion-node', '#event_builder_drag2.cocoon');
    EventBuilder.cocoon_after_insert_callback()
    EventBuilder.deleteBlocks()

    $('#event_builder_drag1, #event_builder_drag2')
      .on('cocoon:before-insert', function (e, item) {
        item.fadeIn()
      })
      .on('cocoon:after-insert', function (e, item) {
        EventBuilder.cocoon_after_insert_callback(item)
      })
      .on("cocoon:before-remove", function (e, item) {
        item.fadeOut()
      })
      .on("cocoon:after-remove", function (e, item) {
      });

    $(".btn-upload-image").bind('click', function () {
      $(this).parent().find(".change-image").trigger('click');
    })

    $("#btn_submit_event_builder").bind('click', function () {
      if (EventBuilder.isValidForm()) {
        $('.modal-loading').modal('show')
        EventBuilder.checkValidate()
      } else {
        EventBuilder.showErrorMsg()
      }
    })

    $("#btn_search_place").bind('click', function () {
      $("#searchPlace").modal('show')
    })

    $("#btn_add_block").bind('click', function () {
      $(".event-builder-block").each(function () {
        if ($(this).hasClass("hidden")) {
          $(this).removeClass("hidden");
          $(this).find(".delete").each(function () {
            $(this).find('input').val(false);
          });
          EventBuilder.toggleBtnAddBlock()
          return false;
        }
      });
    })

    $(".event-search-bukken").on('click', function () {
      if ($('#bukken_type').val() === '' && $("#bukken_id").val() === '') {
        toastr.error("必ず物件種別を選択してください！")
      } else {
        EventBuilder.searchBukken()
      }
    });

    $(".btn-event-assign-bukken").on('click', function () {
      EventBuilder.selected_bukkens = $.merge([], EventBuilder.bukkens);
      $(".modal-search-bukken").modal('hide')
      EventBuilder.updateHiddenField()
      EventBuilder.buildBukkenList()
      EventBuilder.toggleTableAssignBukken()
      EventBuilder.toggleOptionAssignBlock()
    })

    if (/event_builder\/[0-9]+\/edit/.test(window.location.href)) {
      EventBuilder.getBukkenAssign();
    }
  },

  updateHiddenField: function () {
    let ids = [];
    EventBuilder.selected_bukkens.map(bukken => ids.push(bukken.id))
    $("#event_builder_ids_bukken").val(ids.join(','));
  },

  toggleTableAssignBukken: function () {
    const table = $("#table_assign_bukkens")
    const msg = $("#event_no_bukken")
    if (EventBuilder.selected_bukkens.length) {
      if (table.hasClass('hidden')) {
        $("#table_assign_bukkens, #event_bukken_help").removeClass('hidden');
        msg.addClass('hidden');
      }
    } else {
      $("#table_assign_bukkens, #event_bukken_help").addClass('hidden');
      msg.removeClass('hidden');
    }
  },

  toggleOptionAssignBlock: function () {
    $(`#event_builder_setting_block1 option:not(:first-child)`).attr("disabled", true);
    $(`#event_builder_setting_block2 option:not(:first-child)`).attr("disabled", true);
    for (let i = 2; i <= EventBuilder.selected_bukkens.length + 1; i++ ) {
      $(`#event_builder_setting_block1 option:nth-child(${i})`).attr("disabled", false);
      $(`#event_builder_setting_block2 option:nth-child(${i})`).attr("disabled", false);
    }
  },

  buildBukkenList: function () {
    // MEMO: 2022/10/18 現在使っていない処理なので、コメントアウトする（ベツダイリホームイベントページ生成）
    // let html = '<thead><tr><td>物件ID</td><td>情報公開日</td><td>物件名</td><td>価格</td><td>物件種別</td><td>会場設定</td></tr></thead>'
    let html = '<thead><tr><td>物件ID</td><td>情報公開日</td><td>物件名</td><td>価格</td><td>物件種別</td></tr></thead>'
    html += '<tbody id="event_bukken_sortable">'
    $.each(EventBuilder.selected_bukkens, function(index, bukken) {
      html += '<tr id="'+bukken.id+'">'
      html += '<td class="text-left">'+bukken.id+'</td>'
      html += '<td class="text-left">'+bukken.release_date+'</td>'
      html += '<td class="text-left">'+bukken.bukken_name+'</td>'
      html += '<td class="text-left color-chestnut-rose">'+bukken.price+'</td>'
      html += '<td class="text-left">'+bukken.bukken_type_name+'</td>'
      if (bukken.event_place_setting) {
        // /* MEMO: 2022/10/18 現在使っていない処理なので、コメントアウトする（ベツダイリホームイベントページ生成）
        // html += '<td class="text-left"><label class="d-flex"><input name="event_builder[event_place_setting]['+bukken.id+']" type="hidden" value="0"><input class="input-checkbox" type="checkbox" value="1" name="event_builder[event_place_setting]['+bukken.id+']" checked="checked"><span class="icon-checkbox"></span><span class="title"> 設定する</span></label></td>'
        html += '<input name="event_builder[event_place_setting]['+bukken.id+']" type="hidden" value="0">'
      } else {
        // /* MEMO: 2022/10/18 現在使っていない処理なので、コメントアウトする（ベツダイリホームイベントページ生成）
        // html += '<td class="text-left"><label class="d-flex"><input name="event_builder[event_place_setting]['+bukken.id+']" type="hidden" value="0"><input class="input-checkbox" type="checkbox" value="1" name="event_builder[event_place_setting]['+bukken.id+']"><span class="icon-checkbox"></span><span class="title"> 設定する</span></label></td>'
        html += '<input name="event_builder[event_place_setting]['+bukken.id+']" type="hidden" value="0">'
      }
      html += '<td class="text-left"><div class="btn-group dropleft"><button aria-expanded="false" aria-haspopup="true" class="dropdown-toggle" data-toggle="dropdown" type="button"><i class="fas fa-ellipsis-h"></i></button><div class="dropdown-menu" style=""><ul><li class="edit-action"><i class="fas fa-home"></i><a target="_blank" href="'+bukken.detail_path+'">詳細</a></li><li class="dropdown-divider"></li><li class="delete-action"><i class="fa fa-trash"></i><a class="link__delete event-remove-bukken" data-id="'+bukken.id+'">削除</a></li></ul></div></div></td>'
      html += '</tr>'
    });
    html += '</tbody>'

    $('#table_assign_bukkens').html(html)
    EventBuilder.removeBukken()
    EventBuilder.sortableBukkenAssign()
  },

  removeBukken: function () {
    $('.event-remove-bukken').on('click', function () {
      const id = $(this).data('id')
      EventBuilder.selected_bukkens = EventBuilder.selected_bukkens.filter(bukken => bukken.id !== id)
      EventBuilder.bukkens = $.merge([], EventBuilder.selected_bukkens);
      $(this).parents( "tr" ).remove()
      EventBuilder.toggleTableAssignBukken()
      EventBuilder.toggleBtnAddBukken()
      EventBuilder.updateHiddenField();
      EventBuilder.toggleOptionAssignBlock();
    });
  },

  searchBukken: function () {
    const form_search = $('#event_search_bukken')
    const url_search = form_search.attr('action');
    let data = form_search.serialize()

    $("#table_search_bukkens").html('<tbody><tr><td class="text-center">検索中</td></tr></tbody>');
    $.ajax({
      url: url_search,
      type : 'GET',
      data : data,
      success: function () {
        $('.modal-loading').modal('hide')
        EventBuilder.addBukken();
        EventBuilder.toggleBtnAddBukken();
      },
      error: function (request, status, error) {
        handleAjaxError(request, status, error);
      }
    })
  },

  toggleBtnAddBukken: function () {
    $(".event-add-bukken").each(function() {
      let bukken_id = $(this).data('id')

      if (EventBuilder.checkBukkenExist(bukken_id)) {
        $(this).addClass('bgcolor-dusty-gray').text('追加済').show()
      } else {
        $(this).addClass('bgcolor-gray').text('追加する').show()
      }
    })
  },

  checkBukkenExist: function (bukken_id) {
    return !!EventBuilder.bukkens.filter(bukken => bukken.id === bukken_id).length;
  },

  addBukken: function () {
    $(".event-add-bukken").on('click', function () {
      let bukken_id = $(this).data('id')
      if(EventBuilder.checkBukkenExist(bukken_id)){
        $(this).removeClass('bgcolor-dusty-gray').addClass('bgcolor-gray').text('追加する');
        EventBuilder.bukkens = EventBuilder.bukkens.filter(bukken => bukken.id !== bukken_id)
      } else {
        if (EventBuilder.bukkens.length > 4) {
          toastr.error("物件を5件以上追加できません。")
        } else {
          $(this).removeClass('bgcolor-gray').addClass('bgcolor-dusty-gray').text('追加済');
          EventBuilder.bukkens.push({
            id: bukken_id,
            price: $(this).data('price'),
            release_date: $(this).data('release-date'),
            bukken_name: $(this).data('name'),
            bukken_type_name: $(this).data('bukken-type-name'),
            bukken_type: $(this).data('bukken-type'),
            detail_path: $(this).data('url'),
            event_place_setting: false,
          });
        }
      }
    });
  },

  getBukkenAssign: function () {
    const id = window.location.href.match(/\/[0-9]+\//)[0].replaceAll("/", "");
    $('.modal-loading').modal('show')

    $.ajax({
      url: '/kanri/event_builder/ajax_assign_bukken',
      type : 'GET',
      data : { id: id },
      success: function (response) {
        if (response.bukkens.length){
          response.bukkens.forEach(function (bukken) {
            EventBuilder.bukkens.push({
              id: bukken.id,
              price: bukken.price,
              release_date: bukken.release_date,
              bukken_name: bukken.bukken_name,
              bukken_type: bukken.bukken_type,
              bukken_type_name: bukken.bukken_type_name,
              detail_path: bukken.detail_path,
              event_place_setting: bukken.event_place_setting
            });
          });

          EventBuilder.selected_bukkens = $.merge([], EventBuilder.bukkens);
          EventBuilder.buildBukkenList()
        }

        EventBuilder.updateHiddenField()
        EventBuilder.toggleTableAssignBukken()
        EventBuilder.toggleOptionAssignBlock()
        $('.modal-loading').modal('hide')
      },
      error: function (request, status, error) {
        handleAjaxError(request, status, error);
      }
    })
  },

  isValidForm: function () {
    const input_require = $('.input-required');

    let result = true, names = [];

    for (let i = 0; i < input_require.length; i++) {
      if ($(input_require[i]).val().trim().length === 0) {
        result = false;
        names.push($(input_require[i]).data('validate-name'))
      }
    }

    if ($('.event-template:checked').length === 0) {
      names.push('テンプレートの選択')
      result = false;
    }

    if (!$("#event_builder_date_undecided")[0].checked && !$("#event_builder_always_held")[0].checked &&
      ($('#event_builder_start_date').val() === '' || $('#event_builder_end_date').val() === '')) {
      names.push('イベント開催日')
      result = false;
    }

    if (result === false) {
      names = names.filter(function (item, i, ar) {
        return ar.indexOf(item) === i;
      })
      $('.list-error-messages span.fields').html(names.join('、'));
      $('.list-error-messages span.msg').html('が未入力です。');

      return result;
    }

    if ($('#event_builder_start_date').val() !== '' && $('#event_builder_end_date').val() !== '' &&
      $('#event_builder_start_date').val() > $('#event_builder_end_date').val()
    ) {
      $('.list-error-messages span.fields').html('イベントの終了日は開始日の後に設定してください。');
      $('.list-error-messages span.msg').html('');
      result = false;
    }

    if (!/^[A-Za-z0-9_ ]+(?:-[A-Za-z0-9_ ]+)*$/.test($("#event_builder_slug").val())) {
      $('.list-error-messages span.fields').html('イベントパスは必ず半角文字で入力してください。');
      $('.list-error-messages span.msg').html('');
      result = false;
    }

    return result;
  },

  checkValidate: function () {
    const event_id = $("#event_builder_id").val();
    const display_site = $("#event_builder_display_site").val();
    const slug = $("#event_builder_slug").val();
    let form_data = { display_site: display_site, slug: slug }
    if(event_id) {
      form_data.id = event_id
    }

    $.ajax({
      url: '/kanri/event_builder/ajax_check_validate',
      data: form_data,
      method: 'POST',
      success: function(response) {
        if(response.result) {
          $("#event_builder_form").submit()
        } else {
          toastr.error('パスが既に存在しましたので、再度設定してください。')
        }
      },
      error: function (request, status, error) {
        handleAjaxError(request, status, error);
      },
      complete: function () {
        $('.modal-loading').modal('hide')
      }
    })
  },

  showErrorMsg: function () {
    toastr.remove()
    const erros = $('.list-error-messages').html()
    toastr.error(erros)
  },

  cocoon_after_insert_callback: function (item = null) {
    $(".tab_right .apply a").bind('click', function (event) {
      event.stopPropagation();
      const parent = $(this).parents('.tab_wrapper')
      const type = $(this).data('type')
      EventBuilder.preview_content($(this), type)

      // 表示切り替え
      parent.addClass('drag_handle')
      parent.find('.mail_edit').hide()
      parent.find('.mail_preview').fadeIn()
    })

    $(".tab_right .edit a").bind('click', function (event) {
      event.stopPropagation();
      const parent = $(this).parents('.tab_wrapper')
      // 表示切り替え
      parent.removeClass('drag_handle')
      parent.find('.mail_preview').hide()
      parent.find('.mail_edit').fadeIn()
    })

    $(".tab_right .cancel a").bind('click', function () {
      const parent = $(this).parents('.tab_wrapper')
      const type = $(this).data('type')

      // 表示切り替え
      parent.addClass('drag_handle')
      parent.find('.mail_edit').hide()
      parent.find('.mail_preview').fadeIn()
    })

    // sortableの並び順を定義する
    if (item && item.hasClass('sortable_list')) {
      item.parent().find(".order_no").each(function (index) {
        $(this).val(index + 1)
      });
    }
  },

  preview_content: function (event, type) {
    const parent = event.parents('.tab_wrapper')
    switch (type) {
      case 'caption_block':
        EventBuilder.review_caption_block(parent);
        break;
      case 'text_block':
        EventBuilder.review_text_block(parent);
        break;
      case 'picture_block':
        EventBuilder.review_picture_block(parent);
        break;
      case 'picture_wide_block':
        EventBuilder.review_picture_wide_block(parent);
        break;
      case 'multi_block':
      case 'multi_reverse_block':
        EventBuilder.review_multi_block(parent);
        break;
      case 'gallery_block':
        EventBuilder.review_gallery_block(parent);
        break;
      case 'banner_two_block':
        EventBuilder.review_banner_two_block(parent);
        break;
      case 'banner_large_block':
        EventBuilder.review_banner_large_block(parent);
        break;
      case 'coding_block':
        EventBuilder.review_coding_block(parent);
        break;
      default:
        return
    }
  },

  review_caption_block: function (parent) {
    // cb_title
    let old_content = parent.find('.mail_preview').find(".cb_title")
    let new_content = parent.find('.mail_edit').find(".cb_title")
    old_content.html(new_content.val())

    // cb_title_size
    let old_class = parent.find('.mail_preview').find(".cb_title_size")
    let new_class = parent.find('.mail_edit').find(".cb_title_size")
    old_class.parent().removeClass(old_class.parent().attr('class', ''))
    old_class.parent().addClass(new_class.val())
  },

  review_text_block: function (parent) {
    // tb_body
    let old_content = parent.find('.mail_preview').find(".tb_body")
    let iframe = parent.find('iframe').contents().find('body')[0]
    let new_content = parent.find('.mail_edit').find(".tb_body")
    new_content.val($(iframe).html())
    old_content.html(new_content.val())
  },

  review_coding_block: function (parent) {
    // tb_body
    let old_content = parent.find('.mail_preview').find(".tb_body")
    let new_content = parent.find('.mail_edit').find(".tb_body")
    old_content.html(new_content.val())

    // cb_title_size
    let old_class = parent.find('.mail_preview').find(".tb_body")
    let new_class = parent.find('.mail_edit').find(".tb_body")
    old_class.parent().removeClass(old_class.parent().attr('class', ''))
    old_class.parent().addClass(new_class.val())
  },

  review_picture_block: function (parent) {
    let index = 0;
    do {
      index = index + 1;

      // pb_image#{i}
      let old_image = parent.find('.mail_preview').find(".pb_image" + index)
      let new_image = parent.find('.mail_edit').find(".pb_image" + index)
      old_image.attr('style', new_image.attr("style"));

      // pb_image#{i}_caption
      let old_content = parent.find('.mail_preview').find(".pb_image" + index + "_caption")
      let new_content = parent.find('.mail_edit').find(".pb_image" + index + "_caption")
      old_content.html(new_content.val())
    } while (index < 3);
  },

  review_picture_wide_block: function (parent) {
    // pwb_image
    let old_image = parent.find('.mail_preview').find(".pwb_image")
    let new_image = parent.find('.mail_edit').find(".pwb_image")
    old_image.attr('style', new_image.attr("style"));
  },

  review_gallery_block: function (parent) {
    let index = 0;
    do {
      index = index + 1;

      // gb_image#{i}
      let old_image = parent.find('.mail_preview').find(".gb_image" + index)
      let new_image = parent.find('.mail_edit').find(".gb_image" + index)
      old_image.attr('style', new_image.attr("style"));
    } while (index < 4);
  },

  review_banner_two_block: function (parent) {
    let index = 0;
    do {
      index = index + 1;

      // btb_image#{i}
      let old_image = parent.find('.mail_preview').find(".btb_image" + index)
      let new_image = parent.find('.mail_edit').find(".btb_image" + index)
      old_image.attr('style', new_image.attr("style"));

      // btb_image#{i}_link
      let old_link = parent.find('.mail_preview').find(".btb_image"+index+"_link")
      let new_link = parent.find('.mail_edit').find(".btb_image"+index+"_link")
      old_link.attr('href', new_link.val())
    } while (index < 2);
  },

  review_banner_large_block: function (parent) {
    // blb_image_pc
    let old_image_pc = parent.find('.mail_preview').find(".blb_image_pc")
    let new_image_pc = parent.find('.mail_edit').find(".blb_image_pc")
    old_image_pc.attr('style', new_image_pc.attr("style"));

    // blb_image_sp
    let old_image_sp = parent.find('.mail_preview').find(".blb_image_sp")
    let new_image_sp = parent.find('.mail_edit').find(".blb_image_sp")
    old_image_sp.attr('style', new_image_sp.attr("style"));

    // blb_image_link
    let old_link = parent.find('.mail_preview').find(".blb_image_link")
    let new_link = parent.find('.mail_edit').find(".blb_image_link")
    old_link.attr('href', new_link.val())
  },

  review_multi_block: function (parent) {
    // mb_title
    let old_title = parent.find('.mail_preview').find(".mb_title")
    let new_title = parent.find('.mail_edit').find(".mb_title")
    old_title.html(new_title.val())

    // mb_body
    let old_content = parent.find('.mail_preview').find(".mb_body")
    let iframe = parent.find('iframe').contents().find('body')[0]
    let new_content = parent.find('.mail_edit').find(".mb_body")
    new_content.val($(iframe).html())
    old_content.html(new_content.val())

    // mb_image
    let old_image = parent.find('.mail_preview').find(".mb_image")
    let new_image = parent.find('.mail_edit').find(".mb_image")
    old_image.attr('style', new_image.attr("style"));
  },

  toggleBtnAddBlock: function () {
    if ($(".event-builder-block").hasClass("hidden")) {
      $("#btn_add_block").fadeIn();
    } else {
      $("#btn_add_block").fadeOut();
    }
  },

  deleteBlocks: function () {
    $('.event-builder-block').on('click', '.link__delete', function () {
      const block_id = $(this).data('delete-block')
      $("#flag_delete_block").val(block_id)
    })

    $('.delete-blocks').bind('click', function () {
      const block_id = $("#flag_delete_block").val()
      const block = $("#"+block_id).closest(".event-builder-block")
      block.addClass('hidden')
      block.find(".delete").each(function () {
        $(this).find('input').val(1);
      });
      $("#delete_block").modal('hide')
      EventBuilder.toggleBtnAddBlock()
    })
  },

  sortableBukkenAssign: function () {
    $("#event_bukken_sortable").sortable({
      update: function (table, row) {
        const rows = table.target.rows;
        let ids_bukken = "";
        for (var i = 0; i < rows.length; i++) {
          if (i === rows.length - 1) {
            ids_bukken += rows[i].id;
          } else {
            ids_bukken += rows[i].id + ",";
          }
        }
        $('#event_builder_ids_bukken').val(ids_bukken);
      },
      helper: function(event, ui){
        // adjust placeholder td width to original td width
        ui.children().each(function(){
          $(this).width($(this).width());
        });
        return ui;
      },
    });
  },

  changeStatus: function() {
    $('.event-builder-status')
      .on('focus', function () {
        $(".btn-confirm-status-event").attr('data-status-old', $(this).val())
      })
      .on('change', function () {
        $(".btn-confirm-status-event").attr('data-url', $(this).parent('.select').data('url'))
        $(".btn-confirm-status-event").attr('data-status', $(this).val())
        $(".btn-confirm-status-event").attr('data-id', $(this).parent('.select').attr('id'))
        $(this).val($(".btn-confirm-status-event").attr('data-status-old'));
        $("#modal_event_change_status").modal('show')
      })

    $(".btn-confirm-status-event").on('click', function(){
      const status = $(this).attr('data-status')
      const url = $(this).attr('data-url')
      $.ajax({
        url: url,
        data: { status: status },
        method: 'POST',
        success: function(response) {
          if(response.result) {
            let id = $(".btn-confirm-status-event").attr('data-id');
            let select = $("#"+id).find('select')
            select.val($(".btn-confirm-status-event").attr('data-status'));
            toastr.success('公開ステータスを更新しました。')
          }
        },
        error: function (request, status, error) {
          handleAjaxError(request, status, error);
        },
        complete: function () {
          $("#modal_event_change_status").modal('hide')
        }
      })
    });
  },

  eventDateStatus: function () {
    $("#event_builder_date_undecided").change(function() {
      if (this.checked) {
        $('#event_date_status .input-datepicker').val('');
        $("#event_builder_always_held").prop('checked', false);
      }
    });

    $("#event_builder_always_held").change(function() {
      if (this.checked) {
        $('#event_date_status .input-datepicker').val('');
        $("#event_builder_date_undecided").prop('checked', false);
      }
    });

    $('#event_date_status .input-datepicker').change(function(event){
      if ($.trim(event.target.value) !== '') {
        $("#event_builder_date_undecided").prop('checked', false);
        $("#event_builder_always_held").prop('checked', false);
      }
    });
  },

  savePreview: function () {
    $('.btn-create-preview').on('click', function () {
      $("#event_builder_status").val('draft')
      $("#event_builder_action").val('save-preview')
      $("#btn_submit_event_builder").trigger('click')
      $("#previewEvent").modal('hide');
    })

    $('.btn-edit-preview').on('click', function () {
      $("#event_builder_action").val('save-preview')
      $("#btn_submit_event_builder").trigger('click')
    })
  },

  openTabPreview: function () {
    const preview_path = new URL(window.location.href).searchParams.get("preview_path");
    if (preview_path) {
      window.open(preview_path, '_blank');
    }
  }
}

export default EventBuilder;
